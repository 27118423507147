




































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { SchedulingData } from '@/store/planning/types';
import {
  Component, Prop, Watch, Vue, PropSync,
} from 'vue-property-decorator';

@Component({})
export default class ScheduleWorkOrder extends Vue {
  @PropSync('valid') synchedValid: boolean;

  @PropSync('scheduleDialog') synchedScheduleDialog: boolean;

  @PropSync('schedule') synchedSchedule: SchedulingData;

  selectedDate = '';

  endDate = '';

  onMenu = false as boolean;

  numberRules = [
    (v: string): string | boolean => (!!v && /^[1-9]\d*$/.test(v)) || 'Must be a whole number greater than 0',
  ];

  requiredRule = [(v: any): any | boolean => !!v || 'Selection is required'];

  DoesNotEndString = 'Does Not End';

  OnADateString = 'On a Date';

  AfterNOccurencesString = 'After # Occurences';

  endsModel = this.DoesNotEndString;

  period = null;

  interval = null as number;

  occurencesNumber = '' as string;

  get WarningText(): string | undefined {
    if (!this.selectedDate) {
      return null;
    }
    const selectedDate = this.parseDate(this.selectedDate).getDate();
    if (this.period?.name?.includes('month') && selectedDate > 28) {
      return `Work order will repeat on the last day of the month if there are not ${selectedDate} days in the month`;
    }
    return null;
  }

  get minEndDate(): string {
    if (this.selectedDate === '') {
      return this.today;
    }
    return this.parseDate(this.selectedDate).toISOString().slice(0, 10);
  }

  parseDate(dateString: string): Date {
    const d = new Date(dateString);
    const userTimezoneOffset = d.getTimezoneOffset() * 60000;
    return new Date(d.getTime() + userTimezoneOffset);
  }

  mounted(): void {
    this.onDialogChange();
  }

  @Watch('synchedScheduleDialog')
  onDialogChange(): void {
    if (this.synchedScheduleDialog === true && this.synchedSchedule) {
      this.interval = this.synchedSchedule.interval;
      this.period = this.periodOptions.find(
        (p) => this.synchedSchedule.period.toLowerCase().includes(p.name),
      );
      this.selectedDate = this.synchedSchedule.startDate.slice(0, 10);
      switch (this.synchedSchedule.endType) {
        case 'end_date':
          this.endsModel = this.OnADateString;
          this.endDate = this.synchedSchedule.endValue.slice(0, 10);
          break;
        case 'num_occurrences':
          this.endsModel = this.AfterNOccurencesString;
          this.occurencesNumber = this.synchedSchedule.endValue;
          break;
        case 'never':
        default:
          this.endsModel = this.DoesNotEndString;
      }
    }
  }

  get periodOptions(): any[] {
    if (Number(this.interval) === 1) {
      return [
        { name: 'week', index: 0 },
        { name: 'month', index: 1 },
        { name: 'year', index: 2 },
      ];
    }
    return [
      { name: 'weeks', index: 0 },
      { name: 'months', index: 1 },
      { name: 'years', index: 2 },
    ];
  }

  get today(): string {
    return new Date().toISOString().slice(0, 10);
  }

  reset(): void {
    this.endsModel = this.DoesNotEndString;
    this.period = null;
    this.interval = null as number;
    this.occurencesNumber = '' as string;
    this.selectedDate = '';
    this.endDate = '';
    (this.$refs.form as HTMLFormElement).resetValidation();
  }

  submitScheduling(): void {
    const schedule = this.createSchedule();
    this.$emit('saveScheduling', schedule);
    this.reset();
    this.synchedScheduleDialog = false;
  }

  createSchedule(): SchedulingData {
    const schedulingData: SchedulingData = {
      interval: this.interval,
      period: this.period?.name,
      startDate: new Date(this.selectedDate).toISOString().slice(0, 10),
      endType: '',
      endValue: '',
    };
    switch (this.endsModel) {
      case this.OnADateString:
        schedulingData.endType = 'end_date';
        schedulingData.endValue = new Date(this.endDate).toISOString().slice(0, 10);
        break;
      case this.AfterNOccurencesString:
        schedulingData.endType = 'num_occurrences';
        schedulingData.endValue = this.occurencesNumber;
        break;
      case this.DoesNotEndString:
      default:
        schedulingData.endType = 'never';
        schedulingData.endValue = null;
    }
    return schedulingData;
  }
}
