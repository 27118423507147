

























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import { PipeReliningReportFields } from './types';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';
import PipeReliningReportHeader from './PipeReliningReportHeader.vue';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    PipeReliningReportHeader,
  },
})
export default class PipeReliningReport extends ReportBodyMixin {
  // #region Abstract fields
  workOrderData: PipeReliningReportFields | any = {};

  workOrderFields = [
    { name: 'wastewater', value: 'Wastewater' },
    {
      name: 'reliningDate',
      value: 'ReliningDate',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'location', value: 'Location' },
    {
      name: 'pipeLength',
      value: 'PipeLength',
      type: WorkOrderFieldType.NUMBER,
    },
    {
      name: 'lengthOrdered',
      value: 'LengthOrdered',
      type: WorkOrderFieldType.NUMBER,
    },
    { name: 'hostPipeMaterial', value: 'HostPipeMaterial' },
    {
      name: 'hostPipeSize',
      value: 'HostPipeSize',
      type: WorkOrderFieldType.NUMBER,
    },
    { name: 'specialConditions', value: 'SpecialConditions' },
  ];
  // #endregion

  showTimeAndMaterials(): boolean {
    return true;
  }
}
