




























/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, VModel,
} from 'vue-property-decorator';
import ReportInputMixin from '../ReportInputMixin.vue';

@Component({
  components: {},
})
export default class ReportRadioRowInput extends ReportInputMixin {
  @VModel() modelValue!: any | undefined;

  /**
   * The label text displayed next to the input field.
   * @type {string | undefined}
   */
  @Prop() label: string | undefined;

  /**
   * Array of radio option values used to generate rows.
   * Use radioOptionLabels to set labels
   * @type {string[]}
   */
  @Prop() radioOptions: string[];

  /**
   * Overrides the default labels
   * If undefined, defaults to the radioOptions
   * @type {string[] | undefined}
   */
  @Prop() radioOptionLabels: string[] | undefined;

  /**
   * Gives the radio buttons a more condensed look
   * @type {boolean}
   */
  @Prop({ default: false }) isCondensed: boolean;

  /**
   * If `true`, marks the input field as required and displays a red asterisk.
   * @type {boolean}
   * @default false
   */
   @Prop({ type: Boolean, default: false }) required!: boolean;

  /**
   * If `true`, marks the input field as semiRequired and displays a black asterisk.
   * Used in creating Service Call
   * @type {boolean}
   * @default false
   */
  @Prop({ type: Boolean, default: false }) semiRequired!: boolean;

  get radioGroupClass() {
    let returnValue = 'radio-group';
    if (this.isCondensed) {
      returnValue += ' radio-group-condensed';
    }
    return returnValue;
  }

  /**
   * Index of current radio option. Defaults to -1.
   * @type number
   */
  get index(): number {
    if (this.modelValue) {
      return this.radioOptions.indexOf(this.modelValue);
    }
    return -1;
  }

  /**
   * Updates the model with the selected radio option for a specific header.
   */
  set index(newIndex: number) {
    this.modelValue = this.radioOptions[newIndex];
  }

  /**
   * Get the label for the index or the value itself if no label is defined
   * @param index The index to grab for the label
   */
  getRadioLabel(index: number): string {
    if (this.radioOptionLabels != null && this.radioOptionLabels.length > index) {
      return this.radioOptionLabels[index] != null
        ? this.radioOptionLabels[index]
        : this.radioOptions[index];
    }
    return this.radioOptions[index];
  }
}
