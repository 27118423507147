import {
  EASEMENT_CLEANING_TASK_TYPE,
  HYDRO_CLEANING_TASK_TYPE,
  RODDING_CLEANING_TASK_TYPE,
  ROOT_CUTTING_CLEANING_TASK_TYPE,
} from '@/common/Constants';

export interface AssignmentTaskType {
  name: string,
  guid?: string,
  isDataCollectionType: boolean,
  subValue?: AssignmentTaskType[],
}

export const macpTaskTypes: AssignmentTaskType[] = [
  {
    name: 'MACP',
    isDataCollectionType: true,
    subValue: [
      {
        name: 'Level 1',
        isDataCollectionType: true,
      },
      {
        name: 'Level 2',
        isDataCollectionType: true,
      },
    ],
  },
  {
    name: 'GPS',
    isDataCollectionType: true,
  },
  {
    name: 'Vertue',
    isDataCollectionType: true,
  },
  {
    name: 'Solo Manhole',
    isDataCollectionType: true,
  },
  {
    name: 'Premium',
    isDataCollectionType: true,
  },
];

export const pacpTaskTypes: AssignmentTaskType[] = [
  {
    name: 'Robot Inspection',
    isDataCollectionType: true,
    subValue: [
      {
        name: 'Solo',
        isDataCollectionType: true,
      },
      {
        name: 'Responder',
        isDataCollectionType: true,
      },
      {
        name: 'Profiler',
        isDataCollectionType: true,
      },
      {
        name: 'CCTV',
        isDataCollectionType: true,
      },
      {
        name: 'P3D',
        isDataCollectionType: true,
      },
    ],
  },
  {
    name: 'PACP Inspection',
    isDataCollectionType: true,
  },
  {
    name: 'MSI Collection',
    isDataCollectionType: true,
  },
  {
    name: 'Pipe Relining',
    isDataCollectionType: false,
  },
  {
    name: 'SLRat',
    isDataCollectionType: true,
  },
];

export const commonTaskTypes: AssignmentTaskType[] = [
  {
    name: 'Cleaning',
    subValue: [
      {
        name: 'Easement',
        guid: EASEMENT_CLEANING_TASK_TYPE,
        isDataCollectionType: false,
      },
      {
        name: 'Hydro-Cleaning',
        guid: HYDRO_CLEANING_TASK_TYPE,
        isDataCollectionType: false,
      },
      {
        name: 'Rodding',
        guid: RODDING_CLEANING_TASK_TYPE,
        isDataCollectionType: false,
      },
      {
        name: 'Root Cutting',
        guid: ROOT_CUTTING_CLEANING_TASK_TYPE,
        isDataCollectionType: false,
      },
    ],
    isDataCollectionType: false,
  },
  {
    name: 'General Maintenance',
    isDataCollectionType: false,
  },
  {
    name: 'Structure Inspection',
    isDataCollectionType: false,
  },
  {
    name: 'SSO Report',
    isDataCollectionType: false,
  },
  {
    name: 'FOG Inspection',
    isDataCollectionType: false,
  },
  {
    name: 'Smoke Test',
    isDataCollectionType: false,
  },
  {
    name: 'Repair',
    isDataCollectionType: false,
  },
  {
    name: 'Generic',
    isDataCollectionType: false,
  },
];
