var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-table"},[_c('IntegrityTable',{attrs:{"data":_vm.filteredRoles,"headers":_vm.headers,"matchFilters":_vm.matchFilters,"dataFillFunctions":_vm.fillTableDataFunctions,"search":_vm.search,"height":'100%',"hideDefaultFooter":_vm.hideDefaultFooter,"loading":_vm.selectedUserDataLoading},on:{"update:data":function($event){_vm.filteredRoles=$event},"update:headers":function($event){_vm.headers=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"edit":_vm.editDialog},scopedSlots:_vm._u([{key:"projectName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.projectName)+" ")])]}},{key:"projectNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.projectNumber)+" ")])]}},{key:"projectCustomer",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.customerName)+" ")])]}},{key:"scheduledStartTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.scheduledStartTime)+" ")])]}},{key:"scheduledEndTime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.scheduledEndTime)+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"id":"actions-buttons"}},[(_vm.hasPermissionAdminSetDateRangeForActivation)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editDialog(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil-outline ")])],1):_vm._e(),(_vm.hasPermissionAdminDeleteUser)?_c('IntegrityDelete',{attrs:{"deleteHeader":"WARNING: Delete Project Access","deleteText":("This operation removes " + (_vm.synchedSelectedUser.detailedUserData.firstname) + "\n          " + (_vm.synchedSelectedUser.detailedUserData
              .lastname) + "\n          from the project '" + (item.projectName) + "'. Are you sure you want to continue?"),"deleteFunction":_vm.deleteUserRole.bind(this, item),"loading":_vm.loading},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('v-btn',{staticClass:"delete-btn",attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]},proxy:true}],null,true)}):_vm._e()],1)]}}])}),(_vm.hasPermissionAdminSetDateRangeForActivation)?_c('v-dialog',{attrs:{"width":"30%","retain-focus":false},model:{value:(_vm.editDialogVisible),callback:function ($$v) {_vm.editDialogVisible=$$v},expression:"editDialogVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit Project Access Dates")]),_c('v-card-text',[_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.startDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Start Date","id":"startDate","readonly":"","dense":"","outlined":"","placeholder":" ","append-icon":"mdi-calendar-blank-outline"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}],null,false,3719254949),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"#0c6599"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-btn',{staticClass:"cancel-btn",attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.startDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),(_vm.canClearDate)?_c('v-btn',{staticClass:"cancel-btn",attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.startDate = null}}},[_vm._v(" Clear ")]):_vm._e(),_c('v-btn',{staticClass:"integrity-blue-button save-btn",on:{"click":function($event){return _vm.$refs.startDateMenu.save(_vm.startDate)}}},[_vm._v("Save")])],1)],1),_c('v-menu',{ref:"endDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.endDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.endDate=$event},"update:return-value":function($event){_vm.endDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"End Date","id":"endDate","readonly":"","dense":"","outlined":"","placeholder":" ","append-icon":"mdi-calendar-blank-outline","hide-details":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}],null,false,1591584275),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('v-btn',{staticClass:"cancel-btn",attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.endDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),(_vm.canClearDate)?_c('v-btn',{staticClass:"cancel-btn",attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.endDate = null}}},[_vm._v(" Clear ")]):_vm._e(),_c('v-btn',{staticClass:"integrity-blue-button save-btn",on:{"click":function($event){return _vm.$refs.endDateMenu.save(_vm.endDate)}}},[_vm._v("Save")])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"cancel-btn",attrs:{"outlined":"","color":"#0c6599"},on:{"click":function($event){_vm.editDialogVisible = false}}},[_vm._v(" Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"integrity-blue-button save-btn",attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.editUserRole(_vm.userRoleToEdit)}}},[_vm._v(" Save ")])],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"plain":"","small":""},on:{"click":function($event){_vm.snackbarVisible = false}}},'v-btn',attrs,false),[_vm._v("Dismiss")])]}}]),model:{value:(_vm.snackbarVisible),callback:function ($$v) {_vm.snackbarVisible=$$v},expression:"snackbarVisible"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }