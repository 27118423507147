
import {
  Prop, PropSync, VModel, Watch,
} from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import {
  PRIORITY_ITEMS,
  TASK_TYPE_STRING,
  WORK_ORDER_STATUS_FOLLOWUPREQUIRED,
  WORK_ORDER_STATUSES,
} from '@/common/Constants';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';
import { DeploymentsActions } from '@/store/deployments/actions';
import { WorkOrderTableData } from '@/store/planning/types';
import vue from 'vue';
import { SubInspectionWorkOrder } from './types';
import ReportHeaderMixin from './ReportHeaderMixin.vue';

// eslint-disable-next-line no-shadow
export enum WorkOrderFieldType {
  TEXT = 'text',
  BOOLEAN = 'boolean',
  DATE = 'date',
  TIME = 'time',
  NUMBER = 'number',
}

const projectModule = namespace('project');
const reportModule = namespace('report');
const deploymentModule = namespace('deployments');

export default class ReportBodyMixin extends vue {
  @reportModule.State('isEditMode') isEditMode: boolean;

  @reportModule.State('isExporting') isExporting: boolean;

  @reportModule.State('reportErrors') reportErrors: any[];

  @reportModule.Getter('scheduledErrorMessage') scheduledErrorMessage: string;

  @reportModule.Getter('inProgressErrorMessage') inProgressErrorMessage: string;

  @reportModule.Getter('followUpErrorMessage') followUpErrorMessage: string;

  @reportModule.Getter('completeErrorMessage') completeErrorMessage: string;

  @projectModule.Action(ProjectActions.FETCH_NAMES) getProjectNames;

  @projectModule.State('names') projectNames: string[];

  @projectModule.State('loadNames') isProjectNameLoading: boolean;

  @projectModule.State('projectGuids') projectGuids: string[];

  @deploymentModule.State('deploymentId') deploymentId: string | undefined;

  @deploymentModule.Action(DeploymentsActions.FETCH_DEPLOYMENT_ID)
  fetchDeploymentId;

  // #region Props
  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop({ default: true }) canPlan: boolean;

  @Prop() dataStandardFormat: any;

  @Prop() dataStandardSchema: any;
  // #endregion

  @VModel() modelValue!: any;

  @PropSync('sharedData') syncedSharedData: any;

  @PropSync('subInspectionData')
  syncedSubInspectionData!: SubInspectionWorkOrder[];

  @Prop({ default: 0 }) inspectionIndex: number;

  // #region Abstract fields

  /**
   * Fields of the work order, containing name, value, and optional type.
   * To be defined by the subclass.
   */
  workOrderFields: {
    name: string;
    value: string;
    type?: WorkOrderFieldType;
    isPartOfHeader?: boolean;
    fillFunction?: (item: unknown) => unknown;
  }[];

  /**
   * The data for the work order, structure to be defined by subclass.
   */
  workOrderData;

  // #endregion

  private hasInitialized = false;

  resetForm(): void {
    this.workOrderData = {};
    this.headerPage().resetForm();
  }

  /**
   * Update the report data by merging it with jsonData.
   *
   * @param jsonData - The JSON data to merge with the current work order data.
   */
  updateReportData(): void {
    this.fillWorkOrderData();
  }

  fillWorkOrderData(): void {
    // Avoid triggering watcher multiple times by creating temp variable
    const tempWorkOrderData = this.getAdditionalWorkOrderData();
    Object.assign(tempWorkOrderData, this.addWorkOrderFieldData());
    this.workOrderData = tempWorkOrderData;
  }

  @Watch('modelValue', { immediate: true, deep: true })
  onModelValueChange(): void {
    if (this.modelValue) {
      this.updateReportData();
    }
  }

  /**
   * Watches for changes in `workOrderData`
   * and updates the work order JSON and any additional report data.
   */
  @Watch('workOrderData', { immediate: true, deep: true })
  onWorkOrderDataChange(): void {
    if (!this.workOrderData) {
      return;
    }
    // Subclass will initialize workOrderData
    // But we don't want to send that null data to the coding form
    // So ignore initialization since mount will handle that
    if (!this.hasInitialized) {
      this.hasInitialized = true;
      return;
    }
    if (!this.workOrderData) {
      return;
    }

    const tempVal = {};

    this.workOrderFields.forEach((field) => {
      let val = this.getNestedValueFromJson(field.name, this.workOrderData);
      if (
        val == null
        && field.name !== 'assignedTo'
        && field.name !== 'surveyor'
      ) {
        return;
      }

      if (field.fillFunction != null) {
        val = field.fillFunction(val);
      }
      this.$set(this.modelValue, field.value, val);
    });

    this.modelValue = Object.assign(this.modelValue, tempVal);
  }

  /**
   * Set the status to follow up from a input's event bool value
   */
  handleCheckboxFollowUpEvent(
    event: any,
    setStatusFollup,
    workOrderData: WorkOrderTableData,
  ): void {
    if (event.target.value) {
      setStatusFollup(workOrderData);
    }
  }

  /**
   * Set the status to follow up
   */
  setStatusFollup(workOrderData: WorkOrderTableData): void {
    const followUpStatusDesc = WORK_ORDER_STATUSES.find(
      (wo) => wo.guid === WORK_ORDER_STATUS_FOLLOWUPREQUIRED,
    )?.description;
    // eslint-disable-next-line no-param-reassign
    workOrderData['Status'] = followUpStatusDesc;
  }

  // TODO maybe abstract function if there is a lot of differences between reports
  /**
   * Get any additional fields not found in work order json in coding form
   * Mostly for planning data in the item object
   */
  getAdditionalWorkOrderData(): any {
    return {
      name: this.item && this.item.nodeName ? this.item.nodeName : '',
      route: this.item && this.item.routeName ? this.item.routeName : '',
      status: this.item && this.item.status ? this.item.status : '',
      workOrderNumber:
        this.item && this.item.workOrderNumber ? this.item.workOrderNumber : '',
      completedBy:
        this.item && this.item.scheduledDueDate
          ? this.formatDate(this.item.scheduledDueDate)
          : '',
      startBy:
        this.item && this.item.scheduledDueDate
          ? this.formatDate(this.item.scheduledStartDate)
          : '',
      dateCompleted:
        this.item && this.item.completeDate
          ? this.formatDate(this.item.completeDate)
          : '',
      workOrderType: this.workOrderReportName ?? '',
      schedulingData: this.getSchedulingString(this.item),
      taskTypeGuid: this.item?.taskTypeGuid ?? '',
      priority: this.item?.priorityDescription ?? '',
      notCompletedWhy: this.item?.taskResultDesc ?? '',
      assignedTo: undefined,
      projectNameString: this.projectNameString ?? '',
    };
  }

  /**
   * Adds data for work order fields, formatting date fields as necessary.
   *
   * @returns A temporary object containing the work order field data.
   */
  addWorkOrderFieldData(): any {
    const tempWorkOrderData = {};
    if (!this.workOrderFields) {
      return tempWorkOrderData;
    }
    // eslint-disable-next-line consistent-return
    this.workOrderFields.forEach((field) => {
      if (!this.modelValue) {
        if (field.type === WorkOrderFieldType.BOOLEAN) {
          return false;
        }
        return '';
      }
      let val = this.getNestedValueFromJson(field.value, this.modelValue);
      if (field.type === WorkOrderFieldType.DATE) {
        val = this.formatDate(val);
      }
      tempWorkOrderData[field.name] = val;
    });
    return tempWorkOrderData;
  }

  // #region util functions

  /**
   * Formats a date string to 'YYYY-MM-DD' format, adjusting for timezone offset.
   *
   * @param {string} dateString - The date string to format, typically in ISO format.
   * @returns {string} The formatted date string in 'YYYY-MM-DD' format.
   */
  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }

    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return null;
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    return date.toISOString().substring(0, 10);
  }

  /**
   * Retrieves the enumeration options for a specified property name.
   *
   * @param {string} propertyName - The name of the property for which to retrieve enum options.
   * @returns {string[]} An array of string values representing the enum options.
   */
  getEnumOptions(propertyName: string): string[] {
    const dataSchemaProperties = this.dataStandardSchema?.properties;
    if (dataSchemaProperties && propertyName in dataSchemaProperties) {
      return dataSchemaProperties[propertyName]?.enum ?? [];
    }
    return [];
  }

  getSchedulingString(item): string {
    if (item?.schedulingData === null) {
      return '';
    }
    try {
      const parsedData = JSON.parse(item?.schedulingData);
      return `Every ${parsedData.Interval} ${
        parsedData.Period
      } starting on ${parsedData.StartDate.substring(0, 10)}`;
    } catch {
      return '';
    }
  }

  changeOption(itemVal: string, option: string): void {
    this.$set(this.workOrderData, itemVal, option);
  }

  get workOrderStatusOptions(): string[] {
    return WORK_ORDER_STATUSES.map((x) => x.description);
  }

  get priorityStatusOptions(): string[] {
    return PRIORITY_ITEMS.map((x) => x.name);
  }

  get projectNameString(): string {
    let returnValue = '';

    if (this.projectNames?.length) {
      // eslint-disable-next-line prefer-destructuring
      returnValue = this.projectNames[0];
    }

    return returnValue;
  }

  get workOrderReportName(): string {
    return `${
      TASK_TYPE_STRING.find((tts) => tts.guid === this.item?.taskTypeGuid)
        ?.desc ?? ''
    }`;
  }

  // #region functions for creating Service Call Report
  canCreate(): boolean {
    return true;
  }
  // #endregion

  get isStatusSetToOpen(): boolean {
    return this.workOrderData.status === 'Open';
  }

  get isStatusSetToScheduled(): boolean {
    return this.workOrderData.status === 'Scheduled';
  }

  get isStatusSetToInProgress(): boolean {
    return this.workOrderData.status === 'In Progress';
  }

  get isStatusSetToFollowUpRequired(): boolean {
    return this.workOrderData.status === 'Follow-Up Required';
  }

  get isStatusSetToComplete(): boolean {
    return this.workOrderData.status === 'Complete';
  }

  isBodyModified(): boolean {
    return this.workOrderFields.some(
      (field) => !field.isPartOfHeader && !!this.modelValue[field.value],
    );
  }

  canSetToScheduled(): boolean {
    return this.headerPage().canSetToScheduled();
  }

  canSetToInProgress(): boolean {
    return this.isBodyModified();
  }

  canSetToInFollowUpRequired(): boolean {
    return false;
  }

  canSetToComplete(): boolean {
    return this.headerPage().canSetToComplete();
  }

  isDateCompleteFilled(): boolean {
    return this.headerPage().canSetToComplete();
  }

  /**
   * Get the value of any key at any level of any js object
   * Retrieved from:
   * https://stackoverflow.com/questions/38640072/how-to-get-nested-objects-in-javascript-by-an-string-as-a-bracket-notation
   * @param {string} key Any key string to get a nested value. Can be one of two formats
   * 1. key1[key2][key3]
   * 2. key1.key2.key3
   * @param {any} objectToSearch The object to get the value from
   */
  getNestedValueFromJson(key: string, objectToSearch: any) {
    return key
      .replace(/\[([^\]]+)]/g, '.$1')
      .split('.')
      .reduce((o, p) => o[p], objectToSearch);
  }

  headerPage(): ReportHeaderMixin {
    const header = this.$refs.pdfReportHeader as ReportHeaderMixin;
    if (!header) {
      return null;
    }
    return header;
  }

  getPageNumber(
    page: number,
    inspectionIndex: number,
    pagesInReport = 1,
  ): number {
    return pagesInReport * inspectionIndex + page;
  }

  assetName(): string {
    return this.modelValue?.Wastewater;
  }

  followUpErrorMessageString(): string {
    if (this.canSetToInFollowUpRequired()) return null;
    return this.followUpErrorMessage;
  }

  showTimeAndMaterials(): boolean {
    return false;
  }
  // #endregion
}
