
import vue from 'vue';
import {
  Prop, VModel, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  PRIORITY_ITEMS,
  TASK_TYPE_STRING,
  WORK_ORDER_STATUSES,
  WORK_ORDER_STATUS_FOLLOWUPREQUIRED,
} from '@/common/Constants';
import { WorkOrderTableData } from '@/store/planning/types';

// eslint-disable-next-line no-shadow
export enum WorkOrderFieldType {
  TEXT = 'text',
  BOOLEAN = 'boolean',
  DATE = 'date',
  TIME = 'time',
  NUMBER = 'number',
}
const projectModule = namespace('project');

export default class ReportHeaderMixin extends vue {
  @projectModule.State('names') projectNames: string[];

  // Interface things
  @VModel() modelValue!: any;

  @Prop() item: any;

  workOrderData;

  hasInitialized = false;

  /**
   * Fields of the work order, containing name, value, and optional type.
   * To be defined by the subclass.
   */
  workOrderFields: {
    name: string;
    value: string;
    type?: WorkOrderFieldType;
    isPartOfHeader?: boolean;
    fillFunction?: (item: unknown) => unknown;
  }[];

  assignedToValue(): string {
    const assignedTo = this.workOrderFields.find(
      (field) => field.name === 'assignedTo',
    );
    if (!assignedTo) {
      console.error('Cannot find assignedTo');
      return null;
    }
    return this.workOrderData[assignedTo.name];
  }

  @Watch('modelValue', { immediate: true, deep: true })
  onModelValueChange(): void {
    if (this.modelValue) {
      this.updateReportHeaderData();
    }
  }

  resetForm(): void {
    this.workOrderData = {};
  }

  /**
   * Update the ReportHeader data by merging it with jsonData.
   *
   * @param jsonData - The JSON data to merge with the current work order data.
   */
  updateReportHeaderData(): void {
    const tempWorkOrderData = this.getAdditionalWorkOrderData();
    this.workOrderData = Object.assign(tempWorkOrderData,
      this.addWorkOrderFieldData(tempWorkOrderData));
  }

  getAdditionalWorkOrderData(): any {
    return {
      name: this.item && this.item.nodeName ? this.item.nodeName : '',
      route: this.item && this.item.routeName ? this.item.routeName : '',
      status: this.item && this.item.status ? this.item.status : '',
      workOrderNumber:
        this.item && this.item.workOrderNumber ? this.item.workOrderNumber : '',
      completedBy:
        this.item && this.item.scheduledDueDate
          ? this.formatDate(this.item.scheduledDueDate)
          : '',
      startBy:
        this.item && this.item.scheduledDueDate
          ? this.formatDate(this.item.scheduledStartDate)
          : '',
      dateCompleted:
        this.item && this.item.completeDate
          ? this.formatDate(this.item.completeDate)
          : '',
      workOrderType: this.workOrderReportName ?? '',
      schedulingData: this.getSchedulingString(this.item),
      taskTypeGuid: this.item?.taskTypeGuid ?? '',
      priority: this.item?.priorityDescription ?? '',
      notCompletedWhy: this.item?.taskResultDesc ?? '',
      assignedTo: undefined,
      projectNameString: this.projectNameString ?? '',
    };
  }

  get workOrderReportName(): string {
    return `${
      TASK_TYPE_STRING.find((tts) => tts.guid === this.item?.taskTypeGuid)
        ?.desc ?? ''
    }`;
  }

  get projectNameString(): string {
    let returnValue = '';
    if (this.projectNames?.length) {
      // eslint-disable-next-line prefer-destructuring
      returnValue = this.projectNames[0];
    }

    return returnValue;
  }

  getSchedulingString(item): string {
    if (item?.schedulingData === null) {
      return '';
    }
    try {
      const parsedData = JSON.parse(item?.schedulingData);
      return `Every ${parsedData.Interval} ${
        parsedData.Period
      } starting on ${parsedData.StartDate.substring(0, 10)}`;
    } catch {
      return '';
    }
  }

  /**
   * Adds data for work order fields, formatting date fields as necessary.
   *
   * @returns A temporary object containing the work order field data.
   */
  addWorkOrderFieldData(additionalWorkOrderData: any): any {
    const tempWorkOrderData = {};
    if (!this.workOrderFields) {
      return tempWorkOrderData;
    }
    // eslint-disable-next-line consistent-return
    this.workOrderFields.forEach((field) => {
      if (!this.modelValue) {
        if (field.type === WorkOrderFieldType.BOOLEAN) {
          return false;
        }
        return '';
      }
      let val = this.getNestedValueFromJson(field.value, this.modelValue);
      if (val === undefined) {
        vue.set(this.modelValue, field.value, '');
      }
      if (field.type === WorkOrderFieldType.DATE) {
        val = this.formatDate(val);
      }
      if (!val && !!additionalWorkOrderData[field.name]) {
        val = additionalWorkOrderData[field.name];
      }
      tempWorkOrderData[field.name] = val;
    });
    return tempWorkOrderData;
  }

  /**
   * Watches for changes in `workOrderData`
   * and updates the work order JSON and any additional ReportHeader data.
   */
  @Watch('workOrderData', { immediate: true, deep: true })
  onWorkOrderDataChange(): void {
    // Subclass will initialize workOrderData
    // But we don't want to send that null data to the coding form
    // So ignore initialization since mount will handle that
    if (!this.hasInitialized && this.projectNames) {
      this.hasInitialized = true;
      return;
    }
    if (!this.workOrderData) {
      return;
    }

    const tempVal = {};

    this.workOrderFields.forEach((field) => {
      let val = this.getNestedValueFromJson(field.name, this.workOrderData);
      if (
        val == null
        && field.name !== 'assignedTo'
        && field.name !== 'surveyor'
      ) {
        return;
      }

      if (field.fillFunction != null) {
        val = field.fillFunction(val);
      }
      this.$set(tempVal, field.value, val);
    });
    /*
    timeAndMaterialsFields.forEach((field) => {
      if (!(field.name in this.workOrderData)) {
        return;
      }
      const val = cloneDeep(this.workOrderData[field.name]);
      this.$set(tempVal, field.value, val);
    });
    */
    Object.assign(this.modelValue, tempVal);
  }

  getNestedValueFromJson(key: string, objectToSearch: any) {
    return key
      .replace(/\[([^\]]+)]/g, '.$1')
      .split('.')
      .reduce((o, p) => o[p], objectToSearch);
  }

  setStatusFollup(workOrderData: WorkOrderTableData): void {
    const followUpStatusDesc = WORK_ORDER_STATUSES.find(
      (wo) => wo.guid === WORK_ORDER_STATUS_FOLLOWUPREQUIRED,
    )?.description;
    // eslint-disable-next-line no-param-reassign
    this.modelValue['Status'] = followUpStatusDesc;
  }

  /**
   * Formats a date string to 'YYYY-MM-DD' format, adjusting for timezone offset.
   *
   * @param {string} dateString - The date string to format, typically in ISO format.
   * @returns {string} The formatted date string in 'YYYY-MM-DD' format.
   */
  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }

    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return null;
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    return date.toISOString().substring(0, 10);
  }

  get workOrderStatusOptions(): string[] {
    return WORK_ORDER_STATUSES.map((x) => x.description);
  }

  canSetToScheduled(): boolean {
    return !!this.assignedToValue;
  }

  // #region functions for creating Service Call Report
  isScheduled(): boolean {
    return false;
  }

  canSetToComplete(): boolean {
    return !!this.workOrderData.dateCompleted;
  }

  isDateCompleteFilled(): boolean {
    return this.canSetToComplete();
  }

  get priorityStatusOptions(): string[] {
    return PRIORITY_ITEMS.map((x) => x.name);
  }
  // #endregion
}
