














































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import { GeneralMaintenanceReportFields } from './types';
import {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import GeneralMaintenanceReportHeader from './GeneralMaintenanceReportHeader.vue';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';

@Component({
  components: {
    ReportFooter,
    ReportDropDownInput,
    ReportTextInput,
    GeneralMaintenanceReportHeader,
  },
})
export default class GeneralMaintenanceReport extends ReportBodyMixin {
  // #region Abstract fields
  workOrderData: GeneralMaintenanceReportFields | any = {};

  workOrderFields = [
    { name: 'workDescription', value: 'Description' },
    { name: 'specialInstructions', value: 'Instructions' },
    { name: 'street', value: 'Street' },
    { name: 'notCompletedWhy', value: 'ResonNotComplete' },
    { name: 'specialConditions', value: 'SpecialCondition' },
    { name: 'clean', value: 'FUClean', type: WorkOrderFieldType.BOOLEAN },
    { name: 'repair', value: 'FURepair', type: WorkOrderFieldType.BOOLEAN },
    { name: 'inspect', value: 'FUInspect', type: WorkOrderFieldType.BOOLEAN },
    { name: 'none', value: 'FUNone', type: WorkOrderFieldType.BOOLEAN },
    { name: 'activity', value: 'Activity' },
  ];

  get isNoneSelected(): boolean {
    return this.workOrderData.none === true;
  }

  get isFollowUpSelected(): boolean {
    return (
      this.workOrderData.clean === true
      || this.workOrderData.repair === true
      || this.workOrderData.inspect === true
    );
  }

  canSetToInFollowUpRequired(): boolean {
    if (this.isNoneSelected) {
      return false;
    }
    return this.isFollowUpSelected;
  }

  // #endregion
}
