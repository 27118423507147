
import Vue from 'vue';
import { Prop, VModel, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const reportModule = namespace('report');

export default class ReportInputMixin extends Vue {
  @Prop({ default: false, type: Boolean }) ignoreEditMode: boolean;

  @Prop({ default: true }) hasPlanPermission: boolean;

  @Prop({ default: false, type: Boolean }) disabled: boolean;

  @Prop({ default: null }) readonly errorMessages: string | string[];

  /**
   * Determine if the form should be allowed to edit
   */
  get allowEditing(): boolean {
    if (this.isExporting || this.disabled) {
      return false;
    }
    if (this.hasPlanPermission) {
      return this.ignoreEditMode || this.isEditMode;
    }
    return !this.ignoreEditMode && this.isEditMode;
  }

  @reportModule.State('isEditMode') isEditMode: boolean;

  @reportModule.State('isExporting') isExporting: boolean;

  @reportModule.State('reportErrors') reportErrors: any[];

  @reportModule.Getter('scheduledErrorMessage') scheduledErrorMessage: string;

  @reportModule.Getter('inProgressErrorMessage') inProgressErrorMessage: string;

  @reportModule.Getter('followUpErrorMessage') followUpErrorMessage: string;

  @reportModule.Getter('completeErrorMessage') completeErrorMessage: string;
}
