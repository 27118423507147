





























































































































import { Component } from 'vue-property-decorator';
import { uuid } from 'vue-uuid';
import ReportFooter from '../ReportFooter.vue';
import { SmokeTestFields } from './types';
import ReportInterface, {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportTableInput from '../ReportHelpers/ReportInputHelpers/ReportTableInput.vue';
import { SubInspectionWorkOrder } from '../ReportHelpers/types';
import SmokeTestReportHeader from './SmokeTestReportHeader.vue';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    ReportTableInput,
    SmokeTestReportHeader,
  },
})
export default class SmokeTestReport extends ReportBodyMixin {
  // #region Abstract fields
  workOrderData: SmokeTestFields | any = {};

  workOrderFields = [
    { name: 'workDescription', value: 'WorkDescription' },
    { name: 'specialInstructions', value: 'SpecialInstructions' },
    { name: 'testDate', value: 'TestDate', type: WorkOrderFieldType.DATE },
    { name: 'startTime', value: 'StartTime', type: WorkOrderFieldType.TIME },
    { name: 'endTime', value: 'EndTime', type: WorkOrderFieldType.TIME },
    { name: 'setupManhole', value: 'Manhole' },
    { name: 'noOfReturns', value: 'Returns' },
    { name: 'inflowReturns', value: 'InflowReturns' },
    { name: 'infilReturns', value: 'InfiltrationReturns' },
    { name: 'comments', value: 'Comments' },
  ];

  // #endregion

  mounted(): void {
    if (this.item?.guid && !this.deploymentId) {
      this.fetchDeploymentId(this.item.guid);
    }
  }

  SewerStructuresColumn = 'Sewer Structures';

  SewerMainsColumn = 'Sewer Mains';

  get smokeTestColumnNames(): string[] {
    return [this.SewerStructuresColumn, this.SewerMainsColumn];
  }

  smokeTestFilterFunction(data: SubInspectionWorkOrder): string {
    return data?.jsonData?.AssetType === 'Main'
      ? this.SewerMainsColumn
      : this.SewerStructuresColumn;
  }

  smokeTestNewAsset(column: string): SubInspectionWorkOrder {
    return {
      subInspectionGuid: uuid.v4(),
      jsonData: {
        AssetID: '',
        AssetType: column === this.SewerMainsColumn ? 'Main' : '',
        AssetNumber: this.syncedSubInspectionData.length + 1,
      },
    };
  }
}
