



















































































/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';
import { ProjectData, ProjectGraphics, ProjectHeaders } from '@/store/project/types';
import { MapPagesActions } from '@/store/mapPages/actions';
import { MapPagesData } from '@/store/mapPages/types';
import {
  AssetFilter,
  AssetData,
  AssetRef,
  Inspection,
  Location,
  LocationLS,
  MatchFilter,
  AdvancedFilter,
  FilterPair,
} from '@/types';
import SideDrawer from '@/components/SideDrawer/SideDrawer.vue';
import ScopeBase from '@/views/ScopeBase/ScopeBase.vue';
import ScopeHistory from '@/components/ScopeHistory/ScopeHistory.vue';
import MapGIS from '@/components/Maps/MapGIS.vue';
import { UserPrefsActions } from '@/store/userPrefs/actions';
import { Defect } from '@/components/Maps/types';
import AssetFilters from '@/components/AssetFilters/AssetFilters.vue';
import { ProjectMutations } from '@/store/project/mutations';
import { UserPermission } from '@/store/userpermissions/types';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';

const mapPagesModule = namespace('mapPages');
const projectModule = namespace('project');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    SideDrawer,
    ScopeBase,
    ScopeHistory,
    MapGIS,
    AssetFilters,
  },
})
export default class Project extends UserPermissionsMixin {
  selectedAsset = {} as AssetRef;

  selectedMapFeatures = [] as AssetRef[] | ProjectGraphics[];

  isCustomer = false;

  isReportingTech = false;

  fullyLoaded = false;

  filters = {
    selectedAssets: [],
    type: [],
    collectionStatus: [],
    reportingStatus: [],
    dateInspected: [],
    customFromDate: '',
    customToDate: '',
    score: [],
    robot: [],
    status: [] as string[],
    omQuick: [],
    structuralQuick: [],
    slRatScore: [],
    matchFilters: [] as MatchFilter[],
    advancedFilters: [],
    hiddenFilters: [] as FilterPair[],
  } as AssetFilter;

  assetData: AssetData[] = [];

  assetCount = 0;

  roles = [];

  defects: Defect[] = [];

  @projectModule.State('data') project: ProjectData | undefined;

  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  @projectModule.State('graphics') projectGraphics: ProjectGraphics[] | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading;

  @projectModule.Action(ProjectActions.FETCH_GRAPHICS_DATA) fetchProjectGraphics;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  @projectModule.Mutation(ProjectMutations.SET_MAP_NEEDS_UPDATED) setMapNeedsUpdated;

  @mapPagesModule.State('data') mapPages: MapPagesData[] | undefined;

  @mapPagesModule.State('loadError') mapPagesLoadError;

  @mapPagesModule.State('loading') mapPagesLoading;

  @mapPagesModule.Action(MapPagesActions.FETCH_MAP_PAGES_DATA) fetchMapPages;

  @userPrefsModule.Action(UserPrefsActions.GET_SELECTED_MAP_ASSET) getSelectedMapAsset;

  @userPrefsModule.State('selectedMapAssetGuid') selectedMapAssetGuid: string | undefined;

  @userPrefsModule.Action(UserPrefsActions.GET_ZOOM_FILTER) getZoomFilter;

  @userPrefsModule.State('useZoomFilter') useZoomFilter: boolean | undefined;

  @Prop() readonly ids!: string;

  id = '';

  projectGuidList: string[] = [];

  nameQuery: string[] = undefined;

  @Watch('projectGraphics')
  onGraphicsChange(): void {
    if (this.projectGraphics.length !== this.assetCount) {
      this.updateProjectData();
    }
  }

  /**
   * @returns true if the user has the permission GIS_COMMON
   */
  get hasPermissionGISCommon(): boolean {
    return this.hasPermission(UserPermission.GIS_COMMON);
  }

  get cityStatePairs(): any[] {
    return this.projectHeaders.cities.map(
      (city: string, i) => ({ city, state: this.projectHeaders.states[i] }),
    );
  }

  async mounted(): Promise<void> {
    try {
      this.projectGuidList = (JSON.parse(this.ids) as string[]);

      // eslint-disable-next-line prefer-destructuring
      this.id = this.projectGuidList[0];
    } catch {
      this.id = this.ids;
      this.projectGuidList = [this.id];
    }

    this.getZoomFilter();

    this.loadProjectData();

    try {
      this.fetchMapPages(this.projectGuidList);
    } catch {
      console.error('Error Loading Map Pages');
    }
  }

  loadProjectData(): void {
    this.fetchProjectGraphics(this.projectGuidList).then(() => {
      if (this.projectGraphics) {
        this.selectedMapFeatures = this.projectGraphics;
        if (document.getElementById(this.projectGuidList[0]) != null) {
          document.getElementById(this.projectGuidList[0]).style.visibility = 'visible';
        }
      }
    }).catch((error) => {
      console.error(error);
      console.error('GRAPHICS ERROR');
    });
    this.fetchProjectHeaders(this.projectGuidList).catch(() => {
      console.error('HEADERS ERROR');
    });
  }

  refreshProject(): void{
    this.loadProjectData();
  }

  loadSelectedAsset(): void {
    this.getSelectedMapAsset();
    if (this.selectedMapAssetGuid == null || this.project == null) {
      return;
    }
    const selectedMapAsset = this.project.assets
      .find((asset) => asset.guid === this.selectedMapAssetGuid);
    if (selectedMapAsset == null) {
      return;
    }
    // Not sure what this does, but it breaks stuff if it's already jsonified
    if ((typeof selectedMapAsset.attributes as string) !== 'object') {
      selectedMapAsset.attributes = JSON.parse(
        selectedMapAsset.attributes.replace(/&quot;/g, '"'),
      );
    }
    this.selectedAsset = selectedMapAsset;
  }

  updateSelectedAsset(response: {selectedAssets: AssetData[], multiSelect: boolean}): void {
    if (response.selectedAssets.length === 0
      || response.selectedAssets.length > 1
      || response.multiSelect
    ) {
      this.selectedAsset = null;
    } else if (this.project) {
      this.selectedAsset = this.project.assets.find(
        (a) => a.guid === response.selectedAssets[0].guid,
      );
    } else if (this.projectGraphics) {
      const selectedAsset = this.projectGraphics.find(
        (a) => a.guid === response.selectedAssets[0].guid,
      );
      (selectedAsset as any).hasCustomerDeliverables = false;
      this.selectedAsset = selectedAsset as any;
    }
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  loaded(): void {
    this.fullyLoaded = true;
    this.getRouteParams();
    // eslint-disable-next-line no-unused-expressions
    (this.$refs?.assetfilters as any)?.applyFilters();
    this.setMapNeedsUpdated(true);
  }

  setFilterLayer(layer: string): void {
    const assetFilter = (this.$refs?.assetfilters as any);
    if (assetFilter?.setFilterLayer != null) {
      assetFilter.setFilterLayer(layer);
    }
  }

  getLocationManhole(asset: AssetRef | ProjectGraphics): Location[] & LocationLS[] {
    let location: Location[] & LocationLS[] = null;

    if (asset.location) {
      location = [];
      if (asset.location.length > 0) {
        // check for customer data
        const cdLocation = asset.location.find((loc) => loc.source === 'CustomerData');
        if (cdLocation) {
          location.push({
            latitude: cdLocation ? cdLocation.latitude : asset.location[0].latitude,
            longitude: cdLocation ? cdLocation.longitude : asset.location[0].longitude,
            source: cdLocation ? cdLocation.source : asset.location[0].source,
          });
        }
      }

      // check for inspection data
      const inspLocations = asset.location.find((loc) => loc.source === 'Inspections');
      if (inspLocations) {
        location.push({
          latitude: inspLocations.latitude,
          longitude: inspLocations.longitude,
          source: inspLocations.source,
        });
      }
    }
    return location;
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  getLocationLineSegment(asset: any): Location[] & LocationLS[] {
    const locationLS: Location[] & LocationLS[] = [];

    try {
      if (asset.location) {
        // check for customer data
        const cdLocations = asset.location.filter((loc) => loc.source === 'CustomerData');
        if (cdLocations.length > 1) {
          const cdLocation: LocationLS = {
            upstream: {} as Location,
            downstream: {} as Location,
            hasFlowData: 0,
          };

          [cdLocation.upstream, cdLocation.downstream] = cdLocations;
          cdLocation.source = 'CustomerData';
          locationLS.push(cdLocation);
        }

        // check for inspection data
        const inspLocations = asset.location.filter((loc) => loc.source === 'Inspections');
        if (inspLocations.length > 1) {
          const inspLocation: LocationLS = {
            upstream: {} as Location,
            downstream: {} as Location,
            hasFlowData: 0,
          };

          [inspLocation.upstream, inspLocation.downstream] = inspLocations;
          inspLocation.source = 'Inspections';
          locationLS.push(inspLocation);
        }
      }
      // Attributes shouldn't exist here anymore
      // else if (asset.attributes) {
      //   const attributes = JSON.parse(asset.attributes.replace(/&quot;/g, '"'));

      //   // TODO: project no longer gets attributes object
      //   //       consider adding struct up/dn to return object
      //   if (attributes.Wastewater_Structure_Dn && attributes.Wastewater_Structure_Up) {
      //     const upmh = this.project.assets.find(
      //       (a) => a.name === attributes.Wastewater_Structure_Up,
      //     );
      //     const dnmh = this.project.assets.find(
      //       (a) => a.name === attributes.Wastewater_Structure_Dn,
      //     );

      //     if (upmh && dnmh) {
      //       locationLS.upstream = this.getLocationManhole(upmh);
      //       locationLS.downstream = this.getLocationManhole(dnmh);
      //       locationLS.hasFlowData = 1;
      //     }
      //   }
      // }
    } catch (e) {
      console.error(`No location data found: ${asset.name}`);
      console.error(e);
    }
    return locationLS;
  }

  getLatestInspection(asset: AssetRef): Inspection {
    if (!asset.inspections) return null;

    let [retVal] = asset.inspections;

    asset.inspections.forEach((inspection) => {
      const inspDate = Date.parse(inspection.date);
      const retValDate = Date.parse(retVal.date);

      if (inspDate > retValDate) {
        retVal = inspection;
      }
    });

    return retVal;
  }

  fillAssetGraphics(): void {
    this.assetData = [];

    // Populating the assetData array using the project asset data
    this.projectGraphics.forEach((asset) => {
      // initial validation
      if (
        asset.guid == null
        || asset.guid === ''
        || asset.name == null
        || asset.name === ''
        // || asset.priority == null
        || asset.type == null
        || asset.location.length === 0
        || asset.location[0] === null
        || asset.location[0].source === ''
      ) {
        // bad asset, continue
        return;
      }

      let attributes = null;

      if (asset.attributes) {
        if (typeof asset.attributes === 'string') attributes = JSON.parse(asset.attributes.replace(/&quot;/g, '"'));
        else attributes = asset.attributes;
      }

      const assetData: AssetData = {
        guid: asset.guid,
        name: asset.name,
        score: asset.priority == null ? -1 : asset.priority,
        taskResult: asset.taskResult,
        type: asset.type,
        status: asset.status ? asset.status : '',
        lastInspected: asset.lastInspectedDate ? asset.lastInspectedDate : '',
        platform: asset.platform ? asset.platform : '',
        visible: true,
        location: [],
        pipeMaterial: attributes && attributes.PipeMaterial ? attributes.PipeMaterial : '',
        pipeSize: attributes && attributes.PipeSize ? attributes.PipeSize : '',
        omQuick: asset.omQuick ? asset.omQuick : '',
        structuralQuick: asset.structuralQuick ? asset.structuralQuick : '',
        slRatScore: asset.slRatScore,
      };

      switch (asset.type) {
        case 'Manhole':
          assetData.location.push(...this.getLocationManhole(asset));
          break;
        case 'Line Segment':
          assetData.location.push(...this.getLocationLineSegment(asset));
          break;
        case 'Lateral':
          assetData.location = asset.location as Location[] & LocationLS[];
          break;
        case 'Parcel':
          assetData.location = asset.location as Location[];
          break;
        default:
          console.log(`bad type: ${asset.type}`);
          // unknown type, continue
          return;
      }

      this.assetData.push(assetData);
    });
  }

  updateProjectData(): void {
    this.assetCount = this.projectGraphics.length;
    this.fillAssetGraphics();
  }

  clearFilters(): void {
    this.filters = {
      selectedAssets: [],
      type: [],
      collectionStatus: [],
      reportingStatus: [],
      dateInspected: [],
      customFromDate: '',
      customToDate: '',
      score: [],
      robot: [],
      status: [] as string[],
      slRatScore: [],
      matchFilters: [] as MatchFilter[],
      advancedFilters: [] as AdvancedFilter[],
      hiddenFilters: [] as FilterPair[],
    };
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (key === 'score') {
          this.filters[key].push(parseInt(value as string, 10));
        } else if (key === 'name') {
          this.filters.selectedAssets = this.filters.selectedAssets.concat(value);
        } else if (key in this.filters) {
          if (Array.isArray(this.filters[key])) {
            this.filters[key].push(value);
          } else {
            this.filters[key] = value;
          }
        }
      });
    }
  }
}
