










import {
  Component, Prop,
} from 'vue-property-decorator';
import UserEdit from '@/components/Admin/UserEditPage/UserEdit.vue';
import AdminPanelMixin, { EditUserData } from '@/components/Admin/AdminPanelMixin.vue';

@Component({
  components: {
    UserEdit,
  },
})
export default class EditUser extends AdminPanelMixin {
  @Prop() readonly id!: string;

  localUserData: EditUserData = null;

  async mounted(): Promise<void> {
    await this.fetchSelectedUserData(this.id);
    const foundUser = { ...this.selectedUserData };
    if (!foundUser) {
      this.$router.push({
        name: 'Error',
        params: {
          catchAll: 'Error',
          message:
          `User does not exist with id '${this.id}'.`,
        },
      });
    }
    this.localUserData = {
      detailedUserData: foundUser,
      auth0Id: foundUser.useridentity,
    };
  }
}
