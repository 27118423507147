
































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportDateHeaderInput from '../ReportHelpers/ReportInputHelpers/ReportDateHeaderInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportHeaderMixin, {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportHeaderMixin.vue';

@Component({
  components: {
    ReportDropDownInput,
    ReportAssignedToDropDownInput,
    ReportDateHeaderInput,
  },
})
export default class ServiceCallReportHeader extends ReportHeaderMixin {
  workOrderData: any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users' },
    {
      name: 'dateIssued',
      value: 'DateIssued',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'completeBy',
      value: 'CompleteBy',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'dateCompleted',
      value: 'DateCompleted',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'status',
      value: 'Status',
    },
  ];

  isScheduled(): boolean {
    return !!(this.workOrderData.completeBy && this.workOrderData.assignedTo);
  }
}
