import { ALL_SOLO_TASK_TYPES } from '@/common/Constants';
import { UserPermission } from '@/store/userpermissions/types';

export const STATUS_OPEN = 'Open';
export const STATUS_SCHEDULED = 'Scheduled';
export const STATUS_IN_PROGRESS = 'In Progress';
export const STATUS_COMPLETE = 'Complete';
export const STATUS_FOLLOW_UP_REQUIRED = 'Follow-Up Required';
export const LABEL_STATUS_ALL = 'All';
export const ALL_STATUSES = [
  STATUS_OPEN,
  STATUS_SCHEDULED,
  STATUS_COMPLETE,
  STATUS_FOLLOW_UP_REQUIRED,
  STATUS_IN_PROGRESS,
].sort();

export function hasNullMissionPermissionError(
  hasPermissionCheck: (permission: UserPermission) => boolean,
): boolean {
  return !hasPermissionCheck(
    UserPermission.WORK_ORDER_VIEW_CREATE_NULL_MISSION_BUTTON,
  );
}

export function getNullMissionError(
  taskTypeGuid: string,
  statusString: string,
  hasPermissionCheck: (permission: UserPermission) => boolean,
): string | undefined {
  if (hasNullMissionPermissionError(hasPermissionCheck)) {
    return 'You do not have permission to create a null mission.';
  }
  if (taskTypeGuid == null || !ALL_SOLO_TASK_TYPES.includes(taskTypeGuid)) {
    return 'Only solo task types are supported for null missions.';
  }
  if (statusString !== STATUS_IN_PROGRESS && statusString !== STATUS_SCHEDULED) {
    return `Null missions can only be created when the work order is ${STATUS_SCHEDULED} or ${STATUS_IN_PROGRESS}.`;
  }
  return undefined;
}

export function canHaveNullMission(
  taskTypeGuid: string,
  statusString: string,
  hasPermissionCheck: (permission: UserPermission) => boolean,
): boolean {
  return getNullMissionError(taskTypeGuid, statusString, hasPermissionCheck) == null;
}
