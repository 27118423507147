









































import { Component } from 'vue-property-decorator';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportDateHeaderInput from '../ReportHelpers/ReportInputHelpers/ReportDateHeaderInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportHeaderMixin from '../ReportHelpers/ReportHeaderMixin.vue';
import { WorkOrderFieldType } from '../ReportHelpers/ReportInterface.vue';

@Component({
  components: {
    ReportTextInput,
    ReportAssignedToDropDownInput,
    ReportDateHeaderInput,
  },
})
export default class FOGInspectionReportHeader extends ReportHeaderMixin {
  workOrderData: any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users' },
    { name: 'inspectionType', value: 'AssetPlanLibrary' },
    { name: 'frequency', value: 'Frequency' },
    { name: 'fogType', value: 'FOGEstType' },
    { name: 'apn', value: 'FSEAPN' },
    {
      name: 'dateIssued',
      value: 'DateIssued',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'completeBy',
      value: 'CompleteBy',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'address', value: 'Street' },
    { name: 'city', value: 'City' },
    { name: 'phone', value: 'FSEPhone' },
    { name: 'managerOnDuty', value: 'FSEManagerOnDuty' },
    {
      name: 'inspectionDate',
      value: 'InspDate',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'dateCompleted',
      value: 'DateCompleted',
      type: WorkOrderFieldType.DATE,
    },
  ];
}
