























































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import vue from 'vue';
import { ReportMutations } from '@/store/report/mutations';
import { PRIORITY_ITEMS, WORK_ORDER_STATUSES } from '@/common/Constants';
import ServiceCallReport from '../ServiceCall/ServiceCallReport.vue';
import { SubInspectionWorkOrder } from './types';
import ReportHeaderMixin from './ReportHeaderMixin.vue';
import SSOReportReport from '../SSO/SSOReportReport.vue';
import CleaningWorkOrderReport from '../Cleaning/CleaningWorkOrderReport.vue';
import GeneralMaintenanceReport from '../General Maintenance/GeneralMaintenanceReport.vue';
import GenericReport from '../GenericWork/GenericReport.vue';
import PipeReliningReport from '../PipeReliningReport/PipeReliningReport.vue';
import RepairReport from '../Repair/RepairReport.vue';
import SLRatReport from '../SLRat/SLRatReport.vue';
import SmokeTestReport from '../Smoke Test/SmokeTestReport.vue';
import StructureInspectionReport from '../Structure Inspection/StructureInspectionReport.vue';
import FOGInspectionReport from '../FOG Inspection/FOGInspectionReport.vue';
import ReportBodyMixin from './ReportBodyMixin.vue';
import TimeAndMaterialsTable from '../TimeAndMaterials/TimeAndMaterialsTable.vue';
import CleaningWorkOrderReportHeader from '../Cleaning/CleaningWorkOrderReportHeader.vue';
import PipeReliningReportHeader from '../PipeReliningReport/PipeReliningReportHeader.vue';
import Observation from '../ObservationFullReport/ObservationFullReport.vue';
import MACP2 from '../MACP2/MACP2.vue';

const reportModule = namespace('report');

@Component({
  components: {
    CleaningWorkOrderReport,
    FOGInspectionReport,
    GeneralMaintenanceReport,
    GenericReport,
    PipeReliningReport,
    RepairReport,
    SLRatReport,
    SSOReportReport,
    ServiceCallReport,
    SmokeTestReport,
    StructureInspectionReport,
    TimeAndMaterialsTable,
    PipeReliningReportHeader,
    CleaningWorkOrderReportHeader,
    MACP2,
    Observation,
  },
})
export default class ReportWrapper extends vue {
  @VModel() modelValue!: any | undefined;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop({ default: true }) canPlan: boolean;

  @Prop() dataStandardFormat: any;

  @Prop() dataStandardSchema: any;

  @reportModule.Mutation(ReportMutations.SET_REPORT_ERRORS) setReportErrors;

  subInspectionData: SubInspectionWorkOrder[] = [];

  @Watch('modelValue', { deep: true })
  onModelValueChange(): void {
    this.$nextTick().then((_) => {
      this.updateAdditionalReportData();
      this.setReportErrors(this.statusErrorMessages());
    });
  }

  get sharedDataSource(): any {
    return this.modelValue.SharedData ?? this.modelValue;
  }

  set sharedDataSource(newVal) {
    if (this.modelValue.SharedData) {
      this.modelValue.SharedData = newVal;
    } else {
      this.modelValue = newVal;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateReportData(): void {}

  updateReportSubData(jsonData: SubInspectionWorkOrder[]): void {
    this.subInspectionData = jsonData;
  }

  // #region functions for creating Service Call Report
  isScheduled(): boolean {
    return this.headerPage()?.isScheduled();
  }

  canCreate(): boolean {
    return this.reportPages().every((x) => x.canCreate());
  }
  // #endregion

  // #region functions for setting/getting status

  workOrderStatus(): string {
    if (this.modelValue.SharedData) {
      return this.modelValue.SharedData.Status;
    }
    return this.modelValue.Status;
  }

  canSetToScheduled(): boolean {
    return this.headerPage()?.canSetToScheduled();
  }

  canSetToInProgress(): boolean {
    return this.reportPages().every((x) => x.canSetToInProgress());
  }

  canSetToInFollowUpRequired(): boolean {
    return this.reportPages().every((x) => x.canSetToInFollowUpRequired());
  }

  canSetToComplete(): boolean {
    return this.reportPages().every((x) => x.canSetToComplete());
  }

  isDateCompleteFilled(): boolean {
    return this.headerPage()?.isDateCompleteFilled();
  }
  // #endregion

  // #region functions for getting pages/header data

  reportPages(): ReportBodyMixin[] {
    const reportPages = this.$refs.pdfReportPage;
    if (!reportPages) {
      return [];
    }
    if (Array.isArray(reportPages)) {
      return reportPages as ReportBodyMixin[];
    }
    return [reportPages as ReportBodyMixin];
  }

  headerPage(): ReportHeaderMixin {
    const headers = this.reportPages();
    if (!headers || headers.length === 0) {
      return null;
    }
    return headers[0].headerPage();
  }
  // #endregion

  // #region functions for error checking

  statusErrorMessages(): any {
    const errorReasonString = this.errorReason();
    if (!errorReasonString) {
      return null;
    }
    const ret = {};
    ret[this.workOrderStatus()] = errorReasonString;
    return ret;
  }

  errorReason(): string | undefined {
    switch (this.workOrderStatus()) {
      case 'Scheduled':
        if (!this.assignedToValue()) {
          return 'Required for Scheduled status.';
        }
        break;

      case 'In Progress':
        if (!this.isBodyModified()) {
          return 'Make an edit to the work order form to set status to In Progress.';
        }
        break;

      case 'Follow-Up Required':
        if (!this.canSetToInFollowUpRequired()) {
          return 'Follow-Up selection other than None is required for Follow-Up status.';
        }
        break;
      case 'Complete':
        if (!this.isDateCompleteFilled()) {
          return 'Required for Complete status.';
        }
        break;
      case 'Open':
      default:
        return null;
    }
    return null;
  }

  resetForm(): boolean {
    return this.reportPages().every((x) => x.resetForm());
  }

  isBodyModified(): boolean {
    return this.reportPages().some((x) => x.isBodyModified());
  }
  // #endregion

  assignedToValue(): string {
    return this.headerPage()?.assignedToValue();
  }

  showTimeAndMaterials(): boolean {
    const pages = this.reportPages();
    if (pages.length === 0) {
      return false;
    }
    return pages[0].showTimeAndMaterials();
  }

  timeAndMaterialsPageNumber(): number {
    return (this.modelValue?.InspectionData?.length ?? 1) + 1;
  }

  updateAdditionalReportData(): void {
    const headerData = this.sharedDataSource;
    const statusGuid = WORK_ORDER_STATUSES.find(
      (wo) => wo.description === headerData['Status'],
    )?.guid;
    const priorityGuid = PRIORITY_ITEMS.find(
      (p) => p.name === headerData['Priority'],
    )?.guid;
    const obj = {
      assignedTo: this.assignedToValue(),
      scheduledDueDate: headerData['CompleteBy'],
      completeDate: headerData['DateCompleted'],
      statusGuid,
      priorityGuid,
      scheduledStartDate: headerData['DateIssued'],
    };
    this.$emit('updateAdditionalReportData', obj);
  }
}
