import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { ReportState } from './types';

function hasErrorMessageOfType(reportErrors: any[], statusType: string): string {
  const obj = reportErrors;
  if (!obj) {
    return null;
  }
  // eslint-disable-next-line no-prototype-builtins
  return obj.hasOwnProperty(statusType) ? obj[statusType] : null;
}

const getters: GetterTree<ReportState, RootState> = {
  scheduledErrorMessage(state: ReportState): string {
    return hasErrorMessageOfType(state.reportErrors, 'Scheduled');
  },

  inProgressErrorMessage(state: ReportState): string {
    return hasErrorMessageOfType(state.reportErrors, 'In Progress');
  },

  followUpErrorMessage(state: ReportState): string {
    return hasErrorMessageOfType(state.reportErrors, 'Follow-Up Required');
  },

  completeErrorMessage(state: ReportState): string {
    return hasErrorMessageOfType(state.reportErrors, 'Complete');
  },
};

export default getters;
