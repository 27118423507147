












































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { BasicSelect } from 'vue-search-select';
import { format } from 'date-fns';
import {
  PRIORITY_HIGH,
  PRIORITY_MEDIUM,
  PRIORITY_LOW,
  FOG_TASK_TYPE,
  GENERAL_MAINTENANCE_TASK_TYPE,
  SERVICE_CALL_TASK_TYPE,
  SSO_TASK_TYPE,
  REPAIR_TASK_TYPE,
  WORK_ORDER_STATUSES,
  EASEMENT_CLEANING_TASK_TYPE,
  HYDRO_CLEANING_TASK_TYPE,
  RODDING_CLEANING_TASK_TYPE,
  ROOT_CUTTING_CLEANING_TASK_TYPE,
} from '@/common/Constants';
import { namespace } from 'vuex-class';
import { UserActions } from '@/store/users/actions';
import { UserData } from '@/store/users/types';
import { Crew, SchedulingData, WorkOrderTableData } from '@/store/planning/types';
import { User } from '@auth0/auth0-spa-js';
import { UserPermission } from '@/store/userpermissions/types';
import planning from '@/store/planning';
import { FIELD_CREW, CREW_LEAD } from '../../auth/roles';
import ScheduleWorkOrder from './ScheduleWorkOrder.vue';
import { WorkOrderReqRes, WorkOrderStatus } from './types';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const userModule = namespace('users');
const planningModule = namespace('planning');

@Component({
  components: {
    BasicSelect,
    ScheduleWorkOrder,
  },
})
export default class PlanningSchedule extends UserPermissionsMixin {
  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @planningModule.State('workOrderCrewData') workOrderCrewData: Crew[] | undefined;

  @planningModule.State('patchLoading') patchLoading: boolean;

  @Prop({ default: () => [] }) editItems: WorkOrderTableData[];

  @Prop() projectGuid: string;

  @Prop({ default: false }) editDialog: boolean;

  valid = true;

  dialog = false;

  priorityLevels = ['Low', 'Medium', 'High'];

  endDate = '';

  endDateMenu = false;

  scheduleDialog = false;

  equipmentConfig = '';

  equipmentConfigOptions: string[] = [];

  selectedPriority = '';

  priorityRules = [
    (v: string): string | boolean => v !== '' || 'Priority is required',
    (v: string): string | boolean => !!v || 'Priority is required',
  ];

  statusRules = [
    (v: string): string | boolean => v === 'Scheduled'
    || 'Crew Lead is required for Scheduled status',
  ];

  editConfirmDialog = false;

  comments = '';

  crewNameList: Array<{ value: number; text: string; guid: string }> = [];

  leaderNameModel = { text: '', guid: '' };

  oldLead = { text: '', guid: '' };

  crewNames: Array<{ text: string; guid: string }> = [];

  search = null;

  pcmRecipients = [];

  pcmRecipientsModel = [];

  user: User = this.$auth.user;

  schedule: SchedulingData = null;

  validScheduleItemGuids = [
    FOG_TASK_TYPE,
    EASEMENT_CLEANING_TASK_TYPE,
    ROOT_CUTTING_CLEANING_TASK_TYPE,
    RODDING_CLEANING_TASK_TYPE,
    HYDRO_CLEANING_TASK_TYPE,
    GENERAL_MAINTENANCE_TASK_TYPE,
    SERVICE_CALL_TASK_TYPE,
    SSO_TASK_TYPE,
    REPAIR_TASK_TYPE,
  ];

  oldWorkOrderStatus = null;

  workOrderStatus = null;

  scheduleValid = false;

  @Watch('editDialog')
  onSelectedNodesChange(): void {
    this.resetForm();
    if (this.editItems.length === 0 || !this.editDialog) {
      return;
    }
    (this.$refs.form as any).resetValidation();

    const firstNode = this.editItems[0];

    if (firstNode.scheduledDueDate !== null) {
      const dueDate = new Date(firstNode.scheduledDueDate);
      this.endDate = format(dueDate, 'yyyy-MM-dd');
    }
    this.selectedPriority = firstNode.priorityDescription;
    this.workOrderStatus = WORK_ORDER_STATUSES.find(
      (s) => s.description === firstNode.status,
    );
    this.oldWorkOrderStatus = { ...this.workOrderStatus };

    this.getWorkOrderStatusOptions();

    if (this.allUsers && this.allUsers.length > 0) this.onAllUsersUpdate();

    this.planningDialogOpen();
    if (this.doSchedulesMatch()) {
      const parsedSchedule = JSON.parse(this.editItems[0].schedulingData);
      this.schedule = {
        interval: parsedSchedule.Interval,
        period: parsedSchedule.Period,
        startDate: parsedSchedule.StartDate.slice(0, 10),
        endType: parsedSchedule.EndType,
        endValue: parsedSchedule.EndValue,
      };
    }
    if (firstNode.notes) {
      this.comments = firstNode.notes;
    }
  }

  @Watch('allUsers')
  onAllUsersUpdate(): void {
    const newCrewNameList = [];
    this.allUsers.forEach((user) => {
      if (
        user.role === FIELD_CREW[0]
        || user.role === FIELD_CREW[1]
        || user.role === CREW_LEAD
      ) {
        newCrewNameList.push({
          text: `${user.firstname} ${user.lastname}`,
          guid: user.guid,
        });
      }
    });
    this.crewNameList = newCrewNameList;
    if (this.crewNames.length === 0 || this.leaderNameModel?.text === '') {
      this.planningDialogOpen();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get today(): string {
    return new Date().toISOString().slice(0, 10);
  }

  get disabled(): boolean {
    return !(this.editItems.length > 0);
  }

  get canSchedule(): boolean {
    return this.editItems.every((item) => this.validScheduleItemGuids.includes(item.taskTypeGuid));
  }

  get selectedWOString(): string {
    return this.editItems.map((item) => `${item.workOrderNumber} (${item.nodeName})`).join(', ');
  }

  getWorkOrderStatusOptions(): WorkOrderStatus[] {
    const options = ['O', 'S'];
    if (this.workOrderStatus?.description === 'In Progress') options.push('IP');
    return WORK_ORDER_STATUSES.filter((s) => options.includes(s.short_name));
  }

  get isP3dCollection(): boolean {
    return this.editItems.length > 0
           && this.editItems.filter(
             (item) => item.taskTypeGuid === '2180e6e0-1ca9-11ed-b6cf-5f0bd137feea', // P3D Collection
           ).length === this.editItems.length;
  }

  get applyStatusRules(): boolean {
    return this.leaderNameModel?.text === '' && this.workOrderStatus?.description === 'Scheduled';
  }

  mounted(): void {
    this.fetchAllUserData();
    if (this.user.email != null || this.user.email !== '') {
      this.pcmRecipients.push(this.user.email);
      this.pcmRecipientsModel = { ...this.pcmRecipients };
    }
    this.onSelectedNodesChange();
  }

  buildDataList(type: string): any {
    const dataList: WorkOrderReqRes[] = [];
    const priority = {
      guid: PRIORITY_LOW,
      category: {
        guid: 'cf12207e-9707-11ea-9cfe-6f062ca617ff',
        name: 'Priority',
      },
      description: 'Low',
      short_name: 'LOW',
      order: 1,
    };

    this.editItems.forEach((node: any) => {
      const data = {
        guid: node.guid,
        priority_item: priority,
        scheduled_start_date: '',
        scheduled_end_date: '',
        notes: '',
        scheduled_start_date_null: true,
        scheduled_end_date_null: true,
        crewLead:
          this.leaderNameModel && this.leaderNameModel.text !== ''
            ? [this.leaderNameModel.guid]
            : [],
        crew: this.crewNames.map((x) => x.guid),
        schedulingData: this.schedule,
        status_item: this.workOrderStatus,
        task_result: null,
        followUpReason: null,
        equipmentConfig: null,
        equipmentConfigOptions: null,
      };

      if (type === 'save') {
        switch (this.selectedPriority) {
          case 'Medium':
            data.priority_item = {
              guid: PRIORITY_MEDIUM,
              category: {
                guid: 'cf12207e-9707-11ea-9cfe-6f062ca617ff',
                name: 'Priority',
              },
              description: 'Medium',
              short_name: 'MED',
              order: 2,
            };
            break;
          case 'High':
            data.priority_item = {
              guid: PRIORITY_HIGH,
              description: 'High',
              category: {
                guid: 'cf12207e-9707-11ea-9cfe-6f062ca617ff',
                name: 'Priority',
              },
              short_name: 'HI',
              order: 3,
            };
            break;
          default:
            break;
        }

        data.scheduled_start_date_null = false;
        data.scheduled_end_date_null = false;
        data.scheduled_end_date = this.endDate;
      }

      data.priority_item = priority;

      if (this.comments) {
        const note = this.comments;
        data.priority_item = priority;
        data.scheduled_end_date = this.endDate;
        data.notes = note;
      }

      if (this.isP3dCollection) {
        data.equipmentConfig = this.equipmentConfig;
        data.equipmentConfigOptions = this.equipmentConfigOptions;
      }

      dataList.push(data);
    });
    return dataList;
  }

  isSearchEmail(email: string): boolean {
    if (email == null) {
      return false;
    }
    return (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) != null
    );
  }

  checkForNewRecipientsAndAdd(): void {
    for (let i = 0; i < this.pcmRecipientsModel.length; i += 1) {
      if (!this.isSearchEmail(this.pcmRecipientsModel[i])) {
        this.pcmRecipientsModel.splice(i, 1);
        break;
      }
      if (!this.pcmRecipients.includes(this.pcmRecipientsModel[i])) {
        this.pcmRecipients.push(this.pcmRecipientsModel[i]);
        break;
      }
    }
  }

  resetForm(): void {
    this.endDate = '';
    this.leaderNameModel = { text: '', guid: '' };
    this.oldLead = { text: '', guid: '' };
    this.crewNames = [];
    this.selectedPriority = '';
    this.pcmRecipients = [];
    this.search = null;
    if (this.user.email != null || this.user.email !== '') {
      this.pcmRecipients.push(this.user.email);
      this.pcmRecipientsModel = { ...this.pcmRecipients };
    }
    this.schedule = null;
    this.editConfirmDialog = false;
    this.workOrderStatus = null;
    this.oldWorkOrderStatus = null;
    this.getWorkOrderStatusOptions();
    (this.$refs.form as any).reset();
  }

  saveScheduling(newSchedule: SchedulingData): void {
    this.schedule = newSchedule;
    this.scheduleDialog = false;
    this.$forceUpdate();
  }

  scheduleString(): string | undefined {
    if (!this.schedule) {
      if (this.editItems.some((i) => i.schedulingData != null)) {
        return 'Multiple schedules';
      }
      return null;
    }

    return `Repeats every ${this.schedule.interval} ${
      this.schedule.period
    }, ${this.scheduleSubString()}`;
  }

  scheduleSubString(): string {
    const lastCompletedDateString = this.editItems[0].completeDate;
    if (this.doLastCompleteDatesMatch()) {
      const lastCompletedDate = new Date(lastCompletedDateString);
      switch (this.schedule.period.toLowerCase()) {
        case 'week':
        case 'weeks':
          lastCompletedDate.setDate(lastCompletedDate.getDate() + 7 * this.schedule.interval);
          break;
        case 'month':
        case 'months':
          lastCompletedDate.setMonth(lastCompletedDate.getMonth() + this.schedule.interval);
          break;
        case 'year':
        case 'years':
          lastCompletedDate.setFullYear(lastCompletedDate.getFullYear() + this.schedule.interval);
          break;
        default:
          break;
      }
      return `next due ${lastCompletedDate.toISOString().substring(0, 10)}`;
    }
    // if there's no last complete date, next due is the start date
    if (lastCompletedDateString == null) {
      return `next due ${this.schedule.startDate}`;
    }
    // if the last complete dates don't match, display start date instead
    return `starting on ${this.schedule.startDate}`;
  }

  removeScheduling(): void {
    this.schedule = null;
    this.$forceUpdate();
  }

  removePlanning(): void {
    // removes complete by date and assignee
    this.endDate = '';
    this.leaderNameModel = { text: '', guid: '' };
    this.oldLead = { text: '', guid: '' };
    this.oldWorkOrderStatus = WORK_ORDER_STATUSES.find((s) => s.short_name === 'O');
    this.submit();
  }

  async submit(): Promise<void> {
    (this.$refs.form as any).validate();
    if (!this.valid) return;
    // setting work order back to Open opens confirmation dialog
    if (this.workOrderStatus?.description === 'Open'
    && this.oldWorkOrderStatus?.description !== this.workOrderStatus?.description) {
      this.editConfirmDialog = true;
      return;
    }
    // if status is In Progress
    if (this.workOrderStatus?.description === 'In Progress'
    && this.oldLead !== this.leaderNameModel) {
      if (this.leaderNameModel?.text === '') {
        // removing assignee changes status to Open
        this.workOrderStatus = WORK_ORDER_STATUSES.find((s) => s.short_name === 'O');
      } else {
        // changing assignee sets status to Scheduled
        this.workOrderStatus = WORK_ORDER_STATUSES.find((s) => s.short_name === 'S');
      }
    }
    this.$emit('save', {
      emails: this.pcmRecipients,
      requestList: this.buildDataList('save'),
      createPCM: true,
    });
    await new Promise((resolve) => {
      const watcher = this.$watch(() => this.patchLoading, (newVal) => {
        // If loading is false, close the dialog
        if (!newVal) {
          this.resetForm();
          resolve(newVal);
          watcher();
        }
      });
    });
  }

  cancel(): void {
    this.$emit('cancel');
    this.resetForm();
  }

  reset(): void {
    this.$emit('reset', {
      emails: this.pcmRecipients,
      requestList: this.buildDataList('reset'),
    });
    this.resetForm();
  }

  doSchedulesMatch(): boolean {
    if (
      this.editItems.length > 0
      && this.editItems[0].schedulingData != null
    ) {
      const firstData = this.editItems[0].schedulingData;
      return this.editItems.every(
        (item) => item?.schedulingData != null
          && item?.schedulingData === firstData,
      );
    }
    return false;
  }

  doLastCompleteDatesMatch(): boolean {
    if (
      this.editItems.length > 0
      && this.editItems[0].completeDate !== null
    ) {
      const firstData = this.editItems[0].completeDate;
      return this.editItems.every(
        (item) => item?.completeDate != null
          && item?.completeDate === firstData,
      );
    }
    return false;
  }

  planningDialogOpen(): void {
    if (this.workOrderCrewData && this.allUsers) {
      const workerGuids = this.workOrderCrewData.map((crew) => crew.crewGuid);
      const crewLeadGuid = this.workOrderCrewData.find((crew) => crew.isCrewLead)?.crewGuid;
      const newCrew = [];
      this.allUsers.forEach((user) => {
        const foundWorkerIndex = workerGuids.findIndex((worker) => worker === user.guid);
        if (foundWorkerIndex !== -1) {
          // allows crew lead to be found if set, if not checks uses old method
          if (crewLeadGuid && user.guid === crewLeadGuid) {
            this.leaderNameModel = {
              text: `${user.firstname} ${user.lastname}`,
              guid: user.guid,
            };
            this.oldLead = { ...this.leaderNameModel };
          } else if (!crewLeadGuid && user.role === CREW_LEAD) {
            this.leaderNameModel = {
              text: `${user.firstname} ${user.lastname}`,
              guid: user.guid,
            };
            this.oldLead = { ...this.leaderNameModel };
          } else {
            workerGuids.slice(foundWorkerIndex, 1);
            newCrew.push({
              text: `${user.firstname} ${user.lastname}`,
              guid: user.guid,
            });
          }
        }
      });
      this.crewNames = newCrew;
    }
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_RESET_PLANNING
  */
  get hasPermissionWorkOrderResetPlanning(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_RESET_PLANNING);
  }
}
