



















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import { GenericWorkFields } from './types';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import GenericReportHeader from './GenericReportHeader.vue';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';

@Component({
  components: {
    ReportFooter,
    ReportTextInput,
    GenericReportHeader,
  },
})
export default class GenericReport extends ReportBodyMixin {
  // #region Abstract fields
  workOrderData: GenericWorkFields | any = {};

  workOrderFields = [
    { name: 'workDescription', value: 'WorkPerformed' },
    { name: 'comments', value: 'Comments' },
    { name: 'streetAddress', value: 'Street' },
    { name: 'followUp', value: 'FollowUp' },
    { name: 'specialInstructions', value: 'Instructions' },
    { name: 'specialConditions', value: 'SpecialCondition' },
    { name: 'followUp', value: 'FUComments' },
  ];
  // #endregion

  canSetToInFollowUpRequired(): boolean {
    return !!this.workOrderData.followUp;
  }
}
