

















































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, PropSync, Watch } from 'vue-property-decorator';
import { OrganizationData } from '@/store/organizations/types';
import { CustomerData } from '@/store/customers/types';
import { UserPermission } from '@/store/userpermissions/types';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';
import CustomerDialogMultiSelectCustomer from '../Customers/CustomerDialogMultiSelectCustomer.vue';

@Component({
  components: {
    CustomerDialogMultiSelectCustomer,
  },
})
export default class AddCustomerButton extends AdminPanelMixin {
  @PropSync('selectedUser') readonly synchedSelectedUser: EditUserData;

  addCustomerDialog = false;

  userCustomers = null;

  userOrganization: OrganizationData = null;

  @Watch('addCustomerDialog')
  onAddCustomerDialogChange(): void {
    if (this.addCustomerDialog) {
      this.userOrganization = this.getUserOrganization();
      this.userCustomers = this.synchedSelectedUser.detailedUserData.customerGuids
        .map((guid) => this.customerDataList.find((cust) => cust.guid === guid));
    }
  }

  getUserOrganization(): OrganizationData {
    if (!this.organizationDataList) {
      return null;
    }
    return this.organizationDataList.find(
      (org) => org.guid === this.synchedSelectedUser.detailedUserData.organizationGuids[0],
    );
  }

  /**
   * @description Determines if the customer list should be dictated by user's organization.
   * @returns defaults to true, false if user is under RedZone Organization
   */
  get requireOrganization(): boolean {
    return !this.isUsersRedzoneOrganizationMember([this.synchedSelectedUser.detailedUserData]);
  }

  /**
   * @returns true if the user has the permission ADMIN_CUSTOMER_SECTION_CREATEACUSTOMER
   */
  get hasPermissionAdminCreateACustomer(): boolean {
    return this.hasPermission(UserPermission.ADMIN_CUSTOMER_SECTION_CREATEACUSTOMER);
  }

  saveAddCustomer(): void {
    this.synchedSelectedUser.detailedUserData.customerGuids = (
      this.userCustomers as CustomerData[]
    ).map((x) => x.guid);
    this.addCustomerDialog = false;
  }
}
