



























































import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { AssetData } from '@/store/asset/types';
import { namespace } from 'vuex-class';
import { AssignmentActions } from '@/store/assignments/actions';
import { WorkOrderTableData } from '@/store/planning/types';
import CodingForm from '@/components/CodingForm/CodingForm.vue';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { AssignmentPost } from '@/store/assignments/types';
import { SnackBarActions } from '@/store/integritySnackBar/actions';

const assignmentsModule = namespace('assignments');
const projectModule = namespace('project');
const integritySnackBar = namespace('integritySnackBar');

@Component({
  components: {
    CodingForm,
  },
})
export default class CreateWorkOrderForm extends UserPermissionsMixin {
  @Prop({ default: '' }) workOrderReport: string;

  @Prop() selectedAsset: AssetData;

  @Prop() workOrderTypeGuid: string;

  @Prop() initJsonData: any;

  @PropSync('editWorkOrderDialog') synchedEditWorkOrderDialog: boolean;

  @assignmentsModule.Action(AssignmentActions.POST_ASSIGNMENTS) postAssignments;

  @assignmentsModule.State('loadError') loadError;

  @assignmentsModule.State('postAssignmentData') postAssignmentData;

  @projectModule.State('projectGuids') projectGuids: string[];

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  displayDupeMessage = false;

  currentItem: WorkOrderTableData = {
    guid: '',
    assignmentAssetGuid: '',
    projectGuid: '',
    taskTypeGuid: '',
    isRedzone: true,
    hasDataStandard: true,
    taskResultDesc: '',
    nodes: [],
  };

  @Watch('selectedAsset')
  onSelectedAssetChange(): void {
    if (!this.selectedAsset) {
      return;
    }
    this.currentItem = {
      guid: '',
      assignmentAssetGuid: this.selectedAsset.guid,
      projectGuid: this.projectGuids[0] ?? '',
      taskTypeGuid: this.workOrderTypeGuid,
      isRedzone: true,
      hasDataStandard: true,
      taskResultDesc: '',
      nodes: [
        {
          nodeGuid: this.selectedAsset.guid,
          nodeName: this.selectedAsset.name,
          workOrderGuid: '',
          workOrderNumber: '',
        },
      ],
    };
  }

  @Watch('synchedEditWorkOrderDialog')
  async onSynchedEditWorkOrderDialogChange(): Promise<void> {
    if (!this.synchedEditWorkOrderDialog) {
      return;
    }
    await this.$nextTick();
    if (this.initJsonData) {
      const createCodingForm = this.$refs.createCodingForm as any;
      if (createCodingForm) {
        createCodingForm.resetForm();
        createCodingForm.updateReportData(this.initJsonData);
      }
    }
  }

  closeDialog(): void {
    this.displayDupeMessage = false;
    this.synchedEditWorkOrderDialog = false;
  }

  async createWorkOrder(item, workOrderStatus): Promise<void> {
    const payload: AssignmentPost[] = [];
    payload.push({
      assetGuid: this.selectedAsset.guid,
      workOrderData: [
        {
          workOrderType: this.workOrderTypeGuid,
          workOrderJsonData: JSON.stringify(item),
          workOrderStatus,
        },
      ],
    });
    await this.postAssignments(payload);
    let message = 'Assignment Added';
    let color = 'green';
    if (
      this.postAssignmentData != null
      && this.postAssignmentData !== 'Success'
    ) {
      this.displayDupeMessage = true;
      color = '#e61e25';
      message = 'Error Adding Assignment';
    }
    this.showSnackBar({
      message,
      color,
      showSnackBar: true,
    });
    // reload data on a clean add
    if (this.loadError == null) {
      this.closeDialog();
      await this.$emit('refresh');
    }
  }

  /**
   * @returns true if the user has the permission WORK_ORDER_PLANNING
   */
  get hasPermissionWorkOrderPlanning(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_PLANNING);
  }

  /**
   * @returns true if the user has the permission WORK_ORDER_EDIT_FORM
   */
  get hasPermissionWorkOrderEditForm(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_EDIT_FORM);
  }

  /**
   * @returns true if the user has the permission WORK_ORDER_EXPORT_PDF
   */
  get hasPermissionWorkOrderExportPdf(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_EXPORT_PDF);
  }
}
