



























































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import { ServiceCallReportFields } from './types';
import ServiceCallReportHeader from './ServiceCallReportHeader.vue';
import ReportFooter from '../ReportFooter.vue';
import ReportInputCheckboxTable from '../ReportHelpers/ReportInputHelpers/ReportInputCheckboxTable.vue';
import ReportBodyMixin, {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportBodyMixin.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';

@Component({
  components: {
    ServiceCallReportHeader,
    ReportFooter,
    ReportInputCheckboxTable,
    ReportCalendarInput,
    ReportTextInput,
    ReportRadioRowInput,
  },
})
export default class ServiceCallReport extends ReportBodyMixin {
  // #region Abstract fields
  workOrderData: ServiceCallReportFields | any = {};

  workOrderFields = [
    { name: 'activityRemarks', value: 'ActivityRemarks' },
    { name: 'address', value: 'Address', required: true },
    {
      name: 'arrivalDate',
      value: 'ArrivalDate',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'arrivalTime',
      value: 'ArrivalTime',
      type: WorkOrderFieldType.TIME,
    },
    { name: 'busPhone', value: 'BusPhone' },
    {
      name: 'callDate',
      value: 'CallDate',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'caller', value: 'Caller', required: true },
    {
      name: 'callTime',
      value: 'CallTime',
      type: WorkOrderFieldType.TIME,
      required: true,
    },
    {
      name: 'causeCapacity',
      value: 'CauseCapacity',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causeGrease',
      value: 'CauseGrease',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causeGrit',
      value: 'CauseGrit',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causeOther',
      value: 'CauseOther',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causePipeBroken',
      value: 'CausePipeBroken',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causePipeSag',
      value: 'CausePipeSag',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causePowerFailure',
      value: 'CausePowerFailure',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causeRags',
      value: 'CauseRags',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causeRocks',
      value: 'CauseRocks',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causeRoots',
      value: 'CauseRoots',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causeUnknown',
      value: 'CauseUnknown',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'causeVandalism',
      value: 'CauseVandalism',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'cellPhone', value: 'CellPhone' },
    {
      name: 'complaintReceivedBy',
      value: 'ComplaintReceivedBy',
      required: true,
    },
    {
      name: 'dispatchDate',
      value: 'DispatchDate',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'dispatchDescription', value: 'DispatchDescription' },
    {
      name: 'dispatchTime',
      value: 'DispatchTime',
      type: WorkOrderFieldType.TIME,
    },
    { name: 'estimatedFlowRate', value: 'EstimatedFlowRate' },
    { name: 'explanationIfNoneOther', value: 'ExplanationIfNoneOther' },
    { name: 'followReferralBy', value: 'FollowReferralBy' },
    {
      name: 'followReferralDate',
      value: 'FollowReferralDate',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'followUpReferredTo', value: 'FollowUpReferredTo' },
    { name: 'followUpRemarks', value: 'FollowUpRemarks' },
    { name: 'generalArea', value: 'GeneralArea' },
    { name: 'homePhone', value: 'HomePhone' },
    {
      name: 'inspDate',
      value: 'InspDate',
      type: WorkOrderFieldType.DATE,
      required: true,
    },
    {
      name: 'lOASentDate',
      value: 'LOASentDate',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'locationDistrictLateral',
      value: 'LocationDistrictLateral',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'locationLiftStation',
      value: 'LocationLiftStation',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'locationMain',
      value: 'LocationMain',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'locationNothingFound',
      value: 'LocationNothingFound',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'locationOther',
      value: 'LocationOther',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'locationPrivateLateral',
      value: 'LocationPrivateLateral',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallAlarm',
      value: 'NatureofCallAlarm',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallBackUp',
      value: 'NatureofCallBackUp',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallComplaint',
      value: 'NatureofCallComplaint',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallCompliment',
      value: 'NatureofCallCompliment',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallDoorHanger',
      value: 'NatureofCallDoorHanger',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallLiftStation',
      value: 'NatureofCallLiftStation',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallNoise',
      value: 'NatureofCallNoise',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallOdor',
      value: 'NatureofCallOdor',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallOther',
      value: 'NatureofCallOther',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallSSO',
      value: 'NatureofCallSSO',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallStoppage',
      value: 'NatureofCallStoppage',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallStreetSweeping',
      value: 'NatureofCallStreetSweeping',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'natureofCallVermin',
      value: 'NatureofCallVermin',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'notificationDate',
      value: 'NotificationDate',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'notifiedBy', value: 'NotifiedBy' },
    {
      name: 'notifiedByEmail',
      value: 'NotifiedByEmail',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'notifiedByLetter',
      value: 'NotifiedByLetter',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'notifiedByNone',
      value: 'NotifiedByNone',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'notifiedByOther',
      value: 'NotifiedByOther',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'notifiedByPhone',
      value: 'NotifiedByPhone',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'notifiedInPerson',
      value: 'NotifiedInPerson',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedCCTV',
      value: 'PerformedCCTV',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedChemicals',
      value: 'PerformedChemicals',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedCleanup',
      value: 'PerformedCleanup',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedEmergencyRelief',
      value: 'PerformedEmergencyRelief',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedExcavation',
      value: 'PerformedExcavation',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedFlushing',
      value: 'PerformedFlushing',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedOtherDept',
      value: 'PerformedOtherDept',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedOwner',
      value: 'PerformedOwner',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedPermanentRepairs',
      value: 'PerformedPermanentRepairs',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedReferral',
      value: 'PerformedReferral',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'performedTemporaryRepairs',
      value: 'PerformedTemporaryRepairs',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'priority', value: 'Priority' },
    {
      name: 'problemAlarm',
      value: 'ProblemAlarm',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'problemBlockage',
      value: 'ProblemBlockage',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'problemDescription', value: 'ProblemDescription', required: true },
    {
      name: 'problemNoise',
      value: 'ProblemNoise',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'problemOdor',
      value: 'ProblemOdor',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'problemOther',
      value: 'ProblemOther',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'problemPLC',
      value: 'ProblemPLC',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'problemPowerFailure',
      value: 'ProblemPowerFailure',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'problemSubsidence',
      value: 'ProblemSubsidence',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'recCCTV',
      value: 'RecCCTV',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'recHydroClean',
      value: 'RecHydroClean',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'recOther',
      value: 'RecOther',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'recNone',
      value: 'RecNone',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'recRemarks', value: 'RecRemarks' },
    {
      name: 'recRepair',
      value: 'RecRepair',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'recRootInhib',
      value: 'RecRootInhib',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'referredBy', value: 'ReferredBy' },
    { name: 'referredTo', value: 'ReferredTo' },
    {
      name: 'reportedByAlarm',
      value: 'ReportedByAlarm',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'reportedByBusiness',
      value: 'ReportedByBusiness',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'reportedByClient',
      value: 'ReportedByClient',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'reportedByDistrict',
      value: 'ReportedByDistrict',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'reportedByHomeowner',
      value: 'ReportedByHomeowner',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'reportedByOther',
      value: 'ReportedByOther',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'reportedByTenant',
      value: 'ReportedByTenant',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'responderName', value: 'ResponderName' },
    {
      name: 'triBoolean_LOARequired',
      value: 'TriBoolean_LOARequired',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'triBoolean_Overflow',
      value: 'TriBoolean_Overflow',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'triBoolean_OverflowToH20',
      value: 'TriBoolean_OverflowToH20',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'troubleCallNum', value: 'TroubleCallNum' },
    { name: 'wastewater', value: 'Wastewater' },
    { name: 'weatherType', value: 'WeatherType' },
  ];
  // #endregion

  natureOfCallCheckboxValues = [
    {
      label: 'Odor',
      value: 'natureofCallOdor',
    },
    {
      label: 'Street Sweeping',
      value: 'natureofCallStreetSweeping',
    },
    {
      label: 'SSO',
      value: 'natureofCallSSO',
    },
    {
      label: 'Back Up',
      value: 'natureofCallBackUp',
    },
    {
      label: 'Complaint',
      value: 'natureofCallComplaint',
    },
    {
      label: 'Lift Station',
      value: 'natureofCallLiftStation',
    },
    {
      label: 'Compliment',
      value: 'natureofCallCompliment',
    },
    {
      label: 'Noise',
      value: 'natureofCallNoise',
    },
    {
      label: 'Door Hanger',
      value: 'natureofCallDoorHanger',
    },
    {
      label: 'Alarm',
      value: 'natureofCallAlarm',
    },
    {
      label: 'Stoppage',
      value: 'natureofCallStoppage',
    },
    {
      label: 'Other',
      value: 'natureofCallOther',
    },
  ];

  reportedByCheckboxValues = [
    {
      label: 'Homeowner',
      value: 'reportedByHomeowner',
    },
    {
      label: 'Tenant',
      value: 'reportedByTenant',
    },
    {
      label: 'Public Agency',
      value: 'reportedByDistrict',
    },
    {
      label: 'Alarm',
      value: 'reportedByAlarm',
    },
    {
      label: 'Client',
      value: 'reportedByClient',
    },
    {
      label: 'Business',
      value: 'reportedByBusiness',
    },
    {
      label: 'Other',
      value: 'reportedByOther',
    },
  ];

  locationCheckboxValues = [
    {
      label: 'Main',
      value: 'locationMain',
    },
    {
      label: 'Private Lateral',
      value: 'locationPrivateLateral',
    },
    {
      label: 'Nothing Found',
      value: 'locationNothingFound',
    },
    {
      label: 'Lift Station',
      value: 'locationLiftStation',
    },
    {
      label: 'Other',
      value: 'locationOther',
    },
    {
      label: 'District Lateral',
      value: 'locationDistrictLateral',
    },
  ];

  problemCheckboxValues = [
    {
      label: 'Blockage',
      value: 'problemBlockage',
    },
    {
      label: 'Alarm',
      value: 'problemAlarm',
    },
    {
      label: 'Odor',
      value: 'problemOdor',
    },
    {
      label: 'Subsidence',
      value: 'problemSubsidence',
    },
    {
      label: 'Power Failure',
      value: 'problemPowerFailure',
    },
    {
      label: 'Electrical',
      value: 'problemPLC',
    },
    {
      label: 'Noise',
      value: 'problemNoise',
    },
    {
      label: 'Other',
      value: 'problemOther',
    },
  ];

  causeCheckboxValues = [
    {
      label: 'Grease',
      value: 'causeGrease',
    },
    {
      label: 'Grit',
      value: 'causeGrit',
    },
    {
      label: 'Rags',
      value: 'causeRags',
    },
    {
      label: 'Roots',
      value: 'causeRoots',
    },
    {
      label: 'Pipe Broken',
      value: 'causePipeBroken',
    },
    {
      label: 'Pipe Sag',
      value: 'causePipeSag',
    },
    {
      label: 'Capacity',
      value: 'causeCapacity',
    },
    {
      label: 'Rocks',
      value: 'causeRocks',
    },
    {
      label: 'Vandalism',
      value: 'causeVandalism',
    },
    {
      label: 'Weather',
      value: 'causeUnknown',
    },
    {
      label: 'Power',
      value: 'causePowerFailure',
    },
    {
      label: 'Other',
      value: 'causeOther',
    },
  ];

  activityiesCheckboxValues = [
    {
      label: 'Emergency',
      value: 'performedEmergencyRelief',
    },
    {
      label: 'Temp. Repairs',
      value: 'performedTemporaryRepairs',
    },
    {
      label: 'Perm. Repairs',
      value: 'performedPermanentRepairs',
    },
    {
      label: 'Excavation',
      value: 'performedExcavation',
    },
    {
      label: 'Clean Up',
      value: 'performedCleanup',
    },
    {
      label: 'Owner Respons.',
      value: 'performedOwner',
    },
    {
      label: 'Chemicals Applied',
      value: 'performedChemicals',
    },
    {
      label: 'Hydro-Clean',
      value: 'performedFlushing',
    },
    {
      label: 'TV Inspection',
      value: 'performedCCTV',
    },
    {
      label: 'Referral',
      value: 'performedReferral',
    },
    {
      label: 'Other Agency',
      value: 'performedOtherDept',
    },
  ];

  activitiesRequestedCheckboxValues = [
    {
      label: 'CCTV',
      value: 'recCCTV',
    },
    {
      label: 'Hydro Clean',
      value: 'recHydroClean',
    },
    {
      label: 'Root Inhib.',
      value: 'recRootInhib',
    },
    {
      label: 'Repair/Rehab',
      value: 'recRepair',
    },
    {
      label: 'Other',
      value: 'recOther',
    },
    {
      label: 'None',
      value: 'recNone',
    },
  ];

  callerNotifiedByCheckboxValues = [
    {
      label: 'Phone',
      value: 'notifiedByPhone',
    },
    {
      label: 'In Person',
      value: 'notifiedInPerson',
    },
    {
      label: 'Email',
      value: 'notifiedByEmail',
    },
    {
      label: 'Letter',
      value: 'notifiedByLetter',
    },
    {
      label: 'None',
      value: 'notifiedByNone',
    },
    {
      label: 'Other',
      value: 'notifiedByOther',
    },
  ];

  weatherConditions = ['Dry', 'Light Rain', 'Heavy Rain'];

  get isNoneSelected(): boolean {
    return this.workOrderData.recNone === true;
  }

  get isFollowUpSelected(): boolean {
    return (
      this.workOrderData.recCCTV === true
      || this.workOrderData.recHydroClean === true
      || this.workOrderData.recRootInhib === true
      || this.workOrderData.recRepair === true
      || this.workOrderData.recOther === true
    );
  }

  canSetToInFollowUpRequired(): boolean {
    if (this.isNoneSelected) {
      return false;
    }
    return this.isFollowUpSelected;
  }

  // #region functions for creating Service Call Report
  canCreate(): boolean {
    // If trying to set to complete, then require all fields
    if (this.isStatusSetToComplete && this.shouldDisableSubmitButton()) {
      return false;
    }
    const requiredFields = this.workOrderFields
      .filter((field) => field.required === true)
      .every((field) => this.workOrderData[field.name]);
    const isAtLeastOneNatureOfCallSelected = this.natureOfCallCheckboxValues.some(
      (checkbox) => this.workOrderData[checkbox.value],
    );
    return requiredFields && isAtLeastOneNatureOfCallSelected;
  }
  // #endregion

  canSetToComplete(): boolean {
    return super.canSetToComplete() && !this.shouldDisableSubmitButton();
  }

  /**
   * Disable the submit button if all semi required fields are not filled in
   */
  shouldDisableSubmitButton(): boolean {
    return (
      !this.workOrderData.responderName
      || !this.workOrderData.arrivalDate
      || !this.workOrderData.arrivalTime
    );
  }
}
