




























import {
  Component,
} from 'vue-property-decorator';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportDateHeaderInput from '../ReportHelpers/ReportInputHelpers/ReportDateHeaderInput.vue';
import ReportHeaderMixin, { WorkOrderFieldType } from '../ReportHelpers/ReportHeaderMixin.vue';

@Component({
  components: {
    ReportTextInput,
    ReportDropDownInput,
    ReportCalendarInput,
    ReportAssignedToDropDownInput,
    ReportDateHeaderInput,
  },
})
export default class SLRatReportHeader extends ReportHeaderMixin {
  workOrderData: any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users' },
    { name: 'frequency', value: 'Frequency' },
    {
      name: 'dateIssued',
      value: 'DateIssued',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'completeBy', value: 'CompleteBy', type: WorkOrderFieldType.DATE,
    },
    {
      name: 'dateCompleted',
      value: 'DateCompleted',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'status',
      value: 'Status',
    },
  ];
}
