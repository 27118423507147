import { MutationTree } from 'vuex';
import { ReportState } from './types';

// eslint-disable-next-line no-shadow
export enum ReportMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_PDF_URI = 'SET_PDF_URI',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
  SET_EDIT_STATE = 'SET_EDIT_STATE',
  SET_EXPORTING_STATE = 'SET_EXPORTING_STATE',
  SET_REPORT_ERRORS='SET_REPORT_ERRORS'
}

export const mutations: MutationTree<ReportState> = {
  [ReportMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },
  [ReportMutations.SET_PDF_URI](state, payload: string) {
    state.data = payload;
  },
  [ReportMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },
  [ReportMutations.SET_EDIT_STATE](state, payload: boolean) {
    state.isEditMode = payload;
  },
  [ReportMutations.SET_EXPORTING_STATE](state, payload: boolean) {
    state.isExporting = payload;
  },
  [ReportMutations.SET_REPORT_ERRORS](state, payload: any[]) {
    state.reportErrors = payload;
  },
};
