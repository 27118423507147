import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { UserState } from './types';
import getters from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state : UserState = {
  loading: true,
  loadError: undefined,
  allUserData: undefined,
  allDetailedUserData: undefined,
  selectedUserData: undefined,
  selectedUserDataLoading: undefined,
  inactiveDetailedUserData: undefined,
  response: undefined,
  userManagements: undefined,
};

const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default user;
