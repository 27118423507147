
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AdditionalItemsMenu extends Vue {
  @Prop() items: (string | number)[];

  @Prop({ default: 3 }) numberOfItemsToPreview: number;

  @Prop({ default: false, type: Boolean }) isNumberArray: boolean;

  get sortedItems(): string[] {
    if (!this.items) {
      return [];
    }
    if (this.isNumberArray) {
      this.items.sort((a, b) => (a as number) - (b as number));
    } else {
      this.items.sort();
    }
    return this.items.map((item) => item as string);
  }

  get listItems(): string[] {
    return this.sortedItems.slice(this.numberOfItemsToPreview);
  }

  displayItems(): string {
    return this.sortedItems.slice(0, this.numberOfItemsToPreview).join(' , ');
  }
}
