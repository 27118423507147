
























































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Prop, PropSync } from 'vue-property-decorator';
import { UserPermission } from '@/store/userpermissions/types';
import { getRoleGuid } from '@/auth/roles';
import AdminPanelMixin from '../AdminPanelMixin.vue';

@Component({})
export default class AddProjectButton extends AdminPanelMixin {
  @PropSync('selectedUser') readonly synchedSelectedUser;

  @Prop() readonly currentUserRole: string;

  addProjectDialog = false as boolean;

  startDateMenu = false as boolean;

  endDateMenu = false as boolean;

  startDate = null;

  endDate = null;

  saveEditUserValid = false as boolean;

  selectedProjectNames = [];

  mounted(): void {
    if (!this.organizationProjects) {
      this.fetchOrganizationProjectsData().catch(() => {
        this.$router.push({
          name: 'Error',
          params: {
            catchAll: 'Error',
            message:
              'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.',
          },
        });
      });
    }
  }

  saveEditUser(): void {
    this.selectedProjectNames.forEach((projectName) => {
      const selectedProject = this.organizationProjects.find((p) => p.name === projectName);

      this.synchedSelectedUser.detailedUserData.role.push({
        projectGuid: selectedProject.guid,
        projectName: selectedProject.name,
        projectNumber: selectedProject.projectNumber,
        customerName: selectedProject.customerGuid !== null
          ? this.customerDataList.find(
            (customer) => customer.guid === selectedProject.customerGuid,
          ).name
          : '',
        roleGuid: getRoleGuid(this.currentUserRole),
        startDate: this.startDate,
        endDate: this.endDate,
      });
    });

    this.selectedProjectNames = [];
    this.startDate = null;
    this.endDate = null;

    this.addProjectDialog = false;
    this.$forceUpdate();
  }

  get filteredProjects(): string[] {
    if (!this.organizationProjects) {
      return [];
    }
    const isRedzoneOrgMember = this.isUsersRedzoneOrganizationMember(
      [this.synchedSelectedUser.detailedUserData],
    );
    let projects = this.organizationProjects
      .filter(
        (proj) => !this.synchedSelectedUser.detailedUserData.role.find(
          (userProject) => userProject.projectGuid === proj.guid,
        ),
      );
    if (!isRedzoneOrgMember) {
      projects = projects
        .filter((proj) => this.synchedSelectedUser.detailedUserData.customerGuids.find(
          (customerGuid) => customerGuid === proj.customerGuid,
        ));
    }
    return projects.map((project) => project.name).sort();
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_DELETEUSER
   */
  get hasPermissionAdminDeleteUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_DELETEUSER);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_EDIT_PROJECT_ACCESS_DATES
   */
  get hasPermissionAdminSetDateRangeForActivation(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_EDIT_PROJECT_ACCESS_DATES);
  }
}
