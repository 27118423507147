

































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Prop, PropSync } from 'vue-property-decorator';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { getRoleString } from '@/auth/roles';
import { DetailedUserData, ManagedUsersTableData } from '@/store/users/types';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';

@Component({ components: { IntegrityTable, IntegrityDelete }, methods: { getRoleString } })
export default class EditUsersManagedUsers extends AdminPanelMixin {
  @Prop({ default: false }) readonly managedUsersLoading: boolean;

  @PropSync('selectedUser') synchedSelectedUser: EditUserData;

  @PropSync('managedUsers') syncedManagedUsers: ManagedUsersTableData[];

  @PropSync('managedUsersForRemoval') syncedManagedUsersForRemoval: ManagedUsersTableData[];

  search = '';

  headers = [
    {
      text: 'User',
      value: 'user',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Role',
      value: 'role',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Organization',
      value: 'organization',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Customer(s)',
      value: 'customers',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
      filterable: false,
    },
  ];

  matchFilters = [
    {
      header: 'user',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'email',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'role',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'organization',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'customers',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  /**
   * @description fill out the filter values for the table.
   * @returns filter values
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get filterValues(): any {
    if (!this.syncedManagedUsers) {
      return;
    }

    const localFilterValues = {};

    this.headers.forEach((header) => {
      if (!header.filterable) return;

      localFilterValues[header.value] = [];

      this.syncedManagedUsers.forEach((user: any) => {
        let checkVal: string;

        try {
          checkVal = user[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(localFilterValues[header.value])
          && !localFilterValues[header.value].includes(checkVal)
        ) {
          localFilterValues[header.value].push(checkVal);
        }
      });
      localFilterValues[header.value].sort();
    });
    // eslint-disable-next-line consistent-return
    return localFilterValues;
  }

  get tableData() {
    return this.syncedManagedUsers;
  }

  getDeleteText(user: DetailedUserData): string {
    const subjectUserName = this.syncedManagedUsers.find((u) => u.guid === user.guid)?.user;
    const managerUser = this.synchedSelectedUser.detailedUserData;
    const managerUserName = `${managerUser.firstname} ${managerUser.lastname}`;

    return `Are you sure you want to remove ${subjectUserName} as a managed user of ${managerUserName}?`;
  }

  async removeManagedUser(managedUser: ManagedUsersTableData): Promise<boolean> {
    try {
      const deleteIndex = this.syncedManagedUsers
        .findIndex((mu) => mu.userManagementGuid === managedUser.userManagementGuid);

      if (deleteIndex === -1) {
        // If we can not find the item in the syncedManagedUsers
        return false;
      }

      this.syncedManagedUsersForRemoval.push(managedUser);
      this.syncedManagedUsers.splice(deleteIndex, 1);
    } catch (e) {
      return false;
    }

    return true;
  }
}
