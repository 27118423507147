


































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, VModel } from 'vue-property-decorator';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { namespace } from 'vuex-class';
import ReportDropDownInput from './ReportDropDownInput.vue';
import ReportCalendarInput from './ReportCalendarInput.vue';

const reportModule = namespace('report');

@Component({
  components: { ReportDropDownInput, ReportCalendarInput },
})
export default class ReportDateHeaderInput extends UserPermissionsMixin {
  @VModel() modelValue!: any | undefined;

  @Prop({ default: false, type: Boolean }) disableDateCompleted: boolean;

  @reportModule.Getter('completeErrorMessage') completeErrorMessage: string;
}
