





















































































































































































































/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { loadModules } from 'esri-loader';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';
import { GeoJsonJobObject } from '@/store/project/types';
import { ProjectMutations } from '@/store/project/mutations';
import { RoutingActions } from '@/store/routing/actions';
import { RoutingDTONode } from '@/store/routing/types';
import { UserPermission } from '@/store/userpermissions/types';
import { UserPrefsActions } from '../../store/userPrefs/actions';
import RouteOptions from '../RouteOptions/RouteOptions.vue';
import { AssetActions } from '../../store/asset/actions';
import {
  AssetRef,
  AssetData,
  AssetFilter,
  Location,
} from '../../types';
import {
  AssetType, Defect, MapPage, Point,
} from './types';
import util from './utils';
import AssetPopout from '../AssetPopout/AssetPopout.vue';
import Legend from '../Legend/Legend.vue';
import MapOptions from '../MapOptions/MapOptions.vue';
import arcgisToGeoJSON from '../../services/terraformer';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const userPrefsModule = namespace('userPrefs');
const assetModule = namespace('asset');
const projectModule = namespace('project');
const routingModule = namespace('routing');

@Component({
  components: {
    AssetPopout, Legend, MapOptions, RouteOptions,
  },
})
export default class MapGIS extends UserPermissionsMixin {
  timeRequestMade = 0;

  firstSyncFlag = false;

  zoomToCityFlag = false;

  ToggleLayerSwitch = '';

  clearBool = false;

  mapLoaded = false as boolean;

  mapLoadFinished = false as boolean;

  preventNewLines = false as boolean;

  latitude = 39 as number;

  longitude = -100 as number;

  showPrint = false as boolean;

  searchWidget;

  searchWidgetLoaded = false;

  highlighted;

  manholeLayerHighlight;

  lineSegmentLayerHighlight;

  parcelsLayerHighlight;

  lateralLayerHighlight;

  measurementList: Array<number> = [];

  measurementUnit = '';

  infoOptions = [
    {
      name: 'Manholes',
      exists: true,
      active: true,
    },
    {
      name: 'Line Segments',
      exists: true,
      active: true,
      contents: [
        {
          name: 'lineSegmentFlowDirection',
          desc: 'Show Flow Direction',
          active: false,
        },
      ],
    },
    {
      name: 'Parcels',
      exists: true,
      active: false,
    },
    {
      name: 'GIS Inspections',
      exists: true,
      active: false,
    },
    {
      name: 'Severe Defects',
      exists: false,
      active: false,
      contents: [
        {
          name: 'defectCode1',
          desc: 'Score 1',
          exists: false,
          active: false,
        },
        {
          name: 'defectCode2',
          desc: 'Score 2',
          exists: false,
          active: false,
        },
        {
          name: 'defectCode3',
          desc: 'Score 3',
          exists: false,
          active: true,
        },
        {
          name: 'defectCode4',
          desc: 'Score 4',
          exists: false,
          active: true,
        },
        {
          name: 'defectCode5',
          desc: 'Score 5',
          exists: false,
          active: true,
        },
      ],
    },
    {
      name: 'Laterals',
      exists: false,
      active: false,
      contents: [
        {
          name: 'lateralFlowDirection',
          desc: 'Show Flow Direction',
          active: false,
        },
      ],
    },
    { name: 'Map Pages', exists: false, active: false },
  ];

  measurementList: Array<number> = [];

  activeTool = '' as string;

  Graphic = null;

  FeatureLayer = null;

  areFeaturesLoaded = false;

  lastActiveSourceIndex = -1;

  mapPagesFeaturesLayer = null;

  isOptionsActive = false;

  isRouteActive = false;

  hasLoadedAssets = false;

  lateralFeaturesLayer = null;

  lineSegmentFeaturesLayer = null;

  assetFeaturesLayer = null;

  manholeInspectionsLayer = null;

  lineSegmentInspectionsLayer = null;

  parcelsLayer = null;

  filterMap = false;

  selectedBasemap = 'topo-vector'

  selectedFilterLayer = 'score';

  arcgisToGeoJSON = arcgisToGeoJSON;

  showLayersExport = false;

  layersExportEmail = this.$auth?.user.email;

  layersExportType = 'shapeFile';

  layersExportTypeOptions = [
    { name: 'GeoDB', value: 'geoDb' },
    { name: 'ShapeFile', value: 'shapeFile' },
  ]

  layersExportSelection = [
    'ManholeCustomerData',
    'LineSegmentsCustomerData',
    'Laterals',
    'ManholeInspectionData',
    'LineSegmentInspectionData',
    'ParcelsData',
  ]

  layersExportOptions = [
    { name: 'Manhole Customer Data', value: 'ManholeCustomerData' },
    { name: 'Line Segments Customer Data', value: 'LineSegmentsCustomerData' },
    { name: 'Laterals', value: 'Laterals' },
    { name: 'Manhole Inspection Data', value: 'ManholeInspectionData' },
    { name: 'Line Segments Inspection Data', value: 'LineSegmentInspectionData' },
    { name: 'Parcels Data', value: 'ParcelsData' },
  ]

  showSnackbar = false;

  snackbarMessage = '';

  snackbarColor = '';

  snackbarTimeout = 5000;

  chromeGLInterval = null;

  chromeGLCheckIteration = 0;

  chromeGLCheckMaxIteration = 10;

  showedChromeGLError = false;

  selectedAssets = [] as AssetRef[];

  selectedAssetGraphics = [];

  activeSelectionMode = { icon: 'mdi-cursor-default-outline', mode: 'pointer' }

  selectionModeOptions = [
    { name: 'Pointer', icon: 'mdi-cursor-default-outline', mode: 'pointer' },
    { name: 'Rectangle', icon: 'mdi-select', mode: 'rectangle' },
    { name: 'Polygon', icon: 'mdi-vector-polygon', mode: 'polygon' },
  ]

  options = ['Manhole', 'Line Segment', 'Lateral'];

  routeAssetType = '';

  routeOptionsColor = '#FFFFFF'

  routeOptionsDescription = ''

  routeOptionsName = ''

  routeOptionsGuid = ''

  @Prop() readonly assetCount: number;

  @Prop() readonly mapData!: AssetData[];

  @Prop() readonly cities: string[] | undefined;

  @Prop() readonly states: string[] | undefined;

  @Prop({ default: true }) readonly popupEnabled: boolean;

  @Prop({ default: true }) readonly printEnabled: boolean;

  @Prop({ default: true }) readonly searchEnabled: boolean;

  @Prop({ default: true }) readonly popoutEnabled: boolean;

  @Prop({ default: true }) readonly measurementEnabled: boolean;

  @Prop({ default: false }) readonly loadMapOverride: boolean;

  @Prop({ default: false }) readonly assetZoomOveride: boolean;

  @Prop() readonly mapPages: MapPage[] | undefined;

  @Prop() readonly selectedAsset: AssetRef | undefined;

  // only use this for tests!
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Prop({ default: () => {} }) backUpAsset: AssetRef;

  @Prop() readonly defects: Defect[] | undefined;

  @PropSync('syncedAssetGuidList') readonly assetGuidList: string[] | undefined;

  // TODO: figure out if we still need this
  // a horrible bool that is only useful for tests because prop syncs don't like tests
  closeState = true;

  @PropSync('syncedUseZoomFilter') useZoomFilter: boolean | undefined;

  @PropSync('projectFilters') filters: AssetFilter;

  @assetModule.Action(AssetActions.SET_ASSET_SCREENSHOT_URL) setAssetScreenshotUrl;

  @projectModule.Action(ProjectActions.POST_GEOJSON) postGeoJson;

  @projectModule.State('headers') projectHeaders;

  @projectModule.State('mapNeedsUpdated') mapNeedsUpdated;

  @projectModule.Mutation(ProjectMutations.SET_MAP_NEEDS_UPDATED) setMapNeedsUpdated;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @userPrefsModule.Action(UserPrefsActions.SET_SELECTED_MAP_ASSET) setSelectedMapAsset;

  @userPrefsModule.State('selectedMapAssetGuid') selectedMapAssetGuid;

  @routingModule.State('routeAssets') routingAssets: RoutingDTONode[];

  @routingModule.Action(RoutingActions.FETCH_ROUTING_DATA) fetchRoutingData;

  get selectedAssetGuid(): string {
    if (this.selectedAsset != null) {
      this.closeState = true;
      return this.selectedAsset.guid;
    }
    if (this.backUpAsset != null) {
      return this.backUpAsset.guid;
    }
    return undefined;
  }

  get routeAssets(): AssetRef {
    return this.selectedAssets.filter((asset) => asset.type === this.routeAssetType);
  }

  async mounted(): Promise<void> {
    if (!this.hasPermissionGISCommon) {
      this.$router.push({
        name: 'Error',
        params: { catchAll: 'Error', message: 'You do not have permission to view this page. If this an error, please contact support.' },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      });
    }
    await this.loadMap();
    // begin filter interval
    setInterval(this.checkFilterTrigger, 2000);
    this.chromeGLInterval = setInterval(this.checkChromeGLError, 2000);
    if (this.hasPermissionGISRouteCreation) {
      this.fetchRoutingData(this.$attrs.id);
    }
  }

  @Watch('mapData', { deep: true })
  async onMapDataChange(): void {
    // updates searchable features
    if (this.FeatureLayer == null || this.Graphic == null) {
      this.areFeaturesLoaded = true;
    }

    if (
      this.mapLoadFinished
      && !this.hasLoadedAssets
      && this.mapData.length > 0
      && !this.firstSyncFlag
      && this.mapNeedsUpdated
    ) {
      this.firstSyncFlag = true;
      this.setMapNeedsUpdated(false);
      await this.lateralFeaturesLayer;
      await this.lineSegmentFeaturesLayer;
      await this.assetFeaturesLayer;
      await this.manholeInspectionsLayer;
      await this.lineSegmentInspectionsLayer;
      await this.parcelsLayer;

      await this.dataSetupManholesLayer();
      await this.dataSetupLineSegmentsLayer();
      await this.dataSetupLateralFeaturesLayer();
      await this.dataSetupManholeInspectionsLayer();
      await this.dataSetupLineSegmentInspectionsLayer();
      await this.dataSetupParcelsLayer();

      await this.map.add(this.lateralFeaturesLayer);
      await this.map.add(this.lineSegmentFeaturesLayer);
      await this.map.add(this.lineSegmentInspectionsLayer);
      await this.map.add(this.assetFeaturesLayer);
      await this.map.add(this.manholeInspectionsLayer);
      await this.map.add(this.parcelsLayer);
      this.loadViews();

      this.initialMapTableLoad();
      this.hasLoadedAssets = true;
    } else if (
      this.mapLoadFinished
      && this.hasLoadedAssets
      && this.mapData.length > 0
      && this.mapNeedsUpdated
    ) {
      this.setMapNeedsUpdated(false);
      this.updateGraphics();
    }

    this.updateSearchableFeatures(this.getSearchableGraphics());

    if (this.loadMapOverride) {
      this.updateGraphics();
    }
  }

  @Watch('assetCount')
  async onAssetCountChange(): void {
    if (
      this.mapLoadFinished
      && this.hasLoadedAssets
    ) {
      await this.deleteAssets(this.assetFeaturesLayer);
      await this.dataSetupManholesLayer();
      await this.dataSetupLineSegmentsLayer();
      await this.dataSetupParcelsLayer();
      this.updateGraphics();
    }
  }

  @Watch('mapPages')
  onMapPagesChange(): void {
    if (this.mapLoadFinished && this.mapPages && this.mapPages.length > 0) {
      this.mapPageLoad();
    }
  }

  @Watch('mapLoadFinished')
  async onMapLoadedTryMapPages(): Promise<void> {
    if (this.mapPages && this.mapPages.length > 0) {
      this.mapPageLoad();
    }
    if (
      this.mapData != null
      && this.mapData.length > 0
      && !this.hasLoadedAssets
    ) {
      await this.lateralFeaturesLayer;
      await this.lineSegmentFeaturesLayer;
      await this.assetFeaturesLayer;
      await this.manholeInspectionsLayer;
      await this.lineSegmentInspectionsLayer;
      await this.parcelsLayer;

      await this.dataSetupManholesLayer();
      await this.dataSetupLineSegmentsLayer();
      await this.dataSetupLateralFeaturesLayer();
      await this.dataSetupManholeInspectionsLayer();
      await this.dataSetupLineSegmentInspectionsLayer();
      await this.dataSetupParcelsLayer();

      await this.map.add(this.lateralFeaturesLayer);
      await this.map.add(this.lineSegmentFeaturesLayer);
      await this.map.add(this.assetFeaturesLayer);
      await this.map.add(this.manholeInspectionsLayer);
      await this.map.add(this.lineSegmentInspectionsLayer);
      await this.map.add(this.parcelsLayer);

      this.loadViews();
      this.initialMapTableLoad();
      this.hasLoadedAssets = true;
    }
  }

  @Watch('selectedAsset')
  onSelectedAssetChange(): void {
    if (this.selectedAsset) {
      if (this.highlighted) this.highlighted.remove();

      this.zoomToAsset(this.selectedAsset);
    }
    this.setSelectedMapAsset(this.selectedAsset);
  }

  @Watch('activeTool')
  onActiveToolChange(): void {
    const selectBtn = document.getElementsByClassName(
      'selection-button',
    )[0] as HTMLElement;
    const measurementBtn = document.getElementsByClassName(
      'measurement-button',
    )[0] as HTMLElement;
    const printBtn = document.getElementsByClassName(
      'print-button',
    )[0] as HTMLElement;
    switch (this.activeTool) {
      case 'select':
        // set btn bground to remain indented until the selection is complete
        selectBtn.style.setProperty('background-color', '#d7d7d7', 'important');
        // reset
        printBtn.style.setProperty('background-color', 'white', 'important');
        measurementBtn.style.setProperty(
          'background-color',
          'white',
          'important',
        );
        break;
      case 'measurement':
        // change measurement color
        measurementBtn.style.setProperty(
          'background-color',
          '#d7d7d7',
          'important',
        );
        // reset
        printBtn.style.setProperty('background-color', 'white', 'important');
        selectBtn.style.setProperty('background-color', 'white', 'important');
        break;
      case 'print':
        // change measurement color
        printBtn.style.setProperty('background-color', '#d7d7d7', 'important');
        // reset
        measurementBtn.style.setProperty(
          'background-color',
          'white',
          'important',
        );
        selectBtn.style.setProperty('background-color', 'white', 'important');
        break;
      case '':
        // change measurement color
        printBtn.style.setProperty('background-color', 'white', 'important');
        measurementBtn.style.setProperty(
          'background-color',
          'white',
          'important',
        );
        selectBtn.style.setProperty('background-color', 'white', 'important');
        break;
      default:
        console.log('TOOL NOT FOUND');
    }
  }

  @Watch('useZoomFilter')
  async onUseZoomFilterChange(): void {
    if (!this.useZoomFilter) {
      if (window.Worker) {
        const query = {
          outFields: ['*'],
          returnGeometry: true,
          spatialRelationship: 'intersects',
        };
        this.filterMapViewFromQuery(query);
      } else {
        this.synchedSelectedMapFeatures = this.synchedSelectedFeatures;
      }
    } else {
      this.filterMapView();
    }
  }

  @Watch('selectedBasemap')
  onSelectedBasemapChange(): void {
    this.map.basemap = this.selectedBasemap;
  }

  @Watch('selectedFilterLayer')
  onSelectedFilterLayerChange(): void {
    this.$refs.legend.setLegend(this.selectedFilterLayer);
    this.$emit('setFilterLayer', this.selectedFilterLayer);
    this.assetFeaturesLayer.renderer = this.getManholeRenderer();
    this.lineSegmentFeaturesLayer.renderer = this.getLineSegmentRenderer();
  }

  @Watch('routingAssets')
  onRoutingAssets(): void {
    this.selectedAssets = [];

    this.routingAssets.forEach((routeAsset) => {
      this.selectedAssets.push(this.mapData.find((md) => md.guid === routeAsset.guid));
    });

    this.selectFeaturesByGuids(this.routingAssets.map((route) => route.guid));
  }

  @Watch('filters.collectionStatus')
  onFilerCollectionChange(): void {
    // TODO: use this if we want to auto-switch back to score
    // layer if no collection filters are selected
    if (this.filters.collectionStatus.length === 0) {
      this.selectedFilterLayer = 'score';
      return;
    }
    if (this.filters.collectionStatus.includes('Incomplete')) {
      this.selectedFilterLayer = 'collection';
    }
  }

  /**
   * @returns true if the user has the permission GIS_COMMON
   */
  get hasPermissionGISCommon(): boolean {
    return this.hasPermission(UserPermission.GIS_COMMON);
  }

  /**
   * @returns true if the user has the permission GIS_PDF_MAP_EXPORT
   */
  get hasPermissionGISPDFMapExport(): boolean {
    return this.hasPermission(UserPermission.GIS_PDF_MAP_EXPORT);
  }

  /**
   * @returns true if the user has the permission GIS_ROUTE_CREATION
   */
  get hasPermissionGISRouteCreation(): boolean {
    return this.hasPermission(UserPermission.GIS_ROUTE_CREATION);
  }

  /**
   * Instatiate GIS map showing manhole locations
   */
  loadMap(): Promise {
    loadModules(
      [
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/layers/GraphicsLayer',
        'esri/widgets/DistanceMeasurement2D',
        'esri/widgets/Sketch/SketchViewModel',
        'esri/widgets/Print',
        'esri/widgets/Search',
        'esri/geometry/geometryEngineAsync',
        'esri/tasks/Locator',
        'esri/rest/locator',
        'esri/geometry/geometryEngine',
        'esri/symbols/SimpleMarkerSymbol',
        'esri/core/watchUtils',
        'esri/geometry/support/webMercatorUtils',
        'esri/config',
        'esri/geometry/coordinateFormatter',
      ],
      { css: true },
    )
      .then(
        ([
          ArcGISMap,
          MapView,
          Graphic,
          FeatureLayer,
          GraphicsLayer,
          DistanceMeasurement2D,
          SketchViewModel,
          Print,
          Search,
          geometryEngineAsync,
          Locator,
          locator,
          geometryEngine,
          SimpleMarkerSymbol,
          WatchUtils,
          WebMercatorUtils,
          esriConfig,
          coordinateFormatter,
        ]) => {
          this.Graphic = Graphic;
          this.FeatureLayer = FeatureLayer;
          this.Locator = Locator;
          this.locatorService = locator;
          this.geometryEngine = geometryEngine;

          this.WatchUtils = WatchUtils;
          this.WebMercatorUtils = WebMercatorUtils;

          this.esriConfig = esriConfig;
          this.esriConfig.apiKey = this.$esriApiKey;

          this.coordinateFormatter = coordinateFormatter;

          this.esriConfig.request.interceptors.push({
            urls: this.FeatureLayer.url,
            before(params) {
              if (params.url.includes('findAddressCandidates')) {
                // Match Degrees and decimal minutes (DMM) format
                // in order to convert to Decimal degrees (DD)
                const regex = /^(?:-?\d{1,3} \d{0,2}(?:\.\d*)?,?[ ]*){2}$/;
                if (params.requestOptions.query.SingleLine.match(regex)) {
                  const search = params.requestOptions.query.SingleLine;
                  const coordinateArray = search.split(' ').map((p) => parseFloat(p)).filter((p) => p);
                  const x = coordinateArray[0]
                  + (Math.sign(coordinateArray[0]) * coordinateArray[1]) / 60;
                  const y = coordinateArray[2]
                  + (Math.sign(coordinateArray[2]) * coordinateArray[3]) / 60;
                  params.requestOptions.query.SingleLine = `${x}, ${y}`;
                }
              }
            },
          });

          this.SimpleMarkerSymbol = SimpleMarkerSymbol;
          // Create map
          this.map = new ArcGISMap({ basemap: 'topo-vector' });

          // Create map view
          this.view = new MapView({
            container: document.getElementsByClassName('map-container')[0],
            map: this.map,
          });

          // Create measurement tool
          if (this.measurementEnabled) {
            this.measurement = new DistanceMeasurement2D({
              view: this.view,
              unit: this.displayImperial ? 'imperial' : 'metric',
            });
            this.measurement.viewModel.palette.handleColor = [0, 128, 255, 255];
            this.measurement.viewModel.palette.pathPrimaryColor = [
              0, 128, 255, 255,
            ];
            this.measurement.viewModel.palette.pathSecondaryColor = [
              0, 128, 255, 255,
            ];
            this.view.ui.add('topbar', 'top-right');
          } else {
            // when in asset map move widgets
            this.view.ui.padding = {
              top: 55,
              left: 10,
              right: 10,
              bottom: 20,
            };
          }

          // Create Print button
          if (this.printEnabled) {
            this.print = new Print({
              view: this.view,
            });

            this.print.templateOptions = {
              title: 'Map Printout',
              legendEnabled: false,
            };
          }

          // Creating layer and widget for drawing grapihcs
          this.polygonGraphicsLayer = new GraphicsLayer();
          this.map.add(this.polygonGraphicsLayer);

          this.sketchViewModel = new SketchViewModel({
            layer: this.polygonGraphicsLayer,
            view: this.view,
            defaultUpdateOptions: {
              tool: 'none',
              enableRotation: false,
              enableScaling: false,
              enableZ: false,
            },
          });

          // Once user is done drawing a rectangle on the map
          // use the rectangle to select features on the map and table
          this.sketchViewModel.on('create', async (event) => {
            if (event.state === 'complete') {
              // clear list before checking for matching assets
              this.clearSelection(false, false);
              // this polygon will be used to query features that intersect it
              const geometries = this.polygonGraphicsLayer.graphics.map(
                (graphic) => graphic.geometry,
              );
              this.queryGeometry = await geometryEngineAsync.union(
                geometries.toArray(),
              );
              // reset active tool
              if (this.activeTool === 'select') this.activeTool = '';
              this.selectFeatures(this.queryGeometry);
            }
          });

          this.view.ui.add('topbar', 'top-right');

          // Added to position layer button
          this.view.ui.add('bottombar', 'bottom-right');

          this.view.ui.add('legend', 'bottom-left');

          this.view.ui.move(this.view.ui.components[1], 'top-right');

          // Add click event handling
          this.setupViewClickHandling();

          // Set levels to activate clustering and thinning
          this.manholeClusterZoomLevel = 50000;
          this.lineSegementThinningZoomLevel = 40000;
          this.parcelThinningZoomLevel = 40000;
          this.parcelTextThinningZoomLevel = 5000;
          this.lateralsThinningZoomLevel = 10000;

          // Setup feature layers
          this.assetFeaturesLayer = this.structSetupManholesLayer();

          this.lineSegmentFeaturesLayer = this.structSetupLineSegmentsLayer();

          this.lateralFeaturesLayer = this.structSetupLateralFeaturesLayer();

          this.manholeInspectionsLayer = this.structSetupManholeInspectionsLayer();

          this.lineSegmentInspectionsLayer = this.structSetupLineSegmentInspectionsLayer();

          this.parcelsLayer = this.structSetupParcelsLayer();
          if (!this.infoOptions.find((o) => o.name === 'Parcels').active) {
            this.parcelsLayer.visible = false;
          }

          this.mapPagesFeaturesLayer = this.setupMapPagesFeatureLayer();

          this.assetFeatureReduction = {
            type: 'cluster',
            clusterMinSize: 20,
            labelingInfo: [
              {
                deconflictionStrategy: 'static',
                symbol: {
                  type: 'text',
                  color: 'black',
                  haloColor: 'white',
                  haloSize: '2px',
                  font: {
                    family: 'Arial',
                    size: 10,
                    weight: 'bold',
                  },
                },
                labelPlacement: 'center-center',
                labelExpressionInfo: {
                  expression: "Text($feature.cluster_count, '#,###')",
                },
              },
            ],
          };

          this.view.watch('scale', (scale) => {
            this.assetFeaturesLayer.featureReduction = scale > this.manholeClusterZoomLevel
              ? this.assetFeatureReduction
              : null;

            this.manholeInspectionsLayer.featureReduction = scale > this.manholeClusterZoomLevel
              ? this.assetFeatureReduction
              : null;
          });

          if (this.searchEnabled) {
            this.searchWidget = new Search({
              view: this.view,
              allPlaceholder: 'Search by Location or Asset ID',
              searchAllEnabled: true,
              locationEnabled: true,
              includeDefaultSources: false,
              popupEnabled: false,
              resultGraphicEnabled: true,
              maxSuggestions: 10000,
            });
            // this will set searchWidgets sources
            this.updateSearchableFeatures(this.getSearchableGraphics());
            this.lastActiveSourceIndex = this.searchWidget.activeSourceIndex;
            this.view.ui.add(this.searchWidget, {
              position: 'top-left',
              index: 9,
            });
            this.searchWidget.allSources.on('after-add', ({ item }) => {
              if (item.displayFields && item.displayFields[0] === 'assetName') {
                item.resultSymbol = new this.SimpleMarkerSymbol({
                  size: 0,
                });
              } else {
                item.resultSymbol = new this.SimpleMarkerSymbol({
                  style: 'diamond',
                  outline: { color: [255, 255, 255, 1] },
                  color: [255, 0, 0, 1],
                  size: 20,
                });
              }
            });
            // result is picked by user
            this.searchWidget.on('select-result', async (event) => {
              const thisAsset = this.mapData.find(
                (asset) => asset.name === event.result.name,
              );
              // null check
              if (thisAsset == null) return;
              await this.ensureDupes();
              if (
                thisAsset.type === 'Manhole'
                && this.assetFeaturesDupe != null
              ) {
                await this.deleteAndReassertAssets(this.assetFeaturesLayer, [
                  this.assetFeaturesDupe.find(
                    (f) => thisAsset.guid === f.attributes.guid,
                  ),
                ]);
              }
              if (
                thisAsset.type === 'Line Segment'
                && this.lineFeaturesDupe != null
              ) {
                await this.deleteAndReassertAssets(
                  this.lineSegmentFeaturesLayer,
                  [
                    this.lineFeaturesDupe.find(
                      (f) => thisAsset.guid === f.attributes.guid,
                    ),
                  ],
                );
              }
              if (
                thisAsset.type === AssetType.PARCEL
                && this.parcelFeaturesDupe != null
              ) {
                await this.deleteAndReassertAssets(
                  this.parcelsLayer,
                  [
                    this.parcelFeaturesDupe.find(
                      (f) => thisAsset.guid === f.attributes.guid,
                    ),
                  ],
                );
              }
              this.$emit('update-selected-asset', { selectedAssets: [thisAsset], multiSelect: false });
              this.onInputChange();
            });
            this.searchWidget.on('search-complete', async (event) => {
              if (event.numResults > 0) {
                this.clearBool = true;
              }
            });
            this.searchWidget.on('search-clear', async () => {
              await this.ensureDupes();
              if (this.clearBool === true) {
                if (this.assetFeaturesLayer) {
                  await this.refilterScore(
                    this.assetFeaturesLayer,
                    this.assetFeaturesDupe,
                  );
                }
                if (this.lineSegmentFeaturesLayer) {
                  await this.refilterScore(
                    this.lineSegmentFeaturesLayer,
                    this.lineFeaturesDupe,
                  );
                }
                if (this.parcelsLayer) {
                  await this.refilterScore(
                    this.parcelsLayer,
                    this.parcelFeaturesDupe,
                  );
                }

                this.selectAll(false);
                this.clearBool = false;
              }
            });
          }
          this.view.on('double-click', (evt: unknown) => {
            this.filterMapView();

            // The event object contains the mapPoint and the screen coordinates of the location
            // that was clicked.
            if (!this.preventNewLines && this.activeTool === 'measurement') {
              this.createPointGraphic(
                evt.mapPoint.latitude,
                evt.mapPoint.longitude,
              );
            }
            if (
              this.measurementList.length > 0
              && this.preventNewLines === false
            ) {
              this.preventNewLines = true;
            }
            const newMeasureBtn: HTMLButtonElement = document.getElementsByClassName(
              'esri-distance-measurement-2d__clear-button',
            )[0];
            if (newMeasureBtn != null && this.preventNewLines) {
              newMeasureBtn.addEventListener('click', () => {
                this.measurementList = [];
                this.preventNewLines = false;
                this.clearMeasurement();
                this.beginMeasurement();
                this.activeTool = 'measurement';
              });
            }
          });
          this.view.on('drag', (event) => {
            if (event.action === 'end') this.filterMapView();
          });
          this.view.on('mouse-wheel', () => {
            // this.filterMapView();
            this.filterMap = true;
          });
          this.view.on('key-down', (event: Event) => {
            if (event.key === 'Escape') {
              if (this.selectedAssets.length > 0) {
                this.selectedAssets = [];
                this.selectedAssetGraphics = [];
                this.clearHighlights();
                this.closePopout();
              }
            }
          });
          this.mapLoadFinished = true;
        },
      )
      .catch((error) => {
        console.error(error);
      });

    this.mapLoaded = true;
  }

  async filterMapView(): Promise<void> {
    if (!this.useZoomFilter && this.queryGeometry == null) {
      return;
    }

    const query = {
      geometry: this.view.extent /* .clone() */,
      outFields: ['*'],
      returnGeometry: true,
      spatialRelationship: 'intersects',
    };
    const tempSelectedMapFeatures = [];

    if (window.Worker) {
      this.filterMapViewFromQuery(query);
    } else {
      this.assetFeaturesLayer
        .queryFeatures(query)
        .then((results) => {
          results.features.forEach((feature) => {
            const thisAsset = this.mapData.find(
              (asset) => asset.guid === feature.attributes.guid,
            );
            tempSelectedMapFeatures.push(thisAsset);
            this.filters.selectedAssets.push(thisAsset.guid);
          });
        })
        .catch(this.errorCallback);

      if (this.infoOptions.find((o) => o.name === 'Line Segments').exists) {
        this.lineSegmentFeaturesLayer
          .queryFeatures(query)
          .then((results) => {
            if (results.features.length === 0) {
              // this.clearSelection();
            } else {
              results.features.forEach((feature) => {
                const thisAsset = this.mapData.find(
                  (asset) => asset.guid === feature.attributes.guid,
                );
                tempSelectedMapFeatures.push(thisAsset);
              });
              this.filters.selectedAssets = tempSelectedMapFeatures;
            }
          })
          .catch(this.errorCallback);
      }

      if (this.parcelsLayer != null) {
        this.parcelsLayer
          .queryFeatures(query)
          .then((results) => {
            if (results.features.length === 0) {
              // this.clearSelection();
            } else {
              results.features.forEach((feature) => {
                const thisAsset = this.mapData.find(
                  (asset) => asset.guid === feature.attributes.guid,
                );
                tempSelectedMapFeatures.push(thisAsset);
              });
              this.filters.selectedAssets = tempSelectedMapFeatures;
            }
          })
          .catch(this.errorCallback);
      }
    }
  }

  async filterMapViewFromQuery(query: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geometry?: any;
    outFields: string[];
    returnGeometry: boolean;
    spatialRelationship: string;
  }): Promise<void> {
    // only filter map view if selection box isn't currently used
    if (this.queryGeometry == null) {
      if (window.Worker) {
        this.loading = true;
        const worker = new Worker('./worker.js', { type: 'module' });
        this.timeRequestMade = Date.now();
        const promises = [];

        if (this.assetFeaturesLayer != null) {
          promises.push(this.assetFeaturesLayer.queryFeatures(query));
          if (this.infoOptions.find((o) => o.name === 'Line Segments').exists && this.lineSegmentFeaturesLayer) {
            promises.push(this.lineSegmentFeaturesLayer.queryFeatures(query));
          }
        }
        if (this.parcelsLayer != null) {
          promises.push(this.parcelsLayer.queryFeatures(query));
        }

        const results = await Promise.all(promises);
        const assets = JSON.stringify(this.mapData);
        worker.postMessage({
          type: 'filterMapView',
          timeRequestMade: this.timeRequestMade,
          features: JSON.stringify(results),
          assets,
          selectedFeatures: assets,
        });

        worker.onmessage = (e) => {
          if (this.timeRequestMade === JSON.parse(e.data).timeRequestMade) {
            this.loading = false;
            const tempFeatures = [];
            JSON.parse(e.data).features.forEach((value) => {
              tempFeatures.push(this.mapData.find((val) => val.guid === value));
            });
            // checking this internally is weird, but if we don't it'll override the selection box
            if (this.queryGeometry == null) {
              this.filters.selectedAssets = tempFeatures.map(
                (asset) => asset && asset.guid,
              );
            }
          }
        };
      }
    }
  }

  checkFilterTrigger(): void {
    if (this.filterMap === true) {
      this.filterMapView();
      this.filterMap = false;
    }
  }

  /**
   * @description Check esri webgl context for a mac chrome error that causes labels to not show up.
   */
  checkChromeGLError(): void {
    this.chromeGLCheckIteration += 1;

    // Error only shows up after layers are fully loaded
    // Only need to check this right after map is loaded
    // Error is cleared eventually somewhere else, so no need to check this forever
    if (this.chromeGLCheckIteration >= this.chromeGLCheckMaxIteration) {
      window.clearInterval(this.chromeGLInterval);
    }

    const GL_INVALID_OPERATION_ERROR_CODE = 1282;

    const esriCanvasList = document.getElementsByClassName('esri-view-surface');
    if (esriCanvasList.length === 0) {
      return;
    }
    const esriCanvas = esriCanvasList[0] as HTMLElement;

    if (esriCanvas === null) {
      return;
    }

    // Check esri's webgl instance for any errors
    // If there is an invalid operation error code, then show warning
    if (esriCanvas.childElementCount > 0) {
      const esriCanvasChild = esriCanvas.children[0];
      const gl = esriCanvasChild.getContext('webgl');
      if (gl.getError() === GL_INVALID_OPERATION_ERROR_CODE && !this.showedChromeGLError) {
        this.showChromeFlagWarningMessage();
      }
    }
  }

  dataSetupManholesLayer(): Promise {
    const manholeGraphics = [];

    this.mapData.forEach((asset: AssetData) => {
      if (asset.type === AssetType.MANHOLE) {
        const location = asset.location.find((loc) => loc.source === 'CustomerData');

        if (
          location == null
          || location.longitude === 0
          || location.longitude > 180
          || location.longitude < -180
          || location.latitude === 0
          || location.latitude > 90
          || location.latitude < -90
        ) {
          return;
        }

        let assetScore = null;

        if (!asset.visible) assetScore = 10;
        else assetScore = asset.score;

        const attributes = {
          guid: asset.guid,
          longitude: location.longitude,
          latitude: location.latitude,
          assetName: asset.name,
          priority: (!assetScore && assetScore !== 0) ? null : assetScore,
          score: (!assetScore && assetScore !== 0) ? null : assetScore,
          taskResult: asset.taskResult || null,
        };

        const point = {
          type: 'point',
          longitude: attributes.longitude,
          latitude: attributes.latitude,
        };

        const pointGraphic = new this.Graphic({
          geometry: point,
          attributes,
        });
        manholeGraphics.push(pointGraphic);
      }
    });

    this.assetFeaturesLayer.applyEdits({ addFeatures: manholeGraphics });
  }

  structSetupManholesLayer(): Promise {
    return new this.FeatureLayer({
      title: 'Assets',
      geometryType: 'point',
      objectIdField: 'OBJECTID',
      displayField: 'assetName',
      spatialReference: { wkid: 4326 },
      source: [],
      fields: [
        {
          name: 'OBJECTID',
          alias: 'oid',
          type: 'global-id',
        },
        {
          name: 'assetName',
          alias: 'assetName',
          type: 'string',
        },
        {
          name: 'guid',
          alias: 'guid',
          type: 'guid',
        },
        {
          name: 'priority',
          alias: 'priority',
          type: 'integer',
        },
        {
          name: 'score',
          alias: 'score',
          type: 'integer',
        },
        {
          name: 'latitude',
          alias: 'latitude',
          type: 'double',
        },
        {
          name: 'longitude',
          alias: 'longitude',
          type: 'double',
        },
        {
          name: 'taskResult',
          alias: 'taskResult',
          type: 'string',
        },
      ],
      outFields: ['*'],
      renderer: this.getManholeRenderer(),
      popupEnabled: this.popupEnabled,
      labelingInfo: [
        {
          symbol: {
            type: 'text',
            color: 'black',
            haloColor: 'white',
            haloSize: '2px',
            font: {
              family: 'Arial',
              size: 10,
              weight: 'bold',
            },
          },
          labelPlacement: 'above-center',
          labelExpressionInfo: {
            expression: '$feature.assetName',
          },
          where: 'score < 6',
        },
      ],
    });
  }

  structSetupLineSegmentsLayer(): Promise {
    return new this.FeatureLayer({
      title: 'Line Segments',
      geometryType: 'polyline',
      objectIdField: 'OBJECTID',
      displayField: 'assetName',
      spatialReference: { wkid: 4326 },
      source: [],
      fields: [
        {
          name: 'OBJECTID',
          alias: 'oid',
          type: 'global-id',
        },
        {
          name: 'assetName',
          alias: 'assetName',
          type: 'string',
        },
        {
          name: 'guid',
          alias: 'guid',
          type: 'guid',
        },
        {
          name: 'upstream',
          alias: 'upstream',
          type: 'string',
        },
        {
          name: 'downstream',
          alias: 'downstream',
          type: 'string',
        },
        {
          name: 'validation',
          alias: 'validation',
          type: 'integer',
        },
        {
          name: 'validationString',
          alias: 'validationString',
          type: 'string',
        },
        {
          name: 'inspectionStatus',
          alias: 'inspectionStatus',
          type: 'string',
        },
        {
          name: 'hasFlowData',
          alias: 'hasFlowData',
          type: 'integer',
        },
        {
          name: 'score',
          alias: 'score',
          type: 'integer',
        },
        {
          name: 'slratScore',
          alias: 'slratScore',
          type: 'integer',
        },
        {
          name: 'taskResult',
          alias: 'taskResult',
          type: 'string',
        },
      ],
      outFields: ['*'],
      minScale: this.lineSegementThinningZoomLevel,
      // popupTemplate: lineSegmentPopupTemplate,
      renderer: this.getLineSegmentRenderer(),
      popupEnabled: this.popupEnabled,
      labelingInfo: [
        {
          // autocasts as new LabelClass()
          symbol: {
            type: 'text', // autocasts as new TextSymbol()
            color: 'black',
            haloColor: 'white',
            haloSize: '1px',
            font: {
              // autocast as new Font()
              family: 'Arial',
              size: 10,
              weight: 'normal',
            },
          },
          deconflictionStrategy: 'none',
          // currently only center-along is supported for polyline
          labelPlacement: 'center-along',
          labelExpressionInfo: {
            expression: '$feature.assetName',
          },
          where: 'score < 6',
        },
      ],
    });
  }

  dataSetupLineSegmentsLayer(): Promise {
    const lineSegmentGraphics = [];

    let isLineSegmentFlowDirectionActive = false;
    const foundLineSegmentInfo = this.infoOptions.find((o) => o.name === 'Line Segments');
    if (foundLineSegmentInfo && foundLineSegmentInfo.contents != null) {
      const foundLineSegmentFlowDirection = foundLineSegmentInfo.contents.find((o) => o.name === 'lineSegmentFlowDirection');
      if (foundLineSegmentFlowDirection) {
        isLineSegmentFlowDirectionActive = foundLineSegmentFlowDirection.active;
      }
    }

    this.mapData.forEach((asset: AssetData) => {
      if (asset.type === AssetType.LINE_SEGMENT) {
        const locationLS = asset.location.find((loc) => loc.downstream?.source === 'CustomerData');
        const lineSegmentLoc = [];

        if (locationLS != null && locationLS.upstream != null && locationLS.downstream != null) {
          lineSegmentLoc.push([
            locationLS.upstream.longitude,
            locationLS.upstream.latitude,
          ]);
          lineSegmentLoc.push([
            locationLS.downstream.longitude,
            locationLS.downstream.latitude,
          ]);
        }

        let assetScore = null;
        let assetSlratScore = null;

        if (!asset.visible) {
          assetScore = 10;
          assetSlratScore = 20;
        } else {
          assetScore = asset.score;
          // placeholder prop
          assetSlratScore = asset.slRatScore;
        }

        const attributes = {
          guid: asset.guid,
          assetName: asset.name,
          validation: asset.score,
          // validationString: 'asset.validation',
          hasFlowData: !isLineSegmentFlowDirectionActive
            || lineSegmentLoc.length < 2 ? 0 : 1,
          priority: (!assetScore && assetScore !== 0) ? null : assetScore,
          score: (!assetScore && assetScore !== 0) ? -1 : assetScore,
          slratScore: assetSlratScore == null ? -1 : assetSlratScore,
          taskResult: asset.taskResult || null,
        };

        // Create graphic object and add to the array if upstream and downstream are populated
        if (lineSegmentLoc.length > 0) {
          const line = {
            hasZ: false,
            hasM: false,
            type: 'polyline',
            paths: [lineSegmentLoc],
          };

          const lineGraphic = new this.Graphic({
            geometry: line,
            attributes,
          });

          lineSegmentGraphics.push(lineGraphic);
        }
      }
    });

    this.lineSegmentFeaturesLayer.applyEdits({
      addFeatures: lineSegmentGraphics,
    });
  }

  dataSetupManholeInspectionsLayer(): Promise {
    const manholeGraphics = [];

    this.mapData.forEach((asset: AssetData) => {
      if (asset.type === AssetType.MANHOLE) {
        const location = asset.location.find((loc) => loc.source === 'Inspections');

        if (
          location === null
          || location === undefined
          || location.longitude === 0
          || location.latitude === 0
        ) {
          return;
        }

        let assetScore = null;

        if (!asset.visible) assetScore = 10;
        else assetScore = asset.score;

        const attributes = {
          guid: asset.guid,
          longitude: location.longitude,
          latitude: location.latitude,
          assetName: asset.name,
          priority: (!assetScore && assetScore !== 0) ? null : assetScore,
          score: (!assetScore && assetScore !== 0) ? null : assetScore,
          taskResult: asset.taskResult || null,
        };

        const point = {
          type: 'point',
          longitude: attributes.longitude,
          latitude: attributes.latitude,
        };

        const assetGraphic = new this.Graphic({
          geometry: point,
          attributes,
        });

        manholeGraphics.push(assetGraphic);
      }
    });

    this.manholeInspectionsLayer.applyEdits({ addFeatures: manholeGraphics });
  }

  structSetupManholeInspectionsLayer(): Promise {
    return new this.FeatureLayer({
      title: 'Inspection Manholes',
      geometryType: 'point',
      objectIdField: 'OBJECTID',
      displayField: 'assetName',
      spatialReference: { wkid: 4326 },
      source: [],
      fields: [
        {
          name: 'OBJECTID',
          alias: 'oid',
          type: 'global-id',
        },
        {
          name: 'assetName',
          alias: 'assetName',
          type: 'string',
        },
        {
          name: 'guid',
          alias: 'guid',
          type: 'guid',
        },
        {
          name: 'priority',
          alias: 'priority',
          type: 'integer',
        },
        {
          name: 'score',
          alias: 'score',
          type: 'integer',
        },
        {
          name: 'latitude',
          alias: 'latitude',
          type: 'double',
        },
        {
          name: 'longitude',
          alias: 'longitude',
          type: 'double',
        },
        {
          name: 'taskResult',
          alias: 'taskResult',
          type: 'string',
        },
      ],
      outFields: ['*'],
      renderer: this.getManholeInspectionsRenderer(),
      popupEnabled: this.popupEnabled,
      labelingInfo: [
        {
          symbol: {
            type: 'text',
            color: 'black',
            haloColor: 'white',
            haloSize: '2px',
            font: {
              family: 'Arial',
              size: 10,
              weight: 'bold',
            },
          },
          labelPlacement: 'above-center',
          labelExpressionInfo: {
            expression: '$feature.assetName',
          },
          where: 'score < 6',
        },
      ],
      visible: false,
    });
  }

  dataSetupLineSegmentInspectionsLayer(): Promise {
    const lineSegmentGraphics = [];

    let isLineSegmentFlowDirectionActive = false;
    const foundLineSegmentInfo = this.infoOptions.find((o) => o.name === 'Line Segments');
    if (foundLineSegmentInfo && foundLineSegmentInfo.contents != null) {
      const foundLineSegmentFlowDirection = foundLineSegmentInfo.contents.find((o) => o.name === 'lineSegmentFlowDirection');
      if (foundLineSegmentFlowDirection) {
        isLineSegmentFlowDirectionActive = foundLineSegmentFlowDirection.active;
      }
    }

    this.mapData.forEach((asset: AssetData) => {
      if (asset.type === AssetType.LINE_SEGMENT) {
        const locationLS = asset.location.find((loc) => loc.source === 'Inspections');
        const lineSegmentLoc = [];

        if (locationLS && locationLS.upstream && locationLS.downstream) {
          lineSegmentLoc.push([
            locationLS.upstream.longitude,
            locationLS.upstream.latitude,
          ]);

          lineSegmentLoc.push([
            locationLS.downstream.longitude,
            locationLS.downstream.latitude,
          ]);
        }

        const attributes = {
          guid: asset.guid,
          assetName: asset.name,
          validation: asset.score,
          hasFlowData: !isLineSegmentFlowDirectionActive
            || lineSegmentLoc.length < 2 ? 0 : 1,
          score: asset.score,
          // placeholder prop
          slratScore: asset.slRatScore == null ? -1 : asset.slRatScore,
          taskResult: asset.taskResult || null,
        };

        // Create graphic object and add to the array if upstream and downstream are populated
        if (lineSegmentLoc.length > 0) {
          const line = {
            hasZ: false,
            hasM: false,
            type: 'polyline',
            paths: [lineSegmentLoc],
          };

          const lineGraphic = new this.Graphic({
            geometry: line,
            attributes,
          });

          lineSegmentGraphics.push(lineGraphic);
        }
      }
    });

    this.lineSegmentInspectionsLayer.applyEdits({ addFeatures: lineSegmentGraphics });
  }

  structSetupLineSegmentInspectionsLayer(): Promise {
    return new this.FeatureLayer({
      title: 'Inspection Line Segments',
      geometryType: 'polyline',
      objectIdField: 'OBJECTID',
      displayField: 'assetName',
      spatialReference: { wkid: 4326 },
      source: [],
      fields: [
        {
          name: 'OBJECTID',
          alias: 'oid',
          type: 'global-id',
        },
        {
          name: 'assetName',
          alias: 'assetName',
          type: 'string',
        },
        {
          name: 'guid',
          alias: 'guid',
          type: 'guid',
        },
        {
          name: 'upstream',
          alias: 'upstream',
          type: 'string',
        },
        {
          name: 'downstream',
          alias: 'downstream',
          type: 'string',
        },
        {
          name: 'validation',
          alias: 'validation',
          type: 'integer',
        },
        {
          name: 'validationString',
          alias: 'validationString',
          type: 'string',
        },
        {
          name: 'inspectionStatus',
          alias: 'inspectionStatus',
          type: 'string',
        },
        {
          name: 'hasFlowData',
          alias: 'hasFlowData',
          type: 'integer',
        },
        {
          name: 'score',
          alias: 'score',
          type: 'integer',
        },
        {
          name: 'slratScore',
          alias: 'slratScore',
          type: 'integer',
        },
        {
          name: 'taskResult',
          alias: 'taskResult',
          type: 'string',
        },
      ],
      outFields: ['*'],
      minScale: this.lineSegementThinningZoomLevel,
      renderer: this.getLineSegmentInspectionsRenderer(),
      popupEnabled: this.popupEnabled,
      labelingInfo: [
        {
          // autocasts as new LabelClass()
          symbol: {
            type: 'text', // autocasts as new TextSymbol()
            color: 'black',
            haloColor: 'white',
            haloSize: '1px',
            font: {
              // autocast as new Font()
              family: 'Arial',
              size: 10,
              weight: 'normal',
            },
          },
          deconflictionStrategy: 'none',
          // currently only center-along is supported for polyline
          labelPlacement: 'center-along',
          labelExpressionInfo: {
            expression: '$feature.assetName',
          },
          where: 'score < 6',
        },
      ],
      visible: false,
    });
  }

  dataSetupParcelsLayer(): Promise {
    const parcelGraphics = [];

    this.mapData.forEach((asset: AssetData) => {
      if (asset.type === AssetType.PARCEL) {
        const polygonLocations = asset.location.map((loc: Location[]) => [
          loc.longitude,
          loc.latitude,
        ]);

        let assetScore = -1;
        if (!asset.visible) {
          assetScore = 0;
        }

        const attributes = {
          guid: asset.guid,
          assetName: asset.name,
          hasScore: 1,
          score: assetScore,
          taskResult: asset.taskResult || null,
        };

        // Create graphic object and add to the array if upstream and downstream are populated
        if (polygonLocations.length > 0) {
          const polygon = {
            type: 'polygon',
            rings: [...polygonLocations],
          };

          const graphic = new this.Graphic({
            geometry: polygon,
            attributes,
          });

          parcelGraphics.push(graphic);
        }
      }
    });

    this.parcelsLayer.applyEdits({ addFeatures: parcelGraphics });
  }

  structSetupParcelsLayer(): Promise {
    return new this.FeatureLayer({
      title: 'Parcels',
      geometryType: 'polygon',
      objectIdField: 'OBJECTID',
      displayField: 'assetName',
      spatialReference: { wkid: 4326 },
      source: [],
      fields: [
        {
          name: 'OBJECTID',
          alias: 'oid',
          type: 'global-id',
        },
        {
          name: 'assetName',
          alias: 'assetName',
          type: 'string',
        },
        {
          name: 'guid',
          alias: 'guid',
          type: 'guid',
        },
        {
          name: 'hasScore',
          alias: 'hasScore',
          type: 'integer',
        },
        {
          name: 'score',
          alias: 'score',
          type: 'integer',
        },
        {
          name: 'taskResult',
          alias: 'taskResult',
          type: 'string',
        },
      ],
      outFields: ['*'],
      minScale: this.parcelThinningZoomLevel,
      renderer: this.getParcelRenderer(),
      popupEnabled: this.popupEnabled,
      labelingInfo: [
        {
          // autocasts as new LabelClass()
          symbol: {
            type: 'text', // autocasts as new TextSymbol()
            color: 'black',
            haloColor: 'white',
            haloSize: '1px',
            font: {
              // autocast as new Font()
              family: 'Arial',
              size: 10,
              weight: 'normal',
            },
          },
          deconflictionStrategy: 'none',
          // always-horizontal is the only option
          labelPlacement: 'always-horizontal',
          minScale: this.parcelTextThinningZoomLevel,
          labelExpressionInfo: {
            expression: '$feature.assetName',
          },
          where: 'score < 6',
        },
      ],
    });
  }

  setupMapPages(): void {
    const mapPagesGraphics = [];

    if (this.mapPages) {
      this.mapPages.forEach((page: MapPage) => {
        const rings = [];
        const attributes = {
          guid: page.guid,
          name: page.name,
        };

        page.pageBounds.points.forEach((point: Point) => {
          rings.push([point.x, point.y]);
        });

        const polygon = {
          type: 'polygon',
          rings,
        };

        const polygonGraphic = new this.Graphic({
          geometry: polygon,
          attributes,
        });
        mapPagesGraphics.push(polygonGraphic);
      });
    }

    this.mapPagesFeaturesLayer.source = mapPagesGraphics;
  }

  setupMapPagesFeatureLayer(): Promise {
    return new this.FeatureLayer({
      title: 'Map Pages',
      geometryType: 'polygon',
      objectIdField: 'OBJECTID',
      displayField: 'name',
      source: [],
      renderer: {
        type: 'simple',
        symbol: {
          type: 'simple-fill',
          color: [0, 0, 0, 0],
          outline: {
            color: '#e61e25',
            width: 1,
          },
        },
      },
      fields: [
        {
          name: 'OBJECTID',
          alias: 'oid',
          type: 'global-id',
        },
        {
          name: 'name',
          alias: 'name',
          type: 'string',
        },
        {
          name: 'guid',
          alias: 'guid',
          type: 'guid',
        },
      ],
      outFields: ['*'],
      labelingInfo: [
        {
          symbol: {
            type: 'text',
            color: '#e61e25',
            haloColor: 'white',
            haloSize: '2px',
            font: {
              family: 'Arial',
              size: 12,
              weight: 'bold',
            },
          },
          labelPlacement: 'always-horizontal',
          labelExpressionInfo: {
            expression: '$feature.name',
          },
        },
      ],
    });
  }

  structSetupLateralFeaturesLayer(): Promise {
    return new this.FeatureLayer({
      title: 'Laterals',
      geometryType: 'polyline',
      objectIdField: 'OBJECTID',
      displayField: '',
      source: [],
      fields: [
        {
          name: 'guid',
          alias: 'guid',
          type: 'string',
        },
        {
          name: 'type',
          alias: 'type',
          type: 'string',
        },
      ],
      outFields: ['*'],
      minScale: this.lateralsThinningZoomLevel,
      renderer: {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'type',
        defaultSymbol: {
          type: 'simple-line', // default SimpleLineSymbol
        },
        uniqueValueInfos: [
          {
            value: 'Lateral',
            symbol: {
              type: 'cim', // autocasts as CIMSymbol
              data: {
                type: 'CIMSymbolReference',
                symbol: {
                  type: 'CIMLineSymbol',
                  symbolLayers: [
                    {
                      // black 1px line symbol
                      type: 'CIMSolidStroke',
                      enable: true,
                      width: 1,
                      color: [0, 0, 0, 255],
                    },
                  ],
                },
              },
            },
          },
        ],
      },
    });
  }

  dataSetupLateralFeaturesLayer(): Promise {
    const lateralGraphics = [];
    const lateralLayerOption = this.infoOptions.find((l) => l.name === 'Laterals');

    this.mapData.forEach((asset: AssetRef) => {
      if (asset.type === AssetType.LATERAL) {
        const attributes = { guid: asset.guid, type: asset.type };
        const lateral = asset.location;
        const line = {
          hasZ: false,
          hasM: false,
          type: 'polyline',
          paths: [
            [lateral[0].longitude, lateral[0].latitude],
            [lateral[1].longitude, lateral[1].latitude],
          ],
        };
        const lineGraphic = new this.Graphic({
          geometry: line,
          attributes,
        });

        lateralGraphics.push(lineGraphic);
      }
    });

    if (lateralGraphics.length > 0) lateralLayerOption.exists = true;

    this.lateralFeaturesLayer.source = lateralGraphics;
  }

  onSearchBarLoad(): void {
    const searchbar = (
      document.getElementsByClassName('esri-ui-top-left')[0] as HTMLElement
    ).childNodes[0] as HTMLElement;
    if (searchbar != null && this.searchWidgetLoaded === false) {
      const newSearchbar = (document.getElementById('searchbar') as HTMLElement);
      if (newSearchbar == null) {
        if (!this.hasPermissionGISCommon) {
          searchbar.style.visibility = 'hidden';
        }
        this.searchWidgetLoaded = true;
        return;
      }
      searchbar.style.visibility = 'hidden';
      searchbar.style.position = 'relative';
      searchbar.style.margin = '0px 0px 26px 0px';
      newSearchbar.append(searchbar);
      const form = (
        document.getElementsByClassName('esri-search__form')[0] as HTMLElement
      ).childNodes[0] as HTMLInputElement;
      searchbar.style.visibility = 'visible';
      form.addEventListener('input', this.onInputChange);
      this.searchWidgetLoaded = true;
    }
  }

  onInputChange(): void {
    if (this.queryGeometry !== null) {
      return;
    }
    // On change of ActiveSourceIndex, suggest the query again to update the suggested items
    if (
      this.searchWidget.suggestions !== null
      && this.searchWidget.activeSourceIndex !== this.lastActiveSourceIndex
    ) {
      this.searchWidget.suggest(this.searchWidget.searchTerm);
      this.lastActiveSourceIndex = this.searchWidget.activeSourceIndex;
    }

    if (document.getElementsByClassName('esri-search__form')[0] != null) {
      const form = (
        document.getElementsByClassName('esri-search__form')[0] as HTMLElement
      ).childNodes[0] as HTMLInputElement;
      if (form.value === '') {
        this.searchWidget.clear();
        this.selectAll(false);
      }
      if (
        document.getElementsByClassName('esri-search__clear-button').length
        !== 0
      ) {
        const selectAllMock = this.selectAll;
        (
          document.getElementsByClassName(
            'esri-search__clear-button',
          )[0] as HTMLButtonElement
        ).onclick = function clear() {
          selectAllMock();
        };
      }
    }
  }

  createPointGraphic(lat: number, long: number): void {
    const point = {
      type: 'point', // autocasts as new Point()
      longitude: long,
      latitude: lat,
    };

    const textSymbol = {
      type: 'text', // autocasts as new TextSymbol()
      color: 'black',
      haloColor: 'white',
      haloSize: '2px',
      font: {
        // autocast as new Font()
        family: 'Arial',
        size: 10,
        weight: 'bold',
      },
      text: `M${this.measurementList.length + 1}`,
      // xoffset: 3,
      yoffset: '33px',
    };

    // Create a symbol for drawing the point
    const markerSymbol = {
      type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
      url: 'https://static.arcgis.com/images/Symbols/Basic1/Black_1_Esri_Pin2.png',
      width: '32px',
      height: '32px',
      yoffset: '17px',
    };

    // Create a graphic and add the geometry and symbol to it
    const pointGraphic = new this.Graphic({
      geometry: point,
      symbol: markerSymbol,
    });

    const textGraphic = new this.Graphic({
      geometry: point,
      symbol: textSymbol,
    });

    // Add the graphics to the view's graphics layer
    this.view.graphics.add(pointGraphic);
    this.view.graphics.add(textGraphic);
  }

  setupViewClickHandling(): void {
    this.view.on('click', (evt: MouseEvent) => {
      // Only fire on left clicks
      if (evt.button === 0) {
        if (this.activeTool !== 'measurement') {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.view.hitTest(evt.screenPoint).then((response: any): void => {
            if (!evt.native.shiftKey) this.clearHighlights();

            let selectedAsset: AssetRef = null;

            if (response.results.length > 0) {
              const feature = response.results[0];
              const { attributes } = feature.graphic;
              let featureLayer = null;
              let featureLayerView = null;

              switch (feature.graphic.layer.title) {
                case 'Assets':
                  featureLayer = this.assetFeaturesLayer;
                  featureLayerView = this.assetLayerView;
                  break;
                case 'Line Segments':
                  featureLayer = this.lineSegmentFeaturesLayer;
                  featureLayerView = this.lineSegmentLayerView;
                  break;
                case 'Parcels':
                  featureLayer = this.parcelsLayer;
                  featureLayerView = this.parcelsLayerView;
                  break;
                case 'Laterals':
                  featureLayer = this.lateralFeaturesLayer;
                  featureLayerView = this.lateralLayerView;
                  break;
                default:
                  break;
              }

              if (featureLayer && featureLayerView) {
                if (this.view.scale > this.manholeClusterZoomLevel) {
                  this.view.scale = this.manholeClusterZoomLevel - 1;
                  this.view.goTo(feature.graphic.geometry);
                  return;
                }

                selectedAsset = this.mapData.find((asset) => asset.guid === attributes.guid);

                if (!selectedAsset) { // No asset clicked
                  this.selectedAssets = [];
                  this.selectedAssetGraphics = [];
                  this.clearHighlights();
                } else if (evt.native.shiftKey) { // Asset clicked, shift key pressed
                  const index = this.selectedAssets.indexOf(selectedAsset);

                  if (index > -1) { // Asset already in array
                    this.selectedAssets.splice(index, 1);
                    this.selectedAssetGraphics.splice(index, 1);
                  } else { // Asset not in array
                    this.selectedAssets.push(selectedAsset);
                    this.selectedAssetGraphics.push(feature.graphic);
                  }

                  this.setHighlights();
                } else { // Asset clicked, shift key not pressed
                  this.clearHighlights();
                  this.selectedAssets = [selectedAsset];
                  this.selectedAssetGraphics = [feature.graphic];
                }
              } else {
                this.selectedAssets = [];
                this.selectedAssetGraphics = [];
                this.isRouteActive = false;
                this.clearHighlights();
              }
            }

            this.$emit('update-selected-asset', { selectedAssets: this.selectedAssets, multiSelect: evt.native.shiftKey });
          });
        }

        if (!this.preventNewLines && this.activeTool === 'measurement') {
          this.createPointGraphic(
            evt.mapPoint.latitude,
            evt.mapPoint.longitude,
          );
        }
      }
    });
  }

  setHighlights(): void {
    this.clearHighlights();

    const mhGraphics = this.selectedAssetGraphics.filter((asset) => asset.layer.title === 'Assets');
    const lsGraphics = this.selectedAssetGraphics.filter((asset) => asset.layer.title === 'Line Segments');
    const parcelsGraphics = this.selectedAssetGraphics.filter((asset) => asset.layer.title === 'Parcels');
    const latGraphics = this.selectedAssetGraphics.filter((asset) => asset.layer.title === 'Laterals');

    this.manholeLayerHighlight = this.assetLayerView.highlight(mhGraphics);
    this.lineSegmentLayerHighlight = this.lineSegmentLayerView.highlight(lsGraphics);
    this.parcelsLayerHighlight = this.parcelsLayerView.highlight(parcelsGraphics);
    this.lateralLayerHighlight = this.lateralLayerView.highlight(latGraphics);
  }

  clearHighlights(): void {
    if (this.highlighted) this.highlighted.remove();
    if (this.manholeLayerHighlight) this.manholeLayerHighlight.remove();
    if (this.lineSegmentLayerHighlight) this.lineSegmentLayerHighlight.remove();
    if (this.parcelsLayerHighlight) this.parcelsLayerHighlight.remove();
    if (this.lateralLayerHighlight) this.lateralLayerHighlight.remove();
  }

  togglePrint(closePrint = false): void {
    if (!this.showPrint && !closePrint) {
      this.showPrint = true;
      this.activeTool = 'print';
      if (this.view) {
        this.view.ui.add(this.print, 'top-right');
      }
    } else if (closePrint === true || this.showPrint) {
      this.activeTool = '';
      this.showPrint = false;
      if (this.view) {
        this.view.ui.remove(this.print, 'top-right');
      }
    }
  }

  beginSelectTool(mode: string): void {
    this.activeSelectionMode = this.selectionModeOptions.find((o) => o.mode === mode);
    this.polygonGraphicsLayer.removeAll();
    if (mode === 'pointer') {
      this.clearSelection(true, false);
      return;
    }
    this.activeTool = 'select';
    this.sketchViewModel.create(mode);
  }

  // This function is called when user completes drawing a rectangle on the map
  // Use the rectangle to select features in the layer
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async selectFeatures(Geometry): void {
    // Create a query and set its geometry parameter to the rectangle that was drawn on the view
    // Geometry set for the query can be polygon for point features
    // Only intersecting geometries are returned
    const query = {
      geometry: Geometry,
      outFields: ['*'],
    };

    this.selectedAssets = [];
    this.selectedAssetGraphics = [];

    // Query each asset layer
    try {
      const awaitAllFunctions = [];
      if (this.assetFeaturesLayer) {
        awaitAllFunctions.push(this.assetFeaturesLayer.queryFeatures(query)
          .then((results) => {
            if (results.features.length > 0) {
              results.features.forEach((feature) => {
                this.selectedAssets.push(
                  this.mapData.find((asset) => asset.guid === feature.attributes.guid),
                );
                this.selectedAssetGraphics.push(feature);
              });
            }
          }));
      }
      if (this.lineSegmentFeaturesLayer) {
        awaitAllFunctions.push(this.lineSegmentFeaturesLayer.queryFeatures(query)
          .then((results) => {
            if (results.features.length > 0) {
              results.features.forEach((feature) => {
                this.selectedAssets.push(
                  this.mapData.find((asset) => asset.guid === feature.attributes.guid),
                );
                this.selectedAssetGraphics.push(feature);
              });
            }
          }));
      }
      if (this.parcelsLayer) {
        awaitAllFunctions.push(this.parcelsLayer.queryFeatures(query)
          .then((results) => {
            if (results.features.length > 0) {
              results.features.forEach((feature) => {
                this.selectedAssets.push(
                  this.mapData.find((asset) => asset.guid === feature.attributes.guid),
                );
                this.selectedAssetGraphics.push(feature);
              });
            }
          }));
      }

      if (this.lateralFeaturesLayer) {
        awaitAllFunctions.push(this.lateralFeaturesLayer.queryFeatures(query)
          .then((results) => {
            if (results.features.length > 0) {
              results.features.forEach((feature) => {
                this.selectedAssets.push(
                  this.mapData.find((asset) => asset.guid === feature.attributes.guid),
                );
                this.selectedAssetGraphics.push(feature);
              });
            }
          }));
      }

      await Promise.all(awaitAllFunctions);
      this.setHighlights();
    } catch {
      this.errorCallback();
    }
  }

  // This function selects features by asset guid
  async selectFeaturesByGuids(assetGuids: string[]): void {
    const features = [];
    let results = [];

    this.selectedAssets = [];
    this.selectedAssetGraphics = [];

    // Query each asset layer
    try {
      if (this.assetFeaturesLayer) {
        results = await this.assetFeaturesLayer.queryFeatures();
        if (results.features.length > 0) {
          results.features.forEach((feature) => {
            if (assetGuids.includes(feature.attributes.guid)) {
              this.selectedAssets.push(
                this.mapData.find((asset) => asset.guid === feature.attributes.guid),
              );
              features.push(feature);
              this.selectedAssetGraphics.push(feature);
            }
          });
        }
      }

      if (this.lineSegmentFeaturesLayer) {
        results = await this.lineSegmentFeaturesLayer.queryFeatures();
        if (results.features.length > 0) {
          results.features.forEach((feature) => {
            if (assetGuids.includes(feature.attributes.guid)) {
              this.selectedAssets.push(
                this.mapData.find((asset) => asset.guid === feature.attributes.guid),
              );
              features.push(feature);
              this.selectedAssetGraphics.push(feature);
            }
          });
        }
      }

      if (this.parcelsLayer) {
        results = await this.parcelsLayer.queryFeatures();
        if (results.features.length > 0) {
          results.features.forEach((feature) => {
            if (assetGuids.includes(feature.attributes.guid)) {
              this.selectedAssets.push(
                this.mapData.find((asset) => asset.guid === feature.attributes.guid),
              );
              features.push(feature);
              this.selectedAssetGraphics.push(feature);
            }
          });
        }
      }

      if (this.lateralFeaturesLayer) {
        results = await this.lateralFeaturesLayer.queryFeatures();
        if (results.features.length > 0) {
          results.features.forEach((feature) => {
            if (assetGuids.includes(feature.attributes.guid)) {
              this.selectedAssets.push(
                this.mapData.find((asset) => asset.guid === feature.attributes.guid),
              );
              features.push(feature);
              this.selectedAssetGraphics.push(feature);
            }
          });
        }
      }

      this.setHighlights();

      if (features.length > 0) await this.view.goTo(features);
    } catch {
      this.errorCallback();
    }
  }

  errorCallback(): void {
    console.log("There's been an error");
  }

  clearSelection(cancelSketch = true, clearFilters = true): void {
    this.queryGeometry = null;
    // cancel select
    if (cancelSketch) {
      this.sketchViewModel.cancel();
      // clear active tool
      if (this.activeTool === 'select') this.activeTool = '';
      this.polygonGraphicsLayer.removeAll();
    }
    // clear array and remove selection box
    if (clearFilters) this.filters.selectedAssets = [];
  }

  selectAll(clearWidget = true): void {
    // clear search bar
    if (clearWidget) {
      // this is probably broken, lol
      // this.searchWidget.clear();
      // gross fix
      const form = (
        document.getElementsByClassName('esri-search__form')[0] as HTMLElement
      ).childNodes[0] as HTMLInputElement;
      form.value = '';
      this.onInputChange();
    }
    this.clearSelection();
    this.filterMapView();
  }

  beginMeasurement(): void {
    this.clearMeasurement();
    this.activeTool = 'measurement';
    if (this.measurement) {
      this.measurement.viewModel.start();
    }
    if (this.view) {
      this.view.ui.add(this.measurement, 'top-right');
    }
  }

  clearMeasurement(): void {
    this.activeTool = '';
    this.togglePrint(true);
    if (this.measurement != null) {
      this.measurement.viewModel.clear();
      this.view.ui.remove(this.measurement);
    }
    if (this.view) {
      this.view.activeTool = null;
      this.view.graphics.removeAll();
    }
    this.preventNewLines = false;
    this.measurementList = [];
    if (document.getElementById('instructionBox') != null) {
      document.getElementById('instructionBox').remove();
    }
    this.isRouteActive = false;
  }

  checkUnit(): void {
    const unitSelect: HTMLSelectElement = document.getElementsByClassName(
      'esri-distance-measurement-2d__units-select',
    )[0];
    if (unitSelect !== undefined) {
      unitSelect.addEventListener('change', (event) => {
        const newUnit: string = event.target.value;
        switch (newUnit) {
          case 'imperial':
            this.UnitChange('mi');
            this.measurementUnit = 'mi' as string;
            break;
          case 'metric':
            this.UnitChange('km');
            this.measurementUnit = 'km' as string;
            break;
          case 'inches':
            this.UnitChange('in');
            this.measurementUnit = 'in' as string;
            break;
          case 'feet':
            this.UnitChange('ft');
            this.measurementUnit = 'ft' as string;
            break;
          case 'yards':
            this.UnitChange('yd');
            this.measurementUnit = 'yd' as string;
            break;
          case 'miles':
            this.UnitChange('mi');
            this.measurementUnit = 'mi' as string;
            break;
          case 'nautical-miles':
            this.UnitChange('nm');
            this.measurementUnit = 'nm' as string;
            break;
          case 'us-feet':
            this.UnitChange('ft');
            this.measurementUnit = 'ft' as string;
            break;
          case 'meters':
            this.UnitChange('m');
            this.measurementUnit = 'm' as string;
            break;
          case 'kilometers':
            this.UnitChange('km');
            this.measurementUnit = 'km' as string;
            break;
          default:
            console.log('ERROR: UNIT TYPE NOT SUPPORTED');
            break;
        }
      });
    }
  }

  checkMeasurement(): void {
    this.appendMeasureIntructions();
    if (this.preventNewLines === false) {
      const MeasurementElements = document.getElementsByClassName(
        'esri-distance-measurement-2d__measurement-item-value',
      );
      if ((MeasurementElements[0] as HTMLElement) !== undefined) {
        // check measurement, add it to list of measurements
        let newMeasurement: number = +MeasurementElements[0].innerHTML
          .split(' ')[0]
          .replaceAll(',', '');
        const newUnit: string = MeasurementElements[0].innerHTML.split(' ')[1];
        // update measurement unit, if it's different we need to go back and do some conversions
        if (newUnit !== this.measurementUnit) {
          // do all those things above
          this.UnitChange(newUnit);
          // update measurementUnit
          this.measurementUnit = newUnit as string;
        }
        if (!Number.isNaN(newMeasurement)) {
          this.measurementList.forEach((oldMeasurement) => {
            newMeasurement -= oldMeasurement;
          });
          if (newMeasurement > 0) {
            this.measurementList.push(newMeasurement);
            this.newDistanceText();
          }
        }
        // update style
        const MeasureListContainer: HTMLDivElement = document.getElementsByClassName(
          'esri-distance-measurement-2d__measurement-item',
        )[0];
        MeasureListContainer.style.paddingBottom = '0px';
      } else {
        this.measurementList = [];
        this.preventNewLines = false;
      }
    }
  }

  newDistanceText(): void {
    if (this.measurementList.length === 1) {
      // Replace default 'Distance' with 'Total Distance' when we have more than one measurement
      const totalMeasurements = document.getElementsByClassName(
        'esri-distance-measurement-2d__measurement-item-title',
      )[0];
      totalMeasurements.innerHTML = 'Total Distance:';
      const measurementBox = document.getElementsByClassName(
        'esri-distance-measurement-2d__measurement-item',
      )[0];
      const segmentContainer = document.createElement('div');
      segmentContainer.id = 'segmentContainer';
      segmentContainer.className = 'pb-md-2';
      measurementBox.appendChild(segmentContainer);
    }
    if (this.measurementList.length >= 1) {
      // Add new distances to list under Total Distance
      const segmentContainer = document.getElementById('segmentContainer');
      const newSegment = document.createElement('span');
      newSegment.id = `S${this.measurementList.length}`;
      newSegment.className = 'lineSegment';
      const currMeasurement = this.measurementList[this.measurementList.length - 1].toFixed(2);
      newSegment.innerHTML = `
        <li>M${this.measurementList.length} to M${
  this.measurementList.length + 1
}:
        <b> ${currMeasurement} ${this.measurementUnit}</b></li>`;
      newSegment.style.fontSize = '90%';
      newSegment.style.listStylePosition = 'inside';
      segmentContainer.appendChild(newSegment);
      if (this.measurementList.length === 5) {
        segmentContainer.style.height = `${
          document.querySelector('#segmentContainer').offsetHeight
        }px`;
        segmentContainer.style.overflowY = 'scroll';
      }
    }
  }

  appendMeasureIntructions(): void {
    if (document.getElementById('instructionBox') == null) {
      if (
        document.getElementsByClassName(
          'esri-distance-measurement-2d__container',
        )[0] != null
      ) {
        const measurementBox = document.getElementsByClassName('esri-ui-top-right')[0];
        const instructionBox = document.createElement('div');
        instructionBox.id = 'instructionBox';
        const line1 = document.createElement('div');
        line1.className = 'lines';
        line1.innerHTML = 'Single click to create new line.';
        const line2 = document.createElement('div');
        line2.className = 'lines';
        line2.innerHTML = 'Double left click to end measurement.';
        const line3 = document.createElement('div');
        line3.className = 'lines';
        line3.innerHTML = 'Right click or Esc to clear measurements.';
        instructionBox.appendChild(line1);
        instructionBox.appendChild(line2);
        instructionBox.appendChild(line3);
        measurementBox.appendChild(instructionBox);
      }
    }
  }

  updateDistanceText(newUnit: string): void {
    for (let i = 0; i < this.measurementList.length; i += 1) {
      const currMeasurement = this.measurementList[i].toFixed(2);
      if (document.getElementById('S1') != null) {
        document.getElementById(`S${i + 1}`).innerHTML = `<li>Line ${
          i + 1
        }: <b>${currMeasurement} ${newUnit}</b></li>`;
      }
    }
  }

  UnitChange(newUnit: string): void {
    // convert from km
    if (this.measurementUnit === 'km') {
      switch (newUnit) {
        case 'm':
          for (let i = 0; i < this.measurementList.length; i += 1) {
            this.measurementList[i] *= 1000;
          }
          break;
        case 'mi':
          for (let i = 0; i < this.measurementList.length; i += 1) {
            this.measurementList[i] *= 0.62137119;
          }
          break;
        case 'in':
          for (let i = 0; i < this.measurementList.length; i += 1) {
            this.measurementList[i] *= 39370.08;
          }
          break;
        case 'ft':
          for (let i = 0; i < this.measurementList.length; i += 1) {
            this.measurementList[i] *= 3280.84;
          }
          break;
        case 'yd':
          for (let i = 0; i < this.measurementList.length; i += 1) {
            this.measurementList[i] *= 1093.613;
          }
          break;
        case 'nm':
          for (let i = 0; i < this.measurementList.length; i += 1) {
            this.measurementList[i] *= 0.5399568;
          }
          break;
        case 'km':
          break;
        default:
          console.log('NEW UNIT NOT SUPPORTED');
          break;
      }
    }

    // convert to km
    if (this.measurementUnit === 'm') {
      for (let i = 0; i < this.measurementList.length; i += 1) {
        this.measurementList[i] /= 1000;
      }
      this.measurementUnit = 'km';
      this.UnitChange(newUnit);
    }
    if (this.measurementUnit === 'mi') {
      for (let i = 0; i < this.measurementList.length; i += 1) {
        this.measurementList[i] /= 0.62137119;
      }
      this.measurementUnit = 'km';
      this.UnitChange(newUnit);
    }
    if (this.measurementUnit === 'in') {
      for (let i = 0; i < this.measurementList.length; i += 1) {
        this.measurementList[i] /= 39370.08;
      }
      this.measurementUnit = 'km';
      this.UnitChange(newUnit);
    }
    if (this.measurementUnit === 'ft') {
      for (let i = 0; i < this.measurementList.length; i += 1) {
        this.measurementList[i] /= 3280.84;
      }
      this.measurementUnit = 'km';
      this.UnitChange(newUnit);
    }
    if (this.measurementUnit === 'yd') {
      for (let i = 0; i < this.measurementList.length; i += 1) {
        this.measurementList[i] /= 1093.613;
      }
      this.measurementUnit = 'km';
      this.UnitChange(newUnit);
    }
    if (this.measurementUnit === 'nm') {
      for (let i = 0; i < this.measurementList.length; i += 1) {
        this.measurementList[i] /= 0.5399568;
      }
      this.measurementUnit = 'km';
      this.UnitChange(newUnit);
    }
    this.updateDistanceText(newUnit);
  }

  async deleteAndReassertAssets(
    featureLayer: unknown,
    newAssets: unknown,
  ): void {
    if (featureLayer != null) {
      const tempFeats = await featureLayer.queryFeatures();
      await featureLayer.applyEdits({
        deleteFeatures: tempFeats.features,
      });
      await featureLayer.applyEdits({
        addFeatures: newAssets,
      });
    }
  }

  async deleteAssets(
    featureLayer: unknown,
  ): void {
    if (featureLayer != null) {
      const tempFeats = await featureLayer.queryFeatures();
      await featureLayer.applyEdits({
        deleteFeatures: tempFeats.features,
      });
    }
  }

  async ensureDupes(overideDupes = false): void {
    if (!this.lineFeaturesDupe && this.lineSegmentFeaturesLayer != null) {
      await this.lineSegmentFeaturesLayer.queryFeatures().then((features) => {
        this.lineFeaturesDupe = features.features;
      });
    }
    if (!this.parcelFeaturesDupe && this.parcelsLayer != null) {
      await this.parcelsLayer.queryFeatures().then((features) => {
        this.parcelFeaturesDupe = features.features;
      });
    }
    if (!this.assetFeaturesDupe && this.assetFeaturesLayer != null) {
      await this.assetFeaturesLayer.queryFeatures().then((features) => {
        this.assetFeaturesDupe = features.features;
      });
    }
    if (!this.defectsFeaturesLayerDupe && this.defectsFeaturesLayer != null) {
      await this.defectsFeaturesLayer.queryFeatures().then((features) => {
        this.defectsFeaturesLayerDupe = features.features;
      });
    }
    if (overideDupes) {
      await this.lineSegmentFeaturesLayer.queryFeatures().then((features) => {
        this.lineFeaturesDupe = features.features;
      });
      await this.parcelsLayer.queryFeatures().then((features) => {
        this.parcelFeaturesDupe = features.features;
      });
      await this.assetFeaturesLayer.queryFeatures().then((features) => {
        this.assetFeaturesDupe = features.features;
      });
      await this.defectsFeaturesLayer.queryFeatures().then((features) => {
        this.defectsFeaturesLayerDupe = features.features;
      });
    }
  }

  async refilterScore(featureLayer: unknown, featureLayerDupe: unknown): void {
    // eslint-disable-next-line prefer-const
    let newFeats = [];
    if (featureLayerDupe) {
      featureLayerDupe.forEach((e) => {
        if (e.visible) {
          newFeats.push(e);
        }
      });
    }
    await this.deleteAndReassertAssets(featureLayer, newFeats);
  }

  getManholeRenderer(): any {
    const renderers = {
      score: {
        type: 'unique-value',
        field: 'score',
        defaultSymbol: {
          type: 'simple-marker',
          color: '#0C6599',
          size: 10,
          outline: {
            width: 1,
            color: 'white',
          },
        },
        uniqueValueInfos: [
          {
            value: 10,
            symbol: {
              type: 'simple-marker',
              color: {
                a: 0.1,
                r: 102,
                g: 102,
                b: 102,
              },
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 5,
            symbol: {
              type: 'simple-marker',
              color: util.getScoreColor(5),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 4,
            symbol: {
              type: 'simple-marker',
              color: util.getScoreColor(4),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 3,
            symbol: {
              type: 'simple-marker',
              color: util.getScoreColor(3),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 2,
            symbol: {
              type: 'simple-marker',
              color: util.getScoreColor(2),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 1,
            symbol: {
              type: 'simple-marker',
              color: util.getScoreColor(1),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 0,
            symbol: {
              type: 'simple-marker',
              color: util.getScoreColor(0),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
        ],
      },
      slratScore: {
        type: 'unique-value',
        field: 'slratScore',
        defaultSymbol: {
          type: 'simple-marker',
          color: {
            a: 0.1,
            r: 102,
            g: 102,
            b: 102,
          },
          size: 10,
          outline: {
            width: 1,
            color: 'white',
          },
        },
      },
      collection: {
        type: 'unique-value',
        field: 'taskResult',
        defaultSymbol: {
          type: 'simple-marker',
          color: '#0C6599',
          size: 10,
          outline: {
            width: 1,
            color: 'white',
          },
        },
        uniqueValueInfos: [
          {
            value: 'Succeeded',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Succeeded'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Incomplete',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Incomplete'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Failed',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Failed'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Could Not Locate',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Could Not Locate'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Could Not Access',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Could Not Access'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Does Not Exist',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Does Not Exist'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Complete',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Complete'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Component Pending',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Component Pending'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Traffic Prevented Access',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Traffic Prevented Access'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Located But Buried',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Located But Buried'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Unable To Open Access Point',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Unable To Open Access Point'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Access Point Severely Surcharged Too Much Debris',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor(
                'Access Point Severely Surcharged Too Much Debris',
              ),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Inspection Pending',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Inspection Pending'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'Unknown',
            symbol: {
              type: 'simple-marker',
              color: util.getCollectionColor('Unknown'),
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
          {
            value: 'hidden',
            symbol: {
              type: 'simple-marker',
              color: {
                a: 0.2,
                r: 102,
                g: 102,
                b: 102,
              },
              size: 10,
              outline: {
                width: 1,
                color: 'white',
              },
            },
          },
        ],
      },
    };

    return renderers[this.selectedFilterLayer] != null
      ? renderers[this.selectedFilterLayer] : renderers.score;
  }

  getLineSegmentRenderer(): any {
    const collections = [
      'Incomplete',
      'Succeeded',
      'Failed',
      'Could Not Locate',
      'Could Not Access',
      'Does Not Exist',
      'Complete',
      'Traffic Prevented Access',
      'Located But Buried',
      'Unable To Open Access Point',
      'Access Point Severely Surcharged Too Much Debris',
      'Inspection Pending',
      'Unknown',
      'hidden',
    ];
    const lineWidth = 3;
    const renderers = {
      score: {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'hasFlowData',
        defaultSymbol: {
          type: 'simple-line',
          color: '#0C6599',
          width: lineWidth,
        },
        uniqueValueInfos: [
          {
            value: '1',
            symbol: {
              type: 'cim', // autocasts as CIMSymbol
              data: {
                type: 'CIMSymbolReference',
                symbol: {
                  type: 'CIMLineSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidStroke',
                      enable: true,
                      color: '#0C6599',
                      width: lineWidth,
                    },
                    this.getLineSegmentArrowSymbol('#0C6599'),
                  ],
                },
              },
            },
          },
        ],
        visualVariables: [
          {
            type: 'color',
            field: 'score',
            stops: [
              {
                value: -1,
                color: util.getScoreColor(-1),
              },
              {
                value: 0,
                color: util.getScoreColor(0),
              },
              {
                value: 1,
                color: util.getScoreColor(1),
              },
              {
                value: 2,
                color: util.getScoreColor(2),
              },
              {
                value: 3,
                color: util.getScoreColor(3),
              },
              {
                value: 4,
                color: util.getScoreColor(4),
              },
              {
                value: 5,
                color: util.getScoreColor(5),
              },
              {
                value: 10,
                color: {
                  a: 0.1,
                  r: 102,
                  g: 102,
                  b: 102,
                },
              },
            ],
          },
        ],
      },
      slratScore: {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'hasFlowData',
        defaultSymbol: {
          type: 'simple-line',
          color: '#0C6599',
          width: lineWidth,
        },
        uniqueValueInfos: [
          {
            value: '1',
            symbol: {
              type: 'cim', // autocasts as CIMSymbol
              data: {
                type: 'CIMSymbolReference',
                symbol: {
                  type: 'CIMLineSymbol',
                  symbolLayers: [
                    {
                      type: 'CIMSolidStroke',
                      enable: true,
                      color: '#0C6599',
                      width: lineWidth,
                    },
                    this.getLineSegmentArrowSymbol('#0C6599'),
                  ],
                },
              },
            },
          },
        ],
        visualVariables: [
          {
            type: 'color',
            field: 'slratScore',
            stops: [
              {
                value: -1,
                color: util.getSlratScoreColor(-1),
              },
              {
                value: 0,
                color: util.getSlratScoreColor(-1),
              },
              {
                value: 1,
                color: util.getSlratScoreColor(1),
              },
              {
                value: 3,
                color: util.getSlratScoreColor(1),
              },
              {
                value: 4,
                color: util.getSlratScoreColor(4),
              },
              {
                value: 6,
                color: util.getSlratScoreColor(4),
              },
              {
                value: 7,
                color: util.getSlratScoreColor(7),
              },
              {
                value: 10,
                color: util.getSlratScoreColor(7),
              },
              {
                value: 11,
                color: {
                  a: 0.1,
                  r: 102,
                  g: 102,
                  b: 102,
                },
              },
            ],
          },
        ],
      },
      collection: {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'taskResult',
        field2: 'hasFlowData',
        fieldDelimiter: ', ',
        defaultSymbol: {
          type: 'simple-line',
          color: '#0C6599',
          width: lineWidth,
        },
        uniqueValueInfos: this.getGeneratedLSCollectionUniqueValueInfo(
          lineWidth,
          collections,
        ),
        visualVariables: [
          {
            type: 'color',
            field: 'taskResult',
            stops: collections.map((c) => ({
              value: c,
              color: util.getCollectionColor(c),
            })),
          },
        ],
      },
    };

    return renderers[this.selectedFilterLayer] != null
      ? renderers[this.selectedFilterLayer] : renderers.score;
  }

  getGeneratedLSCollectionUniqueValueInfo(lineWidth: number, values: string[]): any[] {
    const returnValue = [];

    values.forEach((value) => {
      const color = '#0C6599';
      returnValue.push({
        value: `${value}, 0`,
        symbol: {
          type: 'cim',
          data: {
            type: 'CIMSymbolReference',
            symbol: {
              type: 'CIMLineSymbol',
              symbolLayers: [
                {
                  type: 'CIMSolidStroke',
                  enable: true,
                  color,
                  width: lineWidth,
                },
              ],
            },
          },
        },
      });
      returnValue.push({
        value: `${value}, 1`,
        symbol: {
          type: 'cim',
          data: {
            type: 'CIMSymbolReference',
            symbol: {
              type: 'CIMLineSymbol',
              symbolLayers: [
                {
                  type: 'CIMSolidStroke',
                  enable: true,
                  color,
                  width: lineWidth,
                },
                this.getLineSegmentArrowSymbol(color),
              ],
            },
          },
        },
      });
    });

    return returnValue;
  }

  getLineSegmentArrowSymbol(color = '#0C6599'): any {
    return {
      // arrow symbol
      type: 'CIMVectorMarker',
      enable: true,
      size: 12,
      markerPlacement: {
        type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
        endings: 'WithMarkers',
        placementTemplate: [30], // determines space between each arrow
        angleToLine: true,
      },
      frame: {
        xmin: -5,
        ymin: -5,
        xmax: 5,
        ymax: 5,
      },
      markerGraphics: [
        {
          type: 'CIMMarkerGraphic',
          geometry: {
            rings: [
              [
                [-8, -5.47],
                [-8, 5.6],
                [1.96, -0.03],
                [-8, -5.47],
              ],
            ],
          },
          symbol: {
            // black fill for the arrow symbol
            type: 'CIMPolygonSymbol',
            symbolLayers: [
              {
                type: 'CIMSolidFill',
                enable: true,
                color,
              },
            ],
          },
        },
      ],
    };
  }

  getManholeInspectionsRenderer(): any {
    return {
      type: 'simple',
      symbol: {
        type: 'simple-marker',
        color: 'grey',
        size: 10,
        outline: {
          width: 1,
          color: 'white',
        },
      },
    };
  }

  getLineSegmentInspectionsRenderer(): any {
    return {
      type: 'unique-value', // autocasts as UniqueValueRenderer
      field: 'hasFlowData',
      defaultSymbol: {
        type: 'simple-line',
        color: '#0C6599',
        width: 2,
      },
      uniqueValueInfos: [
        {
          value: '1',
          symbol: {
            type: 'cim', // autocasts as CIMSymbol
            data: {
              type: 'CIMSymbolReference',
              symbol: {
                type: 'CIMLineSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    color: '#0C6599',
                    width: 3,
                  },
                  this.getLineSegmentArrowSymbol('#0C6599'),
                ],
              },
            },
          },
        },
      ],
      visualVariables: [
        {
          type: 'color',
          field: 'hasFlowData',
          stops: [
            {
              value: 0,
              color: '#0C6599',
            },
            {
              value: 1,
              color: '#0C6599',
            },
          ],
        },
      ],
    };
  }

  getParcelRenderer(): any {
    const lineWidth = 3;
    const renderers = {
      score: {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'score',
        defaultSymbol: {
          type: 'simple-fill',
          color: [12, 100, 151, 0.2], // #0C6599 with alpha
          outline: {
            color: '#0C6599',
            width: lineWidth,
          },
        },
        uniqueValueInfos: [
          {
            value: 0,
            symbol: {
              type: 'simple-fill',
              color: {
                a: 0.05,
                r: 102,
                g: 102,
                b: 102,
              },
              outline: {
                width: lineWidth,
                color: {
                  a: 0.1,
                  r: 102,
                  g: 102,
                  b: 102,
                },
              },
              style: 'solid',
            },
          },
        ],
      },
    };

    return renderers[this.selectedFilterLayer] != null
      ? renderers[this.selectedFilterLayer] : renderers.score;
  }

  async toggleLayer(layer: { name: string; active: boolean }): void {
    const manholeRenderer = {
      type: 'simple',
      symbol: {
        type: 'simple-marker',
        color: '#76CDFF',
        size: 10,
        outline: {
          width: 1,
          color: 'white',
        },
      },
    };
    const lineSegmentRenderer = {
      type: 'unique-value', // autocasts as UniqueValueRenderer
      field: 'hasFlowData',
      defaultSymbol: {
        type: 'simple-line',
        color: '#76CDFF',
        width: 2,
      },
      uniqueValueInfos: [
        {
          value: '1',
          symbol: {
            type: 'cim', // autocasts as CIMSymbol
            data: {
              type: 'CIMSymbolReference',
              symbol: {
                type: 'CIMLineSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    color: '#76CDFF',
                    width: 3,
                  },
                  this.getLineSegmentArrowSymbol('#76CDFF'),
                ],
              },
            },
          },
        },
      ],
      visualVariables: [
        {
          type: 'color',
          field: 'hasFlowData',
          stops: [
            {
              value: 0,
              color: '#76CDFF',
            },
            {
              value: 1,
              color: '#76CDFF',
            },
          ],
        },
      ],
    };
    let inspVisible = false;

    switch (layer.name) {
      case 'Manholes':
        if (this.assetFeaturesLayer) {
          this.assetFeaturesLayer.visible = layer.active;
        }
        break;
      case 'Line Segments':
        if (this.lineSegmentFeaturesLayer) {
          this.lineSegmentFeaturesLayer.visible = layer.active;
        }
        break;
      case 'Parcels':
        if (this.parcelsLayer) {
          this.parcelsLayer.visible = layer.active;
        }
        break;
      case 'lineSegmentFlowDirection':
        this.updateGraphics();
        break;
      case 'Map Pages':
        if (this.mapPagesFeaturesLayer) {
          this.mapPagesFeaturesLayer.visible = !this.mapPagesFeaturesLayer.visible;
        }
        break;
      case 'GIS Inspections':
        // Toggle manhole inspections visibility
        if (this.manholeInspectionsLayer) {
          this.manholeInspectionsLayer.visible = !this.manholeInspectionsLayer.visible;
        }

        // Toggle line segment inspections visibility
        if (this.lineSegmentInspectionsLayer) {
          this.lineSegmentInspectionsLayer.visible = !this.lineSegmentInspectionsLayer.visible;
        }

        inspVisible = this.manholeInspectionsLayer.visible && this.manholeInspectionsLayer.visible;

        // Update customer data renderer
        if (this.assetFeaturesLayer) {
          this.assetFeaturesLayer.renderer = inspVisible
            ? manholeRenderer
            : this.getManholeRenderer();
          this.assetFeaturesLayer.refresh();
        }
        this.lineSegmentFeaturesLayer.renderer = inspVisible
          ? lineSegmentRenderer
          : this.getLineSegmentRenderer();
        this.lineSegmentFeaturesLayer.refresh();
        break;
      case 'Laterals':
        if (this.lateralFeaturesLayer) {
          this.lateralFeaturesLayer.visible = !this.lateralFeaturesLayer.visible;
        }
        break;
      case 'lateralFlowDirection': {
        if (layer.active) {
          const lateralFeaturesLayer = this.infoOptions.find((o) => o.name === 'Laterals');
          if (lateralFeaturesLayer && !lateralFeaturesLayer.active) {
            // this.toggleLayer(lateralFeaturesLayer);
          }
        }
        const lateralRenderer = {
          type: 'unique-value', // autocasts as UniqueValueRenderer
          field: 'type',
          defaultSymbol: {
            type: 'simple-line', // default SimpleLineSymbol
          },
          uniqueValueInfos: [
            {
              value: 'Lateral',
              symbol: {
                type: 'cim', // autocasts as CIMSymbol
                data: {
                  type: 'CIMSymbolReference',
                  symbol: {
                    type: 'CIMLineSymbol',
                    symbolLayers: [
                      {
                        // black 1px line symbol
                        type: 'CIMSolidStroke',
                        enable: true,
                        width: 1,
                        color: [0, 0, 0, 255],
                      },
                    ],
                  },
                },
              },
            },
          ],
        };

        const arrowSymbol = {
          type: 'CIMVectorMarker',
          enable: true,
          size: 3,
          markerPlacement: {
            // places same size markers along the line
            type: 'CIMMarkerPlacementAlongLineSameSize',
            endings: 'WithMarkers',
            // determines space between each arrow
            placementTemplate: [40],
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3,
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -5.47],
                    [-8, 5.6],
                    [1.96, -0.03],
                    [-8, -5.47],
                  ],
                ],
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill',
                    enable: true,
                    color: [0, 0, 0, 255],
                  },
                ],
              },
            },
          ],
        };

        if (this.lateralFeaturesLayer) {
          if (layer.active) {
            lateralRenderer.uniqueValueInfos[0].symbol.data.symbol.symbolLayers.push(
              arrowSymbol,
            );
          }
          this.lateralFeaturesLayer.renderer = lateralRenderer;
          this.lateralFeaturesLayer.refresh();
        }
        break;
      }
      default:
        break;
    }
  }

  layerOptionsToggle(e: MouseEvent): void {
    if (
      (e.target as HTMLElement).closest('.optionsCard') === null
      && this.isOptionsActive
    ) {
      this.isOptionsActive = !this.isOptionsActive;
      window.removeEventListener('mousedown', this.layerOptionsToggle);
    } else if (!this.isOptionsActive) {
      this.isOptionsActive = !this.isOptionsActive;
      window.addEventListener('mousedown', this.layerOptionsToggle);
    }
  }

  updateSearchableFeatures(searchableGraphics: never[]): void {
    // update feature layer with new graphics
    if (!this.searchEnabled) return;
    if (this.FeatureLayer != null) {
      this.searchableFeaturesLayer = new this.FeatureLayer({
        title: 'SearchableAssets',
        geometryType: 'point',
        objectIdField: 'OBJECTID',
        displayField: 'assetName',
        source: searchableGraphics,
        spatialReference: { wkid: 4326 },
        fields: [
          {
            name: 'OBJECTID',
            alias: 'oid',
            type: 'global-id',
          },
          {
            name: 'assetName',
            alias: 'assetName',
            type: 'string',
          },
        ],
        popupEnabled: false,
      });

      // update searh widget
      this.searchWidget.sources = [
        {
          layer: this.searchableFeaturesLayer,
          searchFields: ['assetName'],
          displayFields: ['assetName'],
          name: 'Assets',
          placeholder: 'asset list',
        },
        {
          locator: new this.Locator(
            '//geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
          ),
          name: 'ArcGIS World Geocoding Service',
          placeholder: 'Find address or place',
          singleLineFieldName: 'SingleLine',
        },
      ];
    }
  }

  getSearchableGraphics(): never[] {
    let retGraphics = [];

    if (this.Graphic != null) {
      this.mapData.forEach((feature: AssetData) => {
        const attributes = {
          guid: feature.guid,
          longitude: feature.location[0].longitude,
          latitude: feature.location[0].latitude,
          assetName: feature.name,
        };

        // continue if we got bad values
        // TODO: decouple search box from graphics
        if (attributes.longitude == null || attributes.latitude == null) {
          if (feature.location[0] == null
            || feature.location[0].upstream == null
            || feature.location[0].upstream.latitude == null
            || feature.location[0].upstream.longitude == null
            || feature.location[0].downstream == null
            || feature.location[0].downstream.latitude == null
            || feature.location[0].downstream.longitude == null) {
            return;
          }
          attributes.longitude = (feature.location[0].downstream.longitude
            + feature.location[0].upstream.longitude) / 2;
          attributes.latitude = (feature.location[0].downstream.latitude
            + feature.location[0].upstream.latitude) / 2;
        } else {
          // Average out the points
          let longitudeTotal = 0;
          let latitudeTotal = 0;
          let count = 0;
          feature.location.forEach((loc: Location) => {
            if (loc.longitude != null && loc.latitude != null) {
              count += 1;
              longitudeTotal += loc.longitude;
              latitudeTotal += loc.latitude;
            }
          });
          if (count !== 0) {
            attributes.longitude = longitudeTotal / count;
            attributes.latitude = latitudeTotal / count;
          }
        }

        const point = {
          type: 'point',
          longitude: attributes.longitude,
          latitude: attributes.latitude,
        };

        const pointGraphic = new this.Graphic({
          geometry: point,
          attributes,
        });

        retGraphics.push(pointGraphic);
      });
    }

    // Get unique values by attribute's guid
    retGraphics = [...new Map(
      retGraphics.map((item) => [item.attributes.guid, item]),
    ).values()];

    return retGraphics;
  }

  /*
  Desc: If extent is empty response fills extent.
    If extent contains info it expands to the greatest extnets, min vals go low, max vals go high
  Params:
    extent: object to be filled
    response: extent grabbed from esri
  */
  fillExtents(extent: unknown, response: unknown): void {
    if (extent != null) {
      // eslint-disable-next-line no-param-reassign
      if (extent.xmin > response.xmin) {
        extent.xmin = response.xmin;
      }
      // eslint-disable-next-line no-param-reassign
      if (extent.ymin > response.ymin) {
        extent.ymin = response.ymin;
      }
      // eslint-disable-next-line no-param-reassign
      if (extent.xmax < response.xmax) {
        extent.xmax = response.xmax;
      }
      // eslint-disable-next-line no-param-reassign
      if (extent.ymax < response.ymax) {
        extent.ymax = response.ymax;
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      extent = response;
    }
    this.view.goTo(extent)
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.error(error);
        }
      });
  }

  closePopout(): void {
    this.$emit('update-selected-asset', { selectedAssets: [], multiSelect: false });
    this.closeState = false;
    this.isRouteActive = false;
  }

  // This updates how the assets are rendered based on the mapData prop,
  // setting the manhole and line segment features to colored or greyed out
  // based on the assets visible value in the mapData prop.
  async updateGraphics(): void {
    const layers = [this.assetFeaturesLayer, this.lineSegmentFeaturesLayer, this.parcelsLayer];

    // eslint-disable-next-line no-restricted-syntax
    for (const layer of layers) {
      if (layer && layer !== null) {
        // eslint-disable-next-line no-await-in-loop
        const query = await layer.queryFeatures();
        layer.applyEdits({
          deleteFeatures: query.features,
        }).then(async () => {
          layer.refresh();
        });
      }
    }
    await this.dataSetupManholesLayer();
    await this.dataSetupLineSegmentsLayer();
    await this.dataSetupLateralFeaturesLayer();
    await this.dataSetupManholeInspectionsLayer();
    await this.dataSetupLineSegmentInspectionsLayer();
    await this.dataSetupParcelsLayer();
  }

  mapPageLoad(): void {
    this.setupMapPages();
    this.map.add(this.mapPagesFeaturesLayer);
    this.map.reorder(this.mapPagesFeaturesLayer, 0);
    // Set Map Page in infoObject to true
    const mapOption = this.infoOptions.find((o) => o.name === 'Map Pages');
    mapOption.exists = true;
    mapOption.active = true;
  }

  zoomToAsset(asset: AssetRef): void {
    let featuresLayer = null;
    let featuresLayerView = null;

    switch (asset.type) {
      case 'Manhole':
        featuresLayer = this.assetFeaturesLayer;
        featuresLayerView = this.assetLayerView;
        this.view.zoom = 20;
        break;
      case 'Line Segment':
        featuresLayer = this.lineSegmentFeaturesLayer;
        featuresLayerView = this.lineSegmentLayerView;
        break;
      case 'Parcel':
        featuresLayer = this.parcelsLayer;
        featuresLayerView = this.parcelsLayerView;
        break;
      case 'Lateral':
        featuresLayer = this.lateralFeaturesLayer;
        featuresLayerView = this.lateralLayerView;
        break;
      default:
        break;
    }

    const query = featuresLayer.createQuery();
    query.where = `guid='${asset.guid}'`;

    if (featuresLayer != null) {
      featuresLayer.queryFeatures(query).then((queryResult) => {
        this.highlighted = featuresLayerView.highlight(queryResult.features[0]);
        if (this.assetZoomOveride) {
          // Manually set the center
          this.setCenter(asset, queryResult);
        } else {
          this.view
            .goTo(queryResult.features)
            .then(() => {
              this.filterMapView();
              setTimeout(() => {
                this.screenshot();
              }, '3000');
            })
            .catch((e) => {
              console.error(e);
            });
        }
      });
    }
  }

  setCenter(asset: AssetRef, queryResult: unknown): void {
    const queryGeometry = queryResult.features[0].geometry;
    if (asset.type === 'Manhole') {
      this.view.center = [queryGeometry.x, queryGeometry.y];
    } else if (asset.type === 'Line Segment' || asset.type === 'Lateral') {
      // Get center of line to set center of view
      const pointA = queryGeometry.paths[0][0];
      const pointB = queryGeometry.paths[0][1];
      this.view.center = [
        (pointA[0] + pointB[0]) / 2,
        (pointA[1] + pointB[1]) / 2,
      ];
    } else if (asset.type === AssetType.PARCEL) {
      const pointA = (queryGeometry.paths as number[][])
        .reduce((accumulator, currentValue) => accumulator + currentValue[0], 0)
        / queryGeometry.paths.length;
      const pointB = (queryGeometry.paths as number[][])
        .reduce((accumulator, currentValue) => accumulator + currentValue[1], 0)
        / queryGeometry.paths.length;
      this.view.center = [
        pointA,
        pointB,
      ];
    } else {
      return;
    }
    this.view.zoom = 20;
  }

  async zoomToCity(): void {
    if (this.cities?.length !== 1) {
      return;
    }
    const response = await fetch(`https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=pjson&address=
      ${this.cities[0]}, ${this.states[0]}&token=${this.$esriApiKey}`, {
      method: 'POST',
    });
    const event = await (response as Response).json();
    if (event.candidates instanceof Array && event.candidates.length > 0) {
      this.view.goTo({
        target: [event.candidates[0].location.x, event.candidates[0].location.y],
        zoom: 12,
      })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.error(error);
          }
        });
    }
  }

  loadViews(): void {
    this.view.whenLayerView(this.assetFeaturesLayer).then((assetLayerView) => {
      this.assetLayerView = assetLayerView;
    });

    this.view
      .whenLayerView(this.lineSegmentFeaturesLayer)
      .then((lineSegmentLayerView) => {
        this.lineSegmentLayerView = lineSegmentLayerView;
      });

    this.view
      .whenLayerView(this.parcelsLayer)
      .then((parcelsLayerView) => {
        this.parcelsLayerView = parcelsLayerView;
      });

    this.view
      .whenLayerView(this.lateralFeaturesLayer)
      .then((lateralLayerView) => {
        // Lateral layer visibility should default to false
        this.lateralFeaturesLayer.visible = false;
        this.lateralLayerView = lateralLayerView;
      });

    this.view.whenLayerView(this.assetFeaturesLayer).then((layerView) => {
      this.$emit('loaded');
      this.WatchUtils.whenFalseOnce(layerView, 'updating', () => {
        this.$emit('loadSelectedAsset');
      });
    });

    this.view.whenLayerView(this.assetFeaturesLayer).then(() => {
      const esriViewSurface = document.getElementsByClassName('esri-view-surface')[0];

      if (esriViewSurface != null) {
        let moved = false;

        // Event listener for mouse move added to prevent unwanted points being added
        // when clicking and dragging the map
        esriViewSurface.addEventListener('mousedown', () => {
          moved = false;
        });
        esriViewSurface.addEventListener('mousemove', () => {
          moved = true;
        });
        esriViewSurface.addEventListener('mouseup', (e) => {
          // handle standard clicks
          if (e.button === 0 && !moved) this.checkMeasurement();
          if (e.button === 2) {
            this.clearMeasurement();
          }
        });

        const newMeasureBtn: HTMLButtonElement = document.getElementsByClassName(
          'esri-distance-measurement-2d__clear-button',
        )[0];

        if (newMeasureBtn != null && this.preventNewLines) {
          newMeasureBtn.addEventListener('click', () => {
            this.measurementList = [];
            this.preventNewLines = false;
            this.clearMeasurement();
            this.beginMeasurement();
            this.activeTool = 'measurement';
          });
        }
      }
    });
    this.view
      .whenLayerView(this.assetFeaturesLayer)
      .then(this.onSearchBarLoad);
  }

  initialMapTableLoad(): void {
    if (!this.selectedMapAssetGuid) {
      let resObj: unknown;
      if (this.assetFeaturesLayer) {
        this.assetFeaturesLayer
          .when(() => this.assetFeaturesLayer.queryExtent())
          .then((response) => {
            if (response.extent
                && response.extent.center.latitude < 90
                && response.extent.center.latitude > -90
                && response.extent.center.longitude < 180
                && response.extent.center.longitude > -180) {
              this.fillExtents(resObj, response.extent);
              resObj = response.extent;
              // find and zoom to city instead
            } else if (!this.zoomToCityFlag) {
              this.zoomToCityFlag = true;
              // grab city and attempt to use locator and goto
              this.zoomToCity();
            }
          });
      }

      if (this.lineSegmentFeaturesLayer) {
        this.lineSegmentFeaturesLayer
          .when(() => this.lineSegmentFeaturesLayer.queryExtent())
          .then((response) => {
            if (response.extent
                && response.extent.center.latitude < 90
                && response.extent.center.latitude > -90
                && response.extent.center.longitude < 180
                && response.extent.center.longitude > -180) {
              this.fillExtents(resObj, response.extent);
              // find and zoom to city instead
            } else if (!this.zoomToCityFlag) {
              this.zoomToCityFlag = true;
              // grab city and attempt to use locator and goto
              this.zoomToCity();
            }
          });
      }
    }
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  async screenshot(): Promise<void> {
    const scrShot = await this.view.takeScreenshot({ format: 'jpg', quality: 85 });
    this.setAssetScreenshotUrl(scrShot.dataUrl);
  }

  async exportGeoJson(): Promise<void> {
    const retVal: GeoJsonJobObject = {
      projectNames: [],
      emailOut: this.layersExportEmail,
      layers: [],
    };

    const featureLayers = [
      { layerName: 'ManholeCustomerData', layer: this.assetFeaturesLayer },
      { layerName: 'Laterals', layer: this.lateralFeaturesLayer },
      { layerName: 'LineSegmentsCustomerData', layer: this.lineSegmentFeaturesLayer },
      { layerName: 'ManholeInspectionData', layer: this.manholeInspectionsLayer },
      { layerName: 'LineSegmentInspectionData', layer: this.lineSegmentInspectionsLayer },
      { layerName: 'ParcelsData', layer: this.parcelsLayer },
    ];

    const selectedLayers = [];

    this.layersExportSelection.forEach((layerName) => {
      selectedLayers.push(featureLayers.find((l) => l.layerName === layerName));
    });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectedLayers.length; i++) {
      const geoJSONFeatureCollection = {
        type: 'FeatureCollection',
        features: [],
      };
      const featureLayer = selectedLayers[i].layer;
      // eslint-disable-next-line no-await-in-loop
      const assetFeatures = (await featureLayer.queryFeatures()).features;
      assetFeatures.forEach((asset) => {
        const geoJSONFeature = this.arcgisToGeoJSON(asset);
        geoJSONFeatureCollection.features.push(geoJSONFeature);
      });
      if (geoJSONFeatureCollection.features.length > 0) {
        retVal.layers.push(
          {
            layerName: selectedLayers[i].layerName,
            layerGeoJson: JSON.stringify(geoJSONFeatureCollection),
          },
        );
      }
    }
    retVal.projectNames = this.projectHeaders.names;
    retVal.exportType = this.layersExportType;
    this.postGeoJson(retVal);
    this.snackbarMessage = 'The export has been initiated. A link to the files will be sent to the indicated email on export success.';
    this.snackbarColor = '#0c6599';
    this.showLayersExport = false;
    this.showSnackbar = true;
  }

  openRouteInterface(item: string): void {
    if (this.selectedAssets.length === 0) {
      // TODO: add popup telling user to select assets
      console.warn('please select assets');
      return;
    }
    this.routeAssetType = item;
    this.isRouteActive = true;
  }

  toggleRouteInterface(state: boolean): void {
    this.isRouteActive = state;
  }

  removeAsset(asset: AssetRef): void {
    const index = this.selectedAssets.findIndex((sa) => sa.guid === asset.guid);
    this.selectedAssets.splice(index, 1);
  }

  postResult(result: string): void {
    // empty string means success
    if (result === '') {
      this.snackbarColor = 'green';
      this.snackbarMessage = 'Route Added';
      this.selectedAssets = [];
      this.selectedAssetGraphics = [];
      this.clearHighlights();
    } else {
      this.snackbarColor = '#e61e25';
      this.snackbarMessage = 'Error Adding Route';
    }
    this.showSnackbar = true;
  }

  showChromeFlagWarningMessage(): void {
    this.snackbarMessage = 'To use all map features please enable all settings.';
    this.snackbarMessage += ' If using chrome, go to the url "chrome://flags/#use-angle" and set the "Choose ANGLE graphics backend" setting to "OpenGL".';
    this.showedChromeGLError = true;
    this.showSnackbar = true;
    this.snackbarColor = 'warning';
    this.snackbarTimeout = -1; // Show indefinitely
  }

  closeSnackBar(): void {
    this.showSnackbar = false;
    this.snackbarColor = '';
    this.snackbarMessage = '';
    this.snackbarTimeout = 5000;
  }
}
