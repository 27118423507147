































import { RoutingData } from '@/store/routing/types';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import IntegrityTable, {
  AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDate,
} from '@/components/IntegrityTable/IntegrityTable.vue';
import {
  NODEITEM_LINESEGMENT_GUID,
  NODEITEM_MANHOLE_GUID,
} from '@/common/Constants';
import { namespace } from 'vuex-class';
import { uuid } from 'vue-uuid';
import IntegrityDelete from '../../IntegrityDelete/IntegrityDelete.vue';

const routingModule = namespace('routing');

@Component({
  components: {
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class NewRoutingAssignmentTable extends Vue {
  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @routingModule.State('routingLoading') routingLoading: boolean | undefined;

  @PropSync('selectedItemsSync', { default: () => ([]) }) selectedItems:
    | RoutingData[]
    | undefined;

  @Prop() activeTab!: number;

  @Prop() search!: string;

  headers = [
    {
      text: 'Route Name',
      value: 'routeName',
      align: 'start',
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: 'Task Types',
      value: 'taskType',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Next Due Date',
      value: 'nextDueDate',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Created By',
      value: 'createdBy',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Created Date',
      value: 'createdDate',
      align: 'start',
      filterable: true,
    },
    {
      text: 'Description',
      value: 'description',
      align: 'start',
      filterable: true,
    },
  ];

  matchFilters = [
    {
      header: 'routeName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'taskType',
      type: 'array',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'nextDueDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'createdBy',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'createdDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'description',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'createdDate',
          functionVariables: [processDate.toString()],
          fillFunction: function fillCreatedDate(
            item: RoutingData,
            processDateFunctionString: string,
          ): string {
            // eslint-disable-next-line no-new-func
            return new Function(`return ${processDateFunctionString};`)()(item.createdDate);
          },
        },
        {
          headerValue: 'nextDueDate',
          functionVariables: [processDate.toString()],
          fillFunction: function fillNextDueDate(
            item: RoutingData,
            processDateFunctionString: string,
          ): string {
            // eslint-disable-next-line no-new-func
            return new Function(`return ${processDateFunctionString};`)()(item.nextDueDate);
          },
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [this.activeTab, NODEITEM_MANHOLE_GUID, NODEITEM_LINESEGMENT_GUID],
        filterFunction: function isManhole(item, activeTab, manholeNodeItem, lineSegmentNodeItem) {
          if (activeTab === 2) {
            return item.categoryItemGuid === manholeNodeItem;
          }
          return item.categoryItemGuid === lineSegmentNodeItem;
        },
      },
    ];
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  getRouteColor(color: string): string | undefined {
    const hexReg = /^#([0-9a-f]{3,4}){1,2}$/i;
    if (color == null || color === '') {
      return '#FFFFFFFF';
    }
    if (hexReg.test(color)) {
      return color;
    }
    if (hexReg.test(`#${color}`)) {
      return `#${color}`;
    }
    return '#FFFFFFFF';
  }

  get filteredRouteData(): RoutingData[] {
    if (!this.routingData) {
      return undefined;
    }
    return this.routingData;
  }
}
