

















import { DataTableHeader } from '@/components/AssetTable/types';
import { AssetData } from '@/types';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import IntegrityTable, {
  FillFunctionContainer,
} from '@/components/IntegrityTable/IntegrityTable.vue';
import { uuid } from 'vue-uuid';

@Component({
  components: {
    IntegrityTable,
  },
})
export default class NewAssignmentTable extends Vue {
  @PropSync('selectedAssetsSync', { default: () => [] })
  selectedAssets: AssetData[];

  @Prop() tableData!: AssetData[];

  @Prop() activeTabString!: string;

  @Prop() search!: string;

  get tableHeaders(): DataTableHeader[] {
    return this.activeTabString === 'Manhole'
      ? this.manholeHeaders
      : this.lineSegmentHeaders;
  }

  get matchFilters(): any {
    return this.activeTabString === 'Manhole'
      ? this.matchFiltersManhole
      : this.matchFiltersPipe;
  }

  get tabData(): AssetData[] {
    return this.tableData.filter((x) => x.type === this.activeTabString);
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [],
    };
    return returnValue;
  }

  manholeHeaders = [
    {
      text: 'Asset ID',
      value: 'name',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Map Page',
      value: 'mapPage',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Basin',
      value: 'basin',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Batch Number',
      value: 'batchNumber',
      sortable: true,
      filterable: true,
    },
  ];

  lineSegmentHeaders = [
    {
      text: 'Asset ID',
      value: 'name',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Map Page',
      value: 'mapPage',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Basin',
      value: 'basin',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Batch Number',
      value: 'batchNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Pipe Size',
      value: 'pipeSize',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Upstream Manhole',
      value: 'upstreamId',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Downstream Manhole',
      value: 'downstreamId',
      sortable: true,
      filterable: true,
    },
  ];

  matchFiltersManhole = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'mapPage',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'basin',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'batchNumber',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
  ];

  matchFiltersPipe = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'mapPage',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'basin',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'batchNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'pipeSize',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'upstreamId',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
    {
      header: 'downstreamId',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
    },
  ];
}
