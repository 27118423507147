
















































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import { StructureInspectionReportFields } from './types';
import ReportInterface, {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';
import ReportDateHeaderInput from '../ReportHelpers/ReportInputHelpers/ReportDateHeaderInput.vue';
import StructureInspectionReportHeader from './StructureInspectionReportHeader.vue';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    ReportAssignedToDropDownInput,
    ReportRadioRowInput,
    ReportDateHeaderInput,
    StructureInspectionReportHeader,
  },
})
export default class StructureInspectionReport extends ReportBodyMixin {
  // #region Abstract fields
  workOrderData: StructureInspectionReportFields | any = {};

  workOrderFields = [
    { name: 'workDescription', value: 'Description' },
    { name: 'specialInstructions', value: 'SpecialInstruction' },
    { name: 'streetLocation', value: 'Street' },
    { name: 'crossStreet', value: 'CrossStreet' },
    { name: 'structureType', value: 'StructureType' },
    { name: 'specialConditions', value: 'SpecialConditions' },
    { name: 'conditionsGFP', value: 'ConditionGFP' },
    {
      name: 'followUpClean',
      value: 'FollowUp_Clean',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'followUpRepair',
      value: 'FollowUp_RepairRehab',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'followUpReInspect',
      value: 'FollowUp_ReInspect',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'followUpOther',
      value: 'FollowUp_Other',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'followUpNone',
      value: 'FollowUp_None',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'inspectionComments', value: 'Comments' },
  ];

  // #endregion

  // #region Local variables and functions

  observedConditionsOptions = ['Good', 'Fair', 'Poor'];

  changeOption(itemVal: string, option: string | boolean): void {
    this.$set(this.workOrderData, itemVal, option);
  }

  get isNoneSelected(): boolean {
    return this.workOrderData.followUpNone === true;
  }

  get isFollowUpSelected(): boolean {
    return (
      this.workOrderData.followUpClean === true
      || this.workOrderData.followUpRepair === true
      || this.workOrderData.followUpReInspect === true
      || this.workOrderData.followUpOther === true
    );
  }

  canSetToInFollowUpRequired(): boolean {
    if (this.isNoneSelected) {
      return false;
    }
    return this.isFollowUpSelected;
  }

  // #endregion
}
