
































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Prop } from 'vue-property-decorator';
import { CustomerData } from '@/store/customers/types';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';

@Component({
  components: {
    IntegrityTable,
    IntegrityDelete,
  },
})
export default class EditUserCustomers extends AdminPanelMixin {
  @Prop() readonly selectedUser: EditUserData;

  @Prop({ default: '' }) readonly search: string;

  deleteCustomerHeader = 'WARNING: Delete Customer';

  deleteCustomerText(item: CustomerData): string {
    const usersName = `${this.selectedUser.detailedUserData.firstname} ${this.selectedUser.detailedUserData.lastname}`;
    return `This operation will delete customer '${item.name}' from ${usersName}. Are you sure you want to continue?`;
  }

  headers = [
    {
      text: 'Customer',
      value: 'name',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Address',
      value: 'address',
      sortable: true,
      filterable: true,
    },
    {
      text: 'System Manager',
      value: 'systemManager',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Phone',
      value: 'phoneNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'left',
      sortable: false,
    },
  ];

  matchFilters = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'address',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'systemManager',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'email',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'phoneNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  /**
   * @description Get the data for the table that includes the system manager's name.
   * @returns {CustomerData[]} List of customers
   */
  get customerTableData(): CustomerData[] {
    if (!this.customerDataList || !this.selectedUser?.detailedUserData) {
      return [];
    }
    const customers = this.selectedUser.detailedUserData.customerGuids
      .map((custGuid) => this.customerDataList.find((c) => c.guid === custGuid))
      .filter((x) => x);
    return customers.map((cust) => ({
      ...cust,
      systemManager: this.findUserFromUserGuid(cust.systemOwnerGuid),
    }));
  }

  /**
   * @description Find the user in the all users object based on their guid.
   * @param {string} userGuid User guid to search
   * @returns {string} first and last name of user
   */
  findUserFromUserGuid(userGuid: string): string {
    if (!this.allUsers) {
      return '';
    }
    const foundUser = this.allUsers.find((u) => u.guid === userGuid);
    if (foundUser) {
      return `${foundUser.firstname} ${foundUser.lastname}`;
    }
    return '';
  }

  /**
   * @description fill out the filter values for the table.
   * @returns filter values
   */
  get filterValues(): any {
    if (!this.customerTableData) {
      return;
    }

    const localFilterValues = {};

    this.headers.forEach((header) => {
      if (!header.filterable) return;

      localFilterValues[header.value] = [];

      this.customerTableData.forEach((workOrder: CustomerData) => {
        let checkVal: string;

        try {
          checkVal = workOrder[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(localFilterValues[header.value])
          && !localFilterValues[header.value].includes(checkVal)
        ) {
          localFilterValues[header.value].push(checkVal);
        }
      });
      localFilterValues[header.value].sort();
    });
    // eslint-disable-next-line consistent-return
    return localFilterValues;
  }

  /**
   * @description Calls endpoint to delete customer and removes it from user's customerGuids.
   * @param {CustomerData} item CustomerData object to remove.
   * @returns {Promise<boolean>} Returns true if customer was successfully deleted.
   */
  async handleDelete(item: CustomerData): Promise<boolean> {
    try {
      const deleteIndex = this.selectedUser.detailedUserData.customerGuids.findIndex(
        (value) => value === item.guid,
      );

      if (deleteIndex === -1) {
        // If we can not find the item in the customerGuids
        return false;
      }

      this.selectedUser.detailedUserData.customerGuids.splice(deleteIndex, 1);
    } catch (e) {
      return false;
    }
    return true;
  }
}
