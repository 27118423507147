











/* eslint-disable @typescript-eslint/no-explicit-any */
import { GenericDropDownData } from '@/common/types';
import {
  Component, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UserActions } from '@/store/users/actions';
import { DetailedUserData, UserData } from '@/store/users/types';
import {
  convertCrewMemberName,
  getAllCrewMembers,
} from '@/common/utils/WorkOrderUtils';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import ReportDropDownInput from './ReportDropDownInput.vue';

const userModule = namespace('users');
const projectModule = namespace('project');
const reportModule = namespace('report');

@Component({
  components: { ReportDropDownInput },
})
export default class ReportAssignedToDropDownInput extends UserPermissionsMixin {
  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA)
  fetchAllDetailedUserData;

  @userModule.State('allDetailedUserData') detailedUsers: DetailedUserData[];

  @userModule.State('loading') allUserDataLoading: boolean;

  @projectModule.State('projectGuids') projectGuids: string[];

  @VModel() modelValue!: string | GenericDropDownData | undefined;

  /**
   * The label text displayed next to the date picker input.
   * Defaults to Assign To
   * @type {string | undefined}
   */
  @Prop({ default: 'Assigned To' }) label: string | undefined;

  lastAssignedTo: string | GenericDropDownData = '';

  isLastAssignedToSet = false;

  /**
   * @returns true if the user has the permission WORK_ORDER_PLANNING
   */
  get hasPermissionWorkOrderPlanning(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_PLANNING);
  }

  /**
   * @returns true if the user has the permission WORK_ORDER_RESET_PLANNING
   */
  get hasPermissionWorkOrderResetPlanning(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_RESET_PLANNING);
  }

  get allCrewMembers(): GenericDropDownData[] {
    // Added to be reactive on lastAssignedTo change
    // eslint-disable-next-line no-unused-expressions
    this.lastAssignedTo;
    if (
      this.detailedUsers == null
      || this.allUsers == null
      || this.projectGuids == null
    ) {
      return [];
    }
    const projectUsers: DetailedUserData[] = this.detailedUsers
      .filter((u) => u.role.some((r) => this.projectGuids.includes(r.projectGuid)));
    const projectUserGuids = projectUsers.map((pu) => pu.guid);
    const allUsersCopy = this.allUsers.filter((f) => projectUserGuids.includes(f.guid));

    // Check if project users has our assigned to
    if (this.lastAssignedTo !== '') {
      // If not find it and push it.
      const foundUser = this.allUsers.find(
        (u) => u.guid === this.lastAssignedTo
          || convertCrewMemberName(u.firstname, u.lastname) === this.lastAssignedTo,
      );
      if (
        foundUser != null
        && projectUsers.find((u) => u.guid === foundUser.guid) == null
      ) {
        allUsersCopy.push({ ...foundUser });
      }
    }

    return getAllCrewMembers(allUsersCopy);
  }

  @Watch('modelValue')
  onModelValueChange(): void {
    // If modelValue is set to a value not available, then reset it
    if (
      this.modelValue
      && !this.allCrewMembers.find((f) => f.guid === this.modelValue)
    ) {
      this.modelValue = null;
      return;
    }
    if (!this.isLastAssignedToSet) {
      this.setLastAssignedTo();
    }
  }

  async mounted(): Promise<void> {
    if (this.allUsers == null && !this.allUserDataLoading) {
      await this.fetchAllUserData();
    }
    if (this.detailedUsers == null && !this.allUserDataLoading) {
      this.fetchAllDetailedUserData();
    }
    this.isLastAssignedToSet = false;
    this.setLastAssignedTo();
  }

  setLastAssignedTo(): void {
    this.lastAssignedTo = this.modelValue != null ? this.modelValue : '';
    if (this.lastAssignedTo !== '') {
      this.isLastAssignedToSet = true;
    }
  }
}
