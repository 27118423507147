import { render, staticRenderFns } from "./UploadScopeFile.vue?vue&type=template&id=a6cecaa0&scoped=true&"
import script from "./UploadScopeFile.vue?vue&type=script&lang=ts&"
export * from "./UploadScopeFile.vue?vue&type=script&lang=ts&"
import style0 from "./UploadScopeFile.vue?vue&type=style&index=0&id=a6cecaa0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6cecaa0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCheckbox,VFileInput,VForm,VSelect,VSpacer})
