/* eslint-disable import/prefer-default-export */
import { REDZONE_ORGANIZATION_GUID } from '../Constants';

/**
 * Checks if a given organization guid is the redzone organization
 * @param organizationGuid The organization guid string to check
 * @returns true if it is the redzone organization, else false
 */
export function isRedzoneOrganizationGuid(organizationGuid: string): boolean {
  return organizationGuid === REDZONE_ORGANIZATION_GUID;
}
