


















































import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';

export interface Counts {
  categoryID: number;
  count: number;
}

@Component({})
export default class WorkOrderCounts extends Vue {
  @Prop() categories;

  @Prop() readonly counts: Counts[] | undefined;

  @PropSync('selectedWorkOrderType') synchedSelectedWorkOrderType: string;

  selectedCategoryIndex = 0;

  itemsInCategory = 6;

  workOrderTypes = [
    { label: 'Route', icon: 'mdi-group' },
    { label: 'Asset', icon: 'mdi-square-outline' },
  ];

  mounted(): void {
    this.getRouteParams();
  }

  getCategoryCount(categoryID: number): string {
    if (!this.counts || this.counts.length === 0) {
      return 'loading';
    }
    const category = this.counts[categoryID];
    if (!category || category.count <= 0) {
      return '-';
    }
    return category.count.toLocaleString('en', { useGrouping: true });
  }

  onWorkOrderTypeChange(index: number): void {
    const newIndex = this.selectedCategoryIndex + this.itemsInCategory * index;
    this.$emit('setSelectedCategoryIndex', newIndex);
  }

  onCategoryChange(val: boolean, index: number): void {
    this.$emit('setCreateNewAssign', val);
    const workOrderTypeIndex = +(this.synchedSelectedWorkOrderType === 'Asset');
    this.selectedCategoryIndex = index;
    this.$emit(
      'setSelectedCategoryIndex',
      workOrderTypeIndex * this.itemsInCategory + this.selectedCategoryIndex,
    );
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (key === 'category') {
          this.selectedCategoryIndex = parseInt(value as string, 10);
        } else if (key === 'workOrderStatus') {
          const task = this.categories.findIndex(
            (item) => item.label === value,
          );
          this.onCategoryChange(false, task);
        }
      });
    }
  }
}
