import { MutationTree } from 'vuex';
import {
  DetailedUserData, UserData, UserManagement, UserState,
} from './types';

// eslint-disable-next-line no-shadow
export enum UserMutations {
    SET_LOADING_STATE = 'SET_LOADING_STATE',
    SET_ALL_USER_DATA = 'SET_ALL_USER_DATA',
    SET_ALL_DETAILED_USER_DATA = 'SET_ALL_DETAILED_USER_DATA',
    SET_SELECTED_USER_DATA = 'SET_SELECTED_USER_DATA',
    SET_SELECTED_USER_DATA_LOADING = 'SET_SELECTED_USER_DATA_LOADING',
    SET_INACTIVE_DETAILED_USER_DATA = 'SET_INACTIVE_DETAILED_USER_DATA',
    SET_LOAD_ERROR = 'SET_LOAD_ERROR',
    SET_RESPONSE = 'SET_RESPONSE',
    SET_USER_MANAGEMENTS = 'SET_USER_MANAGEMENTS',
}

export const mutations : MutationTree<UserState> = {
  [UserMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [UserMutations.SET_ALL_USER_DATA](state, payload: UserData[]) {
    state.allUserData = payload;
  },

  [UserMutations.SET_ALL_DETAILED_USER_DATA](state, payload: DetailedUserData[]) {
    state.allDetailedUserData = payload;
  },

  [UserMutations.SET_SELECTED_USER_DATA](state, payload: DetailedUserData) {
    state.selectedUserData = payload;
  },

  [UserMutations.SET_SELECTED_USER_DATA_LOADING](state, payload: boolean) {
    state.selectedUserDataLoading = payload;
  },

  [UserMutations.SET_INACTIVE_DETAILED_USER_DATA](state, payload: DetailedUserData[]) {
    state.inactiveDetailedUserData = payload;
  },

  [UserMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },

  [UserMutations.SET_RESPONSE](state, payload: string) {
    state.response = payload;
  },

  [UserMutations.SET_USER_MANAGEMENTS](state, payload: UserManagement[]) {
    state.userManagements = payload;
  },
};
