



























import {
  Component,
} from 'vue-property-decorator';
import ReportDateHeaderInput from '../ReportHelpers/ReportInputHelpers/ReportDateHeaderInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportHeaderMixin, { WorkOrderFieldType } from '../ReportHelpers/ReportHeaderMixin.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';

@Component({
  components: {
    ReportAssignedToDropDownInput,
    ReportDateHeaderInput,
    ReportDropDownInput,
  },
})
export default class GeneralMaintenanceReportHeader extends ReportHeaderMixin {
  workOrderData: any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users' },
    {
      name: 'dateIssued',
      value: 'DateIssued',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'assetNumber', value: 'Wastewater' },
    { name: 'priority', value: 'Priority' },
    {
      name: 'completeBy',
      value: 'CompleteBy',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'dateCompleted',
      value: 'DateCompleted',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'status',
      value: 'Status',
    },
    {
      name: 'priority',
      value: 'Priority',
    },
  ];
}
