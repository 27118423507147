/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-shadow
export enum AssetType {
    MANHOLE = 'Manhole',
    LINE_SEGMENT = 'Line Segment',
    LATERAL = 'Lateral',
    PARCEL = 'Parcel'
}

// eslint-disable-next-line no-shadow
export enum ValidationResult {
    PASSED = 1,
    WARNING = 2,
    FAILED = 3,
    NOT_RUN = 4,
    PASSED_WITH_ISSUES = 5,
    NULL = 0
}
export interface Defect {
    lat: number,
    long: number,
    score: number,
}
export interface Point {
    x: number,
    y: number,
    z?: number
}
export interface PageBounds {
    type: string,
    points: Point[],
    srid: number
}
export interface MapPage {
    guid: string,
    name: string,
    pageBounds: PageBounds,
    createdDate: string,
    isactive: boolean
}
