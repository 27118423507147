













import {
  Component, Vue, Prop, PropSync,
} from 'vue-property-decorator';
import { AssetData } from '@/store/asset/types';
import { TASK_TYPE_STRING } from '@/common/Constants';
import { format } from 'date-fns';
import { UserData } from '@/store/users/types';
import { namespace } from 'vuex-class';
import { UserActions } from '@/store/users/actions';
import CreateWorkOrderForm from './CreateWorkOrderForm.vue';

const userModule = namespace('users');

@Component({
  components: {
    CreateWorkOrderForm,
  },
})
export default class CreateServiceCall extends Vue {
  @PropSync('serviceCallDialog') editWorkOrderDialog;

  @Prop() selectedAsset: AssetData;

  currentUserGuid = null;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  get serviceCallTaskTypeGuid(): any {
    return TASK_TYPE_STRING.find((tts) => tts.desc === 'Service Call').guid;
  }

  async mounted(): Promise<any> {
    if (!this.allUsers) {
      await this.fetchAllUserData();
    }
    const currentUser = await this.$auth.getUser();
    const currentUserData = this.allUsers.find(
      (u) => u.email === currentUser.email,
    );
    this.currentUserGuid = currentUserData.guid;
  }

  get initJsonData(): any {
    return {
      DateIssued: format(Date.now(), 'yyyy-MM-dd'),
      Users: this.currentUserGuid,
      Address: this.selectedAsset?.street ?? '',
      Wastewater: this.selectedAsset?.name,
    };
  }

  refresh(): void {
    this.$emit('refresh');
  }
}
