







































































































/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';

@Component({})
export default class MapOptions extends Vue {
  @PropSync('infoOptions') synchedInfoOptions!: any;

  @PropSync('selectedBasemap') syncSelectedBasemap: string;

  @PropSync('selectedFilterLayer') syncSelectedFilterLayer: string;

  basemapOptions = [
    {
      name: 'topo-vector',
      desc: 'Vector',
      icon: './map-vector.png',
    },
    {
      name: 'streets-navigation-vector',
      desc: 'Street',
      icon: './map-street.png',
    },
    {
      name: 'hybrid',
      desc: 'Hybrid',
      icon: './map-hybrid.png',
    },
    {
      name: 'satellite',
      desc: 'Satellite',
      icon: './map-satellite.png',
    },
    {
      name: 'terrain',
      desc: 'Terrain',
      icon: './map-terrain.png',
    },
  ]

  filterLayerOptions = {
    name: 'Filter Layers',
    contents: [
      { name: 'score', desc: 'NASSCO Score' },
      { name: 'slratScore', desc: 'SLRat Score' },
      { name: 'collection', desc: 'Collection Status' },
    ],
  }

  get filteredInfoOptions(): any {
    return this.synchedInfoOptions.filter((option) => option.exists === true);
  }

  getBasemapClass(basemap: string): string {
    return basemap === this.syncSelectedBasemap
      ? 'outlined'
      : '';
  }

  getImage(image: string): unknown {
    const retVal = require.context('../../assets/', false, /\.png$/);
    return retVal(image);
  }

  isIndeterminate(option: string): boolean {
    const content = this.synchedInfoOptions.find((o) => o.name === option).contents;
    return content
      ? content.some((c) => c.active === false) && content.some((c) => c.active === true)
      : false;
  }

  selectBasemap(basemap: string): void {
    if (this.syncSelectedBasemap !== basemap) this.syncSelectedBasemap = basemap;
  }

  toggleAllOptions(infoOption: string): void {
    const option = this.synchedInfoOptions.find((o) => o.name === infoOption);

    this.toggleOption({
      name: infoOption,
      active: option.active,
    });
  }

  toggleOption(option: { name: string, active: boolean }): void {
    this.$emit('toggleLayer', { name: option.name, active: option.active });
  }
}

