














































































/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import {
  Component, Prop, PropSync,
} from 'vue-property-decorator';
import { NodeInfo, WorkOrderTableData } from '@/store/planning/types';
import { namespace } from 'vuex-class';
import { DeploymentsActions } from '@/store/deployments/actions';
import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { RequiredField } from '@/common/CommonVariables';
import { NullMissionPostDTO } from '@/store/deployments/types';
import { TASK_RESULT_DETAILS } from '@/common/Constants';
import { RoutingActions } from '@/store/routing/actions';
import { RoutingData } from '@/store/routing/types';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';
import { ALL_STATUSES, STATUS_FOLLOW_UP_REQUIRED } from './Common/PlanningTableUtils';

const deploymentModule = namespace('deployments');
const integritySnackBar = namespace('integritySnackBar');
const routingModule = namespace('routing');
const projectModule = namespace('project');

  @Component({
    components: {
    },
  })
export default class NullMissionCreator extends UserPermissionsMixin {
    @PropSync('show') showDialog: boolean;

    @Prop() readonly selectedWorkOrderTableData!: WorkOrderTableData[];

    @deploymentModule.Action(DeploymentsActions.POST_NULL_MISSION) postNullMission;

    @deploymentModule.State('postNullMissionLoading') postNullMissionLoading: boolean;

    @deploymentModule.State('postNullMissionError') postNullMissionError: string | undefined;

    @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

    @routingModule.State('routingData') routingData: RoutingData[] | undefined;

    @routingModule.Action(RoutingActions.FETCH_ROUTING_DATA) fetchRoutingData;

    @routingModule.State('routingLoading') routingLoading: boolean;

    @projectModule.State('projectGuids') projectGuids: string[];

    allTaskResults = TASK_RESULT_DETAILS.map((item) => item.description);

    allStatuses = ALL_STATUSES;

    formTaskResult?: string = '';

    defaultFormStatus = STATUS_FOLLOW_UP_REQUIRED;

    formStatus = this.defaultFormStatus;

    formComments = '';

    valid = false;

    requiredField = RequiredField;

    get taskResultGuid(): string | undefined {
      return TASK_RESULT_DETAILS
        .find((item) => item.description === this.formTaskResult)?.guid;
    }

    get allSelectedItemsNodeInfo(): NodeInfo[] {
      return this.selectedWorkOrderTableData != null
        ? this.selectedWorkOrderTableData.flatMap(this.constructNodeInfoList)
        : [];
    }

    mounted(): void {
      if (this.routingData == null
        && !this.routingLoading
        && this.projectGuids?.length > 0
      ) {
        this.fetchRoutingData(this.projectGuids[0]);
      }
    }

    private constructNodeInfoList(workOrder: WorkOrderTableData): NodeInfo[] {
      if (workOrder.routeGuid && workOrder.nodes != null) {
        return workOrder.nodes;
      }
      if (workOrder.nodeGuid != null && workOrder.nodeName != null) {
        return [{
          nodeGuid: workOrder.nodeGuid,
          nodeName: workOrder.nodeName,
          workOrderNumber: workOrder.workOrderNumber,
          workOrderGuid: workOrder.guid,
        }];
      }
      if (workOrder.nodes != null) {
        return workOrder.nodes;
      }
      return [];
    }

    private getRouteOrNodeName(workOrderTableData: WorkOrderTableData): string {
      if (workOrderTableData.routeGuid && this.routingData) {
        const route = this.routingData.find((r) => r.guid === workOrderTableData.routeGuid);
        if (route?.routeName) {
          return `${route.routeName}`;
        }
      }
      return workOrderTableData.nodeName ?? '';
    }

    async cancel(): Promise<void> {
      this.showDialog = false;
      await (this.$refs.nullMissionForm as any).reset();
      this.formStatus = this.defaultFormStatus;
    }

    async saveNullMission(): Promise<void> {
      try {
        const postNullMissionDTO: NullMissionPostDTO[] = this.allSelectedItemsNodeInfo
          .map((workOrder) => {
            const returnValue: NullMissionPostDTO = {
              workOrderGuid: workOrder.workOrderGuid,
              taskResultGuid: this.taskResultGuid,
              status: this.formStatus,
              comments: this.formComments,
            };
            return returnValue;
          });
        await this.postNullMission(postNullMissionDTO);
        this.$emit('save');
        this.cancel();
        this.showSnackBar({
          message: this.postNullMissionError ?? 'Null mission created',
          color: 'green',
          showSnackBar: true,
        });
      } catch (e) {
        this.showSnackBar({
          message: this.postNullMissionError ?? 'Null mission creation failed',
          color: 'red',
          showSnackBar: true,
        });
      }
    }
}
