


















































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import FOGInspectionReportHeader from './FOGInspectionReportHeader.vue';
import ReportFooter from '../ReportFooter.vue';
import { FogInspectionFields } from './types';
import {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportInputCheckboxTable from '../ReportHelpers/ReportInputHelpers/ReportInputCheckboxTable.vue';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';

@Component({
  components: {
    ReportFooter,
    ReportTextInput,
    ReportCalendarInput,
    ReportRadioRowInput,
    FOGInspectionReportHeader,
    ReportInputCheckboxTable,
  },
})
export default class FogInspectionReport extends ReportBodyMixin {
  workOrderData: FogInspectionFields | any = {};

  workOrderFields = [
    { name: 'address', value: 'Street' },
    { name: 'city', value: 'City' },
    { name: 'phone', value: 'FSEPhone' },
    { name: 'managerOnDuty', value: 'FSEManagerOnDuty' },
    {
      name: 'inspectionDate',
      value: 'InspDate',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'inspectionTime',
      value: 'InspTime',
      type: WorkOrderFieldType.TIME,
    },
    { name: 'grdType', value: 'FOGGRDType' },
    { name: 'ifOther', value: 'GRDOtherType' },
    { name: 'size', value: 'GRDSize' },
    { name: 'manufacturer', value: 'GRDManufacturer' },
    { name: 'maintenanceLogProvided', value: 'BMPGuideGiven' },
    { name: 'maintenanceLogBPMFollowed', value: 'BMPFollowed' },
    { name: 'maintenanceLogCurrent', value: 'MaintenanceLogCurrent' },
    { name: 'maintenanceLogBPMOnHand', value: 'MaintenanceLogGivenToFSE' },
    {
      name: 'lastGRDMaintenanceDate',
      value: 'GRDLastMaintDate',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'maintainedBy', value: 'GRDLastMaintBy' },
    {
      name: 'lastRemovalDate',
      value: 'NextScheduledGreaseRemoval',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'removedBy', value: 'GreaseRemovalContractor' },
    {
      name: 'unsatisfactoryInspectionDate',
      value: 'DateLastInspectionFail',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'lasResults', value: 'GRDLastMaintResult' },
    {
      name: 'unsatisfactoryMissingAccessCover',
      value: 'GRDLastInspFail_BrokenMissing',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryDefectiveInletOutlet',
      value: 'GRDLastInspFail_InletOutLet',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryNonApprovedGreaseInterceptor',
      value: 'GRDLastInspFail_NonAppovInterceptor',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryMissingFlowRestrictor',
      value: 'GRDLastInspFail_MissingBrokenRestrict',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryDishwasherTiedIntoTrap',
      value: 'GRDLastInspFail_DishwasherTiedtoTrap',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryNoGreaseTrap',
      value: 'GRDLastInspFail_NotInstalled',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryMissingBaffleWall',
      value: 'GRDLastInspFail_BaffleScreenMissing',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryDirty',
      value: 'GRDLastInspFail_DirtyNeedsPumped',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryNoLog',
      value: 'GRDLastInspFail_NoLog',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryNotAccessible',
      value: 'GRDLastInspFail_NoAccess',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryReqDrainNotConnectedToGRD',
      value: 'GRDLastInspFail_DrainNotConnected',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryNotProperlyLocated',
      value: 'GRDLastInspFail_NotLocated',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryProhibitedDrainConnectedtoGRD',
      value: 'GRDLastInspFail_DrainProhibit',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'unsatisfactoryOther',
      value: 'GRDLastInspFail_Other',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'inspectionResults', value: 'FOGInspectionResults' },
    { name: 'other', value: 'Comments' },
    { name: 'followUp', value: 'FOGFollowup' },
    { name: 'followUpComments', value: 'FollowUp' },
  ];

  // #region Local variables
  followUpOptions = ['Clean', 'Repair'];

  reasonUnsatisfactoryCheckboxValues = [
    {
      label: 'Broken/ Missing Access Cover',
      value: 'unsatisfactoryMissingAccessCover',
    },
    {
      label: 'Inlet/ Outlet Defective',
      value: 'unsatisfactoryDefectiveInletOutlet',
    },
    {
      label: 'Non-Approved Grease Interceptor',
      value: 'unsatisfactoryNonApprovedGreaseInterceptor',
    },
    {
      label: 'Flow Restrictor Missing/ Broken',
      value: 'unsatisfactoryMissingFlowRestrictor',
    },
    {
      label: 'Dishwasher Tied into Trap',
      value: 'unsatisfactoryDishwasherTiedIntoTrap',
    },
    {
      label: 'No Grease Trap Installed (Send Ltr)',
      value: 'unsatisfactoryNoGreaseTrap',
    },
    {
      label: 'Baffle Wall Screen Missing',
      value: 'unsatisfactoryMissingBaffleWall',
    },
    {
      label: 'Dirty/ Needs Pumped Out',
      value: 'unsatisfactoryDirty',
    },
    {
      label: 'No Maintenance Log',
      value: 'unsatisfactoryNoLog',
    },
    {
      label: 'Not Accessible for Maintenance',
      value: 'unsatisfactoryNotAccessible',
    },
    {
      label: 'Required Drain not Connected to GRD',
      value: 'unsatisfactoryReqDrainNotConnectedToGRD',
    },
    {
      label: 'Not Properly Located',
      value: 'unsatisfactoryNotProperlyLocated',
    },
    {
      label: 'Prohibited Drain Connected to GRD',
      value: 'unsatisfactoryProhibitedDrainConnectedtoGRD',
    },
    {
      label: 'Other',
      value: 'unsatisfactoryOther',
    },
  ];
  // #endregion
}
