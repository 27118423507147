























/* eslint-disable @typescript-eslint/no-explicit-any */
import { TASK_TYPE_STRING } from '@/common/Constants';
import { Component } from 'vue-property-decorator';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportDateHeaderInput from '../ReportHelpers/ReportInputHelpers/ReportDateHeaderInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportHeaderMixin, { WorkOrderFieldType } from '../ReportHelpers/ReportHeaderMixin.vue';

@Component({
  components: {
    ReportDropDownInput,
    ReportAssignedToDropDownInput,
    ReportDateHeaderInput,
  },
})
export default class PipeReliningReportHeader extends ReportHeaderMixin {
  workOrderData: any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Surveyor' },
    { name: 'wastewater', value: 'Wastewater' },
    {
      name: 'dateIssued', value: 'DateIssued', type: WorkOrderFieldType.DATE,
    },
    {
      name: 'dateCompleted', value: 'DateCompleted', type: WorkOrderFieldType.DATE,
    },
    {
      name: 'completeBy',
      value: 'CompleteBy',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'status',
      value: 'Status',
    },
  ];

  get workOrderType(): string {
    return this.workOrderData?.workOrderNumber != null
      ? this.workOrderData.workOrderNumber.split('-')[0]
      : '';
  }

  get workOrderTaskTypeName(): string {
    return (
      TASK_TYPE_STRING.find((x) => x.guid === this.workOrderData.taskTypeGuid)
        ?.desc ?? ''
    );
  }
}
