













































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import { CleaningReportFields } from './types';
import CleaningWorkOrderReportHeader from './CleaningWorkOrderReportHeader.vue';
import { WorkOrderFieldType } from '../ReportHelpers/ReportInterface.vue';
import ReportRadioTableInput from '../ReportHelpers/ReportInputHelpers/ReportRadioTableInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';

@Component({
  components: {
    ReportFooter,
    ReportTextInput,
    ReportDropDownInput,
    ReportRadioTableInput,
    CleaningWorkOrderReportHeader,
    ReportRadioRowInput,
  },
})
export default class CleaningWorkOrderReport extends ReportBodyMixin {
  // #region Abstract fields
  workOrderData: CleaningReportFields | any = {};

  workOrderFields = [
    { name: 'frequency', value: 'FrequencyChange_FC' },
    { name: 'workDescription', value: 'Description' },
    { name: 'specialInstructions', value: 'Instructions' },
    { name: 'weatherCondition', value: 'WeatherType' },
    { name: 'structUp', value: 'Wastewater_Structure_Up' },
    { name: 'structDown', value: 'Wastewater_Structure_Dn' },
    { name: 'address', value: 'StreetAddress' },
    { name: 'size', value: 'PipeSize' },
    { name: 'material', value: 'PipeMaterial' },
    { name: 'length', value: 'LengthGIS' },
    { name: 'specialConditions', value: 'SpecialConditions' },
    { name: 'roots', value: 'ConditionLMH_Obs1' },
    { name: 'grease', value: 'ConditionLMH_Obs2' },
    { name: 'odor', value: 'ConditionLMH_Obs3' },
    { name: 'observationOther', value: 'ConditionLMH_Obs4' },
    { name: 'gravel', value: 'ConditionLMH_Obs8' },
    { name: 'grit', value: 'ConditionLMH_Obs7' },
    { name: 'sand', value: 'ConditionLMH_Obs6' },
    { name: 'cleaningComments', value: 'CleanComments' },
    { name: 'rootCut', value: 'FURootTreat', type: WorkOrderFieldType.BOOLEAN },
    { name: 'cctv', value: 'FUCCTV', type: WorkOrderFieldType.BOOLEAN },
    { name: 'repair', value: 'FURepair', type: WorkOrderFieldType.BOOLEAN },
    {
      name: 'manholeInpsection',
      value: 'FUMHInspect',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'perfromInvestigation',
      value: 'FUInvestigate',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'mapChanges',
      value: 'FUMapChanges',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'followUpOther',
      value: 'FUOther',
      type: WorkOrderFieldType.BOOLEAN,
    },
    {
      name: 'followUpNone',
      value: 'FUNone',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'followUpComments', value: 'FollowUpComments' },
  ];
  // #endregion

  // #region Local variables

  weatherConditionOptions = ['Dry', 'Light Rain', 'Heavy Rain'];

  tableHeaders = [
    {
      value: 'HEAVY',
      label: 'H',
    },
    {
      value: 'MEDIUM',
      label: 'M',
    },
    {
      value: 'LIGHT',
      label: 'L',
    },
    {
      value: 'NONE',
      label: 'N',
    },
  ];

  firstColumnRows = [
    {
      value: 'roots',
      label: 'Roots',
    },
    {
      value: 'grease',
      label: 'Grease',
    },
    {
      value: 'odor',
      label: 'Odor',
    },
    {
      value: 'observationOther',
      label: 'Other',
    },
  ];

  secondColumnRows = [
    {
      value: 'gravel',
      label: 'Gravel',
    },
    {
      value: 'grit',
      label: 'Debris/ Grit',
    },
    {
      value: 'sand',
      label: 'Sand',
    },
  ];
  // #endregion

  // #region Local functions

  isFrequencyChecked(option: string): boolean {
    return this.workOrderData['frequency'] === option;
  }

  changeFrequencyValue(option: string): void {
    const isChecked = this.isFrequencyChecked(option);
    const newValue = !isChecked ? option : 'Do not adjust Frequency';
    this.changeOption('frequency', newValue);
  }

  get isNoneSelected(): boolean {
    return this.workOrderData.followUpNone === true;
  }

  get isFollowUpSelected(): boolean {
    return (
      this.workOrderData.rootCut === true
      || this.workOrderData.cctv === true
      || this.workOrderData.repair === true
      || this.workOrderData.manholeInpsection === true
      || this.workOrderData.perfromInvestigation === true
      || this.workOrderData.mapChanges === true
      || this.workOrderData.followUpOther === true
      || (this.workOrderData.frequency !== 'Do not adjust Frequency'
        && !!this.workOrderData.frequency)
    );
  }

  canSetToInFollowUpRequired(): boolean {
    if (this.isNoneSelected) {
      return false;
    }
    return this.isFollowUpSelected;
  }

  showTimeAndMaterials(): boolean {
    return true;
  }
  // #endregion
}
