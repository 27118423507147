
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AssetData, InspectionData } from '@/store/asset/types';
import Ajv2019 from 'ajv/dist/2019';
import { InspectionActions } from '@/store/inspection/actions';
import {
  CodingFormData, ConditionalRequirement, ConditionalType, InspectionResponse,
} from '../CodingForm/types';
import utils from '../../views/Asset/utils';

const inspectionModule = namespace('inspection');
const assetModule = namespace('asset');

@Component
export default class CodingFormCommonMixin extends Vue {
    @Prop() assetType: string;

    @Prop({ default: null }) codingDetail: InspectionResponse;

    @Prop() inspectionId: string;

    @Prop() isPopout: boolean;

    @Prop({ default: null }) maxDepth: number;

    @Prop() inspection!: InspectionData;

    @Prop({ default: false }) isWorkOrderForm: boolean;

    @inspectionModule.State('fullCodingForm') fullCodingForm:
    | CodingFormData[]
    | undefined;

    @inspectionModule.State('subFullCodingForm') subFullCodingForm:
    | any[]
    | undefined;

    @inspectionModule.Action(InspectionActions.SET_CODING_FORM) setCodingForm;

    @inspectionModule.Action(InspectionActions.SET_SUB_CODING_FORM)
    setSubCodingForm;

    @assetModule.State('asset') assetData: AssetData | undefined = undefined;

    // Keeping track of GUIDs created for each pipe connection/sub coding forms
    codingDetailInspections = [];

    dataStandardJson = null;

    dataStandardFormat = null;

    dataStandardSchema = {};

    subDataStandardFormat;

    subDataStandardSchema;

    errorExists = false;

    error = '';

    oldFormData: any = {};

    reportWrapperInspectionData: any = {};

    oldSubFormData = [];

    pages = [];

    subPages = [];

    checkBoxHeaderNumbers = [];

    subCheckBoxHeaderNumbers = [];

    selectedType = '';

    selectedTypeOld = '';

    connectionsCount = 0;

    subCodeIndex = -1;

    requiredFieldObjects: ConditionalRequirement;

    ALWAYSREQUIRED = 'alwaysRequired';

    get inspectionIndex(): number {
      let retVal = this.codingDetail.inspections.findIndex(
        (insp) => insp.inspectionGuid === this.inspectionId,
      );
      if (retVal === -1) retVal = 0;
      return retVal;
    }

    async codingDetailsUpdateFunc(): Promise<void> {
      if (this.codingDetail === undefined) return;

      const currentInspDetails = this.codingDetail?.inspections[this.inspectionIndex];

      if (!currentInspDetails) {
        return;
      }

      this.dataStandardFormat = JSON.parse(
        currentInspDetails.dataStandard_format,
      );
      this.dataStandardSchema = JSON.parse(
        currentInspDetails.dataStandard_Schema,
      );
      this.subDataStandardFormat = JSON.parse(
        currentInspDetails.subDataStandard_format,
      );
      this.subDataStandardSchema = JSON.parse(
        currentInspDetails.subDataStandard_Schema,
      );
      this.dataStandardJson = currentInspDetails.dataStandard_Json;

      if (!this.dataStandardFormat) {
        this.error = 'Error: Invalid Data Standard Format';
        this.errorExists = true;
        return;
      }
      if (!this.dataStandardSchema) {
        this.error = 'Error: Invalid Data Standard Schema';
        this.errorExists = true;
        return;
      }

      currentInspDetails.subInspectionJsonData.forEach((element) => {
        const elementExists = this.codingDetailInspections.find(
          (insp) => insp.subInspectionGuid === element.subInspectionGuid,
        );

        if (!elementExists) {
          this.codingDetailInspections.push(element);

          if (element.jsonData !== null) {
            this.oldSubFormData.push(JSON.parse(element.jsonData));
          } else if (element !== null) {
            this.oldSubFormData.push(JSON.parse(element as string));
          }
        }
      });

      if (this.codingDetail.inspections[0].jsonData) {
        this.oldFormData = JSON.parse(this.codingDetail.inspections[0].jsonData);
        this.reportWrapperInspectionData = { ...this.oldFormData };
      }

      const reqs: string[] = [];
      this.pages = this.dataStandardFormat.formpage.map((m) => m.pagename);

      if (this.subDataStandardFormat != null) {
        this.subPages = this.subDataStandardFormat.formpage.map(
          (m) => m.pagename,
        );
      }

      this.pages.forEach((page) => {
        const uniqueNums = [];

        const formPage = this.dataStandardFormat.formpage.find(
          (fp) => fp.pagename === page,
        );
        if (formPage != null) {
          formPage.formpagedata.forEach((pageData) => {
            const oldVal = this.oldFormData[pageData.headername];

            // check if header has already been added, if it was, skip
            if (
              !this.isPopout
            && this.fullCodingForm.findIndex(
              (fc) => fc.header === pageData.headername,
            ) === -1
            ) {
              this.fullCodingForm.push({
                header: pageData.headername,
                // instead of null we should check data type and fill as appropriate
                // strings = '' ints/nums = 0
                value: this.getCodingFormDataValue(oldVal, pageData),
                isChanged: !!(
                  oldVal
                || (reqs && reqs.includes(pageData.headername))
                ),
                headerNumber: pageData.headernumber,
              });
            }

            // look for checkbox values
            const currNum = pageData.headernumber;
            if (pageData.type !== 'array') {
              if (!uniqueNums.includes(currNum) && pageData.type !== 'boolean') {
                uniqueNums.push(currNum);
              } else if (
                !uniqueNums.includes(currNum)
              && pageData.type === 'boolean'
              ) {
                uniqueNums.push(currNum);
                this.checkBoxHeaderNumbers.push(currNum);
              } else {
                this.checkBoxHeaderNumbers.push(currNum);
              }
            }
          });
        }
      });

      // check for macp/pacp values to prefill
      if (this.assetType === 'Manhole') {
      // macp
        const mhNumber = this.fullCodingForm.find(
          (fc) => fc.header === 'ManholeNumber',
        );
        if (mhNumber && mhNumber.value === '') {
          mhNumber.value = this.codingDetail.name;
        }
      } else {
      // pacp
      // 30. Pipe Segment
        const lsSegment = this.fullCodingForm.find(
          (fc) => fc.header === 'PipeSegment',
        );
        if (lsSegment && lsSegment.value === '') {
          lsSegment.value = this.codingDetail.name;
        }
        // 43. Map Length
        const mapLength = this.fullCodingForm.find(
          (fc) => fc.header === 'TotalLength',
        );
        if (mapLength && mapLength.value === null) {
          mapLength.value = parseFloat(
          (this.assetData?.attributes as any)?.Length,
          ).toFixed(2);
        }
        // 44. Length Surveyed
        const maxDepth = this.fullCodingForm.find(
          (fc) => fc.header === 'LengthSurveyed',
        );
        if (maxDepth) {
          if (maxDepth && maxDepth.value === null) {
            maxDepth.value = this.maxDepth;
          }
          const maxDepthReached = Math.round(utils.getMaxDepthReached(this.inspection) * 10) / 10;
          maxDepth.value = Math.max(maxDepth.value as number, maxDepthReached);
        }
      }

      if (this.subDataStandardFormat != null) {
        if (this.oldSubFormData.length > this.subFullCodingForm.length) {
          this.oldSubFormData.forEach((oldData) => {
            const uniqueNums = [];
            const tempData = [];
            this.subPages.forEach((page) => {
              this.subDataStandardFormat.formpage
                .find((fp) => fp.pagename === page)
                .formpagedata.forEach((pageData) => {
                  const oldVal = oldData[pageData.headername];
                  tempData.push({
                    header: pageData.headername,
                    value: this.getCodingFormDataValue(oldVal, pageData),
                    // eslint-disable-next-line no-unneeded-ternary
                    isChanged: !!oldVal,
                    headerNumber: pageData.headernumber,
                  });
                  // look for checkbox values
                  const currNum = pageData.headernumber;
                  if (!uniqueNums.includes(currNum)) {
                    uniqueNums.push(currNum);
                  } else {
                    this.subCheckBoxHeaderNumbers.push(currNum);
                  }
                });
            });
            this.subFullCodingForm.push(tempData);
          });

          this.sortSubFullCoding();
        }
        this.connectionsCount = this.subFullCodingForm.length;
        this.subCodeIndex = this.connectionsCount > 0 ? 0 : -1;
      }

      this.checkCheckBoxValues();

      this.connectionsCount = this.subFullCodingForm.map((cf) => cf.find((it) => it.header === 'Structure')).length;
      // eslint-disable-next-line prefer-destructuring
      this.setInitialSelectedType();

      this.requiredFieldObjects = this.findRequiredFields(
        this.dataStandardSchema,
      );
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getCodingFormDataValue(
      oldVal: any,
      pageData: any,
    ): string | number | boolean | any[] {
      if (oldVal) {
        if (pageData.type === 'array') {
          return Array.isArray(oldVal) ? oldVal : [];
        }
        if ((oldVal as string).length === 0) {
        // eslint-disable-next-line no-param-reassign
          oldVal = null;
        } else if (pageData.type === 'integer') {
        // eslint-disable-next-line no-param-reassign
          oldVal = parseInt(oldVal as string, 10);
        } else if (pageData.type === 'number') {
        // eslint-disable-next-line no-param-reassign
          oldVal = parseFloat(oldVal as string);
        }
        return oldVal;
      }

      if (oldVal === 0) {
        if (pageData.type === 'integer' || pageData.type === 'number') {
          return 0;
        }
      }
      if (pageData.type === 'string') return '';
      if (pageData.type === 'integer' || pageData.type === 'number') return null;
      if (pageData.type === 'boolean') return false;
      if (pageData.type === 'array') return [];
      return null;
    }

    sortSubFullCoding(): any[] {
      if (this.subFullCodingForm.length === 0) {
        return [];
      }

      const subSchemaNumberHeader = this.getPageHeader();

      // Remove duplicates and sort into order
      this.oldSubFormData = [
        ...new Map(
          this.oldSubFormData.map((item) => [item[subSchemaNumberHeader], item]),
        ).values(),
      ].sort((a, b) => a[subSchemaNumberHeader] - b[subSchemaNumberHeader]);
      const subFullCodingForm = [
        ...new Map(
          this.subFullCodingForm.map((item) => [item[0].value, item]),
        ).values(),
      ].sort((a, b) => a[0].value - b[0].value);

      // Enfore numbering
      subFullCodingForm.forEach((subForm, index) => {
      // eslint-disable-next-line no-param-reassign
        subForm[0].value = index + 1;
      });

      if (subSchemaNumberHeader === 'PipeNumber') {
        subFullCodingForm[0].find(
          (header) => header.header === 'ClockPositions',
        ).value = 6;
      }

      this.setSubCodingForm(subFullCodingForm);
      return subFullCodingForm;
    }

    /**
     * @description Get the header of the current page
     * @returns the header name or null if it cannot be found
     */
    getPageHeader(): string | null {
      const pageSubDataStandardFormat = this.subDataStandardFormat.formpage.find(
        (fp) => fp.pagename === this.selectedType,
      );

      let pageHeader = null;
      if (
    pageSubDataStandardFormat?.formpagedata != null
    && pageSubDataStandardFormat.formpagedata.length > 0
      ) {
        pageHeader = pageSubDataStandardFormat.formpagedata[0].headername;
      }
      return pageHeader;
    }

    checkCheckBoxValues(): void {
    // check full coding form
      const fcCheckBoxes = this.fullCodingForm.filter(
        (fc: CodingFormData) => this.checkBoxHeaderNumbers.includes(fc.headerNumber),
      );
      const checkedBoxes = fcCheckBoxes.find(
        (fc: CodingFormData) => fc.value === true,
      );
      if (checkedBoxes !== undefined) {
        for (let i = 0; i < fcCheckBoxes.length; i += 1) {
          fcCheckBoxes[i].isChanged = true;
        }
      }
      // check sub  coding form
      const scCheckBoxes = this.subFullCodingForm.filter(
        (fc: CodingFormData) => this.checkBoxHeaderNumbers.includes(fc.headerNumber),
      );
      const scCheckedBoxes = scCheckBoxes.find(
        (fc: CodingFormData) => fc.value === true,
      );
      if (scCheckedBoxes !== undefined) {
        for (let i = 0; i < scCheckBoxes.length; i += 1) {
          scCheckBoxes[i].isChanged = true;
        }
      }
    }

    setInitialSelectedType(): void {
      this.selectedType = this.isWorkOrderForm ? 'Report' : this.pages[0];
    }

    findRequiredFields(schema: any): ConditionalRequirement {
      if (schema?.required) {
        return {
          conditionalField: this.ALWAYSREQUIRED,
          comparerValue: true,
          requiredOnThen: schema.required as string[],
          // everything else can be empty
          requiredOnElse: [],
          conditionalsOnElse: [],
          conditionalsOnThen: [],
          optionalOnElse: [],
          optionalOnThen: [],
        };
      }
      return this.findRequiredFieldsRecursive(schema);
    }

    findRequiredFieldsRecursive(schema: any): ConditionalRequirement {
      if (schema == null) return null;
      const isOf = this.checkForOf(schema);
      if (isOf) {
        return {
          conditionalField: this.ALWAYSREQUIRED,
          comparerValue: true,
          conditionalsOnThen: (() => {
            const retVal: ConditionalRequirement[] = [];
            schema[isOf].forEach((element) => {
              const newRequirement = this.findRequiredFieldsRecursive(element);
              if (newRequirement != null) retVal.push(newRequirement);
            });
            return retVal;
          })(),
          // everything else can be empty
          requiredOnElse: [],
          requiredOnThen: [],
          conditionalsOnElse: [],
          optionalOnElse: [],
          optionalOnThen: [],
        };
      }
      const conditionalRequirement: ConditionalRequirement = {
        conditionalField: '',
        comparerValue: '',
        requiredOnElse: [],
        conditionalsOnElse: [],
        requiredOnThen: [],
        conditionalsOnThen: [],
        optionalOnElse: [],
        optionalOnThen: [],
      };
      // check for property and const or enum
      if (schema.if) {
      // eslint-disable-next-line prefer-destructuring
        const propName = Object.entries(schema.if.properties)[0][0];
        const path = schema.if.properties[propName];
        conditionalRequirement.conditionalField = propName;
        // eslint-disable-next-line prefer-destructuring
        conditionalRequirement.comparerValue = Object.entries(path)[0][1] as any;
      }
      if (schema.else) {
        if (schema.else.required) {
          conditionalRequirement.requiredOnElse = schema.else.required;
        }
        const keys = Object.keys(schema.else);
        keys.forEach((key) => {
          if (ConditionalType.includes(key)) {
            conditionalRequirement.conditionalsOnElse.push(
              this.findRequiredFieldsRecursive(schema.else),
            );
          }
          if (key === 'properties') {
            Object.entries(schema.else.properties).forEach((entry) => {
              const property = this.checkProperties(entry);
              if (property) {
                conditionalRequirement.optionalOnElse.push(property);
              }
            });
          }
        });
      }
      if (schema.then) {
        if (schema.then.required) {
          conditionalRequirement.requiredOnThen = schema.then.required;
        }
        const keys = Object.keys(schema.then);
        keys.forEach((key) => {
          if (ConditionalType.includes(key)) {
            conditionalRequirement.conditionalsOnThen.push(
              this.findRequiredFieldsRecursive(schema.then),
            );
          }
          if (key === 'properties') {
            Object.entries(schema.then.properties).forEach((entry) => {
              const property = this.checkProperties(entry);
              if (property) {
                conditionalRequirement.optionalOnThen.push(property);
              }
            });
          }
        });
      }
      return conditionalRequirement;
    }

    // return property name when minlength is 0 or type includes null
    checkProperties(entry: any): string | undefined {
      if (entry[1].minLength !== undefined || entry[1].type !== undefined) {
        if (entry[1].minLength === 0) {
          return entry[0];
        }
        if (Array.isArray(entry[1].type) && entry[1].type.includes('null')) {
          return entry[0];
        }
      }
      return undefined;
    }

    checkForOf(schema: any): string | null {
      if (schema.allOf) {
        return 'allOf';
      }
      if (schema.anyOf) {
        return 'anyOf';
      }
      return null;
    }
}
