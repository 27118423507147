


















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectHeaders } from '@/store/project/types';
import { AssetActions } from '@/store/asset/actions';
import { Mapping } from '@/store/types';
import {
  FILE_MAPPING_MANHOLES_WITH_INSPECTIONS,
  FILE_MAPPING_MANHOLES_NO_INSPECTIONS,
  FILE_MAPPING_MANHOLE_GIS_DATA,
  FILE_MAPPING_LINE_SEGMENT,
  FILE_MAPPING_LINE_SEGMENT_GIS_DATA,
  FILE_MAPPING_SLRAT_SCORE,
} from '@/common/Constants';
import {
  iBaseCampFileMapper, iBasecampColumn, iBaseCampFileMapperOptions, IBaseCampFileMapperWithOptions,
} from './iBaseCampFileMapper';
import filemapper from './FileMapper.vue';
import { iFileMapperWithOptions } from './iFileMapper';

const ALERT_TYPE_ERROR = 'error';
const ALERT_TYPE_SUCCESS = 'success';
const ALERT_TYPE_INFO = 'info';
const assetModule = namespace('asset');
const projectModule = namespace('project');

@Component({
  components: {
    filemapper,
  },
})
export default class UploadScopeFile extends Vue {
  valid = true as boolean;

  hideDetails = true as boolean;

  selectedInputMethod = '' as string;

  selectedType = '' as string;

  showAlert = false as boolean;

  alertType = 'error' as string;

  alertText = '' as string;

  selectedFile = [];

  typeItems = ['Manhole'];

  inputItems = ['Import File'];

  assetChosen = '' as string;

  hasGISData = true;

  hasInspections = false;

  hasSLRatInspections = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assetTypes = ['Manhole', 'Line Segment'];

  uploadFileColumns = [];

  rzFileColumns: Mapping[] = [];

  uploadFileName = '' as string;

  selectedUploadFileColumns = '' as string;

  haveuploadFileColumns = false as boolean;

  manhole = {
    mapPage: '' as string,
    assetID: '' as string,
  }

  lineSegment = {
    mapPage: '' as string,
    upstreamManhole: '' as string,
    downstreamManhole: '' as string,
    pipeID: '' as string,
    pipeShape: '' as string,
    pipeMaterial: '' as string,
    diameter: '' as string,
    expected: '' as string,
    location: '',
  }

  @assetModule.Action(AssetActions.POST_FILE_DATA) postFileData;

  @assetModule.Action(AssetActions.POST_FILE_MAPPING_DATA) postMappingData;

  @projectModule.State('headers') project: ProjectHeaders | undefined;

  @Watch('hasInspections')
  onHasInspectionsChange(): void {
    if (this.hasInspections) this.hasGISData = true;
  }

  @Watch('assetChosen')
  onAssetChosenChange(): void {
    this.hasSLRatInspections = false;
  }

  isValidInput(): boolean {
    if ((this.$refs.formAddScope as Vue & { validate: () => boolean }).validate()) {
      this.showAlert = false;
      return true;
    }
    this.alertType = ALERT_TYPE_ERROR;
    this.alertText = 'Please enter required fields';
    this.showAlert = true;
    return false;
  }

  onClickSubmit(): void {
    if (this.isValidInput()) {
      (this.$refs.formAddScope as Vue & { reset: () => void }).reset();
      // TODO: Handle valid form/CSV submission
    }
  }

  async onClickUpload(): Promise<void> {
    if (!this.isValidInput()) return;
    if (!(this.$refs.formAddScope as any).validate()) return;
    const payload = {
      selectedFile: this.selectedFile,
      mappings: this.generateFileImportMappings(),
    };
    const status = await this.postFileData(payload);
    if (status.Complete) {
      this.alertType = ALERT_TYPE_INFO;
      this.alertText = 'Please map the columns';
      this.showAlert = true;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.uploadFileColumns = status.FileHeaders;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.rzFileColumns = status.RzColumns;
      this.uploadFileName = status.FileName;
      this.haveuploadFileColumns = true;
    } else {
      this.alertType = ALERT_TYPE_ERROR;
      this.alertText = status.ErrorMessage;
      this.showAlert = true;
    }
  }

  onClickClear(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.formAddScope as Vue & { reset: () => void }).reset();
    this.selectedFile = [];
    this.haveuploadFileColumns = false;
    this.showAlert = false;
  }

  onClickCancel(): void {
    this.onClickClear();
    this.$emit('close');
  }

  clearAlertBar(): void {
    this.alertText = '';
    this.showAlert = false;
  }

  clear(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.formAddScope as any).reset();
  }

  generateFileImportMappings(): string[] {
    const mappings = [];
    if (this.assetChosen === 'Manhole') {
      if (this.hasInspections) {
        mappings.push(FILE_MAPPING_MANHOLES_WITH_INSPECTIONS);
      } else {
        mappings.push(FILE_MAPPING_MANHOLES_NO_INSPECTIONS);
      }
      if (this.hasGISData) {
        mappings.push(FILE_MAPPING_MANHOLE_GIS_DATA);
      }
    } else {
      // Line segment
      mappings.push(FILE_MAPPING_LINE_SEGMENT);
      if (this.hasGISData) {
        mappings.push(FILE_MAPPING_LINE_SEGMENT_GIS_DATA);
      }
      if (this.hasSLRatInspections) {
        mappings.push(FILE_MAPPING_SLRAT_SCORE);
      }
    }
    return mappings;
  }

  async ColumnsMappedComplete(fileMapperWithOptions: iFileMapperWithOptions): Promise<void> {
    // const uploadFileMapping = new UploadFile();
    if (!this.project) {
      this.alertType = ALERT_TYPE_ERROR;
      this.alertText = 'No valid project';
      this.showAlert = true;
      return;
    }
    const fileMapper = fileMapperWithOptions.Mappings;
    const basecampFileMapper = {} as iBaseCampFileMapper;
    basecampFileMapper.FileName = this.uploadFileName;
    // eslint-disable-next-line prefer-destructuring
    basecampFileMapper.ProjectGuid = this.project.guids[0];
    basecampFileMapper.FileImportMappingGuids = this.generateFileImportMappings();
    basecampFileMapper.Columns = [];
    for (let i = 0; i < fileMapper.length; i += 1) {
      const bc = {} as iBasecampColumn;
      bc.RedzoneColumn = fileMapper[i].RzColumn;
      bc.FileColumn = fileMapper[i].FileColumn;
      basecampFileMapper.Columns.push(bc);
    }

    const fileMapperOptions: iBaseCampFileMapperOptions = {
      email: fileMapperWithOptions.Options.Email,
    };

    const fileMapperDTO: IBaseCampFileMapperWithOptions = {
      options: fileMapperOptions,
      mapping: basecampFileMapper,
    };

    // const status = await uploadFileMapping.ReturnMapper(JSON.stringify(basecampFileMapper),
    //   this.$store.getters.GetAuth0Token);
    try {
      await this.postMappingData(JSON.stringify(fileMapperDTO));
    } catch (e) {
      this.alertType = ALERT_TYPE_ERROR;
      this.alertText = 'There was an error sending your scope request';
      this.showAlert = true;
      return;
    }
    this.showAlert = true;
    this.alertText = 'Submission Succesful! An email will be sent when the request is completed.';
    this.alertType = ALERT_TYPE_SUCCESS;
    this.haveuploadFileColumns = false;
    this.selectedFile = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.formAddScope as any).reset();

    this.$emit('reload');
    this.$emit('refreshProject');
  }
}
