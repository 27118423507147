import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { DeploymentsState } from './types';
import { mutations } from './mutations';
import { actions } from './actions';

const state: DeploymentsState = {
  data: undefined,
  deploymentCounts: undefined,
  deploymentStandards: undefined,
  countsLoading: false,
  loading: false,
  patchLoading: false,
  error: undefined,
  bigPipeDeploymentData: undefined,
  bigPipeDeploymentDataError: undefined,
  bigPipeDeploymentDataLoading: false,
  bigPipeDeploymentPatchError: undefined,
  bigPipeDeploymentPatchLoading: false,
  deploymentNumberPatchLoading: false,
  deploymentNumberPatchError: undefined,
  deploymentNumberData: undefined,
  deploymentId: undefined,
  deploymentIdLoading: undefined,
  deploymentIdError: undefined,
  postNullMissionLoading: false,
  postNullMissionError: undefined,
};

const deployments: Module<DeploymentsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default deployments;
