












































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any, max-len */
import { Component } from 'vue-property-decorator';
import { TASK_TYPE_STRING } from '@/common/Constants';
import ReportFooter from '../ReportFooter.vue';
import { RepairReportFields } from './types';
import {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportTextInput, {
  InputType,
} from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';
import RepairReportHeader from './RepairReportHeader.vue';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';

@Component({
  components: {
    ReportFooter,
    ReportTextInput,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportRadioRowInput,
    RepairReportHeader,
  },
})
export default class RepairReport extends ReportBodyMixin {
  // #region Abstract fields
  workOrderData: RepairReportFields | any = {};

  workOrderFields = [
    { name: 'workDescription', value: 'Description' },
    { name: 'specialInstructions', value: 'SpecialInstruction' },
    { name: 'assetNumber', value: 'Wastewater' },
    { name: 'addressLocation', value: 'StreetAddress' },
    { name: 'crossStreet', value: 'CrossSt' },
    { name: 'size', value: 'Size' },
    { name: 'material', value: 'Material' },
    { name: 'length', value: 'LengthGIS' },
    { name: 'specialConditions', value: 'SpecialConditions' },
    { name: 'workPerformedBy', value: 'WorkCompletedBy' },
    {
      name: 'constructionBegins',
      value: 'ConstructionStart',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'dateConstructionCompleted',
      value: 'ConstructionEnd',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'inspectionPerformedBy',
      value: 'InspectionCompletedBy',
      type: WorkOrderFieldType.DATE,
    },
    { name: 'descriptionOfWorkPerformed', value: 'WorkPerformed' },
    { name: 'followUpRootCutTreatment', value: 'FollowUp_RootCut' },
    { name: 'followUpPerformInvestigation', value: 'FollowUp_Investigation' },
    { name: 'followUpNone', value: 'FollowUp_None' },
    { name: 'followUpClean', value: 'FollowUp_Clean' },
    { name: 'followUpIncreaseCleaning', value: 'FollowUp_IncreaseClean' },
    { name: 'followUpMapChanges', value: 'FollowUp_MapChange' },
    { name: 'followUpRepairRehab', value: 'FollowUp_RepairRehab' },
    { name: 'followUpDecreaseCleaning', value: 'FollowUp_DecreaseClean' },
    { name: 'followUpOther', value: 'FollowUp_Other' },
    { name: 'followUpManholeInspection', value: 'FollowUp_MHInspection' },
    { name: 'followUpChangeFrequency', value: 'FollowUp_ChangeFreq' },
    { name: 'followUpComments', value: 'Comments' },
  ];
  // #endregion

  specialConditionsHeaders = ['In Street', 'In Easement'];

  numberInputType = InputType.NUMBER;

  get workOrderTaskTypeName(): string {
    return (
      TASK_TYPE_STRING.find((x) => x.guid === this.item.taskTypeGuid)?.desc
      ?? ''
    );
  }

  get isNoneSelected(): boolean {
    return this.workOrderData.followUpNone === true;
  }

  get isFollowUpSelected(): boolean {
    return (
      this.workOrderData.followUpRootCutTreatment
      || this.workOrderData.followUpPerformInvestigation
      || this.workOrderData.followUpClean
      || this.workOrderData.followUpIncreaseCleaning
      || this.workOrderData.followUpMapChanges
      || this.workOrderData.followUpRepairRehab
      || this.workOrderData.followUpDecreaseCleaning
      || this.workOrderData.followUpOther
      || this.workOrderData.followUpManholeInspection
      || this.workOrderData.followUpChangeFrequency
      || this.workOrderData.followUpComments
    );
  }

  canSetToInFollowUpRequired(): boolean {
    if (this.isNoneSelected) {
      return false;
    }
    return this.isFollowUpSelected;
  }
}
