import { GenericDropDownData } from '@/common/types';

// eslint-disable-next-line no-shadow
export enum FormDataFillFunction {
    ASSIGNED_TO = 'ASSIGNED_TO'
}

/* eslint-disable camelcase */
export interface FormPageData {
    // Original properties
    type: string,
    Order: number,
    Width: number,
    units: string,
    Height: number,
    InputType: string,
    exportable: string,
    exportname: string,
    headername: string,
    description: string,
    headernumber: number,
    relatedtable: string,
    format?: undefined,
    // Custom properties, should all be optional
    enum?: Array<string | GenericDropDownData>,
    prefilled?: boolean,
    fillFunctionMappingString?: string | FormDataFillFunction,
    required?: boolean,
    hint?: string,
    items?: FormPageData[],
    multiple?: boolean,
}

export interface CodingFormData {
    header: string,
    value: string | number | boolean | any[],
    isChanged: boolean,
    headerNumber?: number,
}

export interface paramsObj {
    comparison: string,
    limit: number,
}

export interface ValidationError {
    instancePath: string,
    keyword: string,
    message: string,
    params: paramsObj,
    schemaPath: string,
}

export interface SubInspectionData {
    subInspectionGuid: string,
    jsonData: string,
}

export interface GeneralInformation {
    surveyedBy: string,
    certificateNumber: string,
    reviewedBy: string,
    reviewerCertificateNumber: string,
    owner: string,
    customer: string,
    pO_Number: string,
    workOrderNum: string,
    mediaLabel: string,
    project: string,
    inspDate: string,
    inspTime: string,
    sheetNumber?: number,
    weatherType: string,
    preCleaning: string,
    dateCleaned: string,
    mHPurpose: string,
    mHInspectionLevel: string,
    mHInspectionStatus: string,
    consequenceOfFailure: string,
}

export interface Location {
    drainageArea: string,
    manholeNumber: string,
    streetAddress: string,
    city: string,
    mHLocationCode: string,
    surfaceType: string,
    mHPotentialRunoff: string,
    locationDetails: string,

}
export interface Manhole {

    sewerUse: string,
    mHAccessType: string,
    yearBuilt?: number,
    yearRenewed?: number,
    mHEvidenceSurcharge: string,

}
export interface Measurement {
    rimToInvert?: number,
    rimToGrade?: number,
    gradeToInvert?: number,
    rimToGradeExposed?: number,
    northing: string,
    easting: string,
    elevation: string,
    coordinateSystem: string,
    verticalDatum: string,
    gPSAccuracy: string,
    additionalInformation: string,

}
export interface Cover {
    type: string,
    mHCoverShape: string,
    coverSize?: number,
    centerCoverSize?: number,
    sizeWidth?: number,
    mHCoverMaterial: string,
    mHHoleDiameter: string,
    holesNumber?: number,
    coverBearingSurfaceDiameter?: number,
    coverBearingSurfaceDiaWidth?: number,
    mHCoverFrameFit: string,
    coverCondition: string,

}
export interface CoverInsert {
    mHCoverInsert: string,
    coverInsertCondition: string,

}
export interface Frame {
    mHCoverMaterial_frame: string,
    frameBearingSurfaceWidth?: number,
    frameBearingSurfaceDepth?: number,
    frameClearOpeningDia?: number,
    frameClearOpenWidth?: number,
    frameCondition: string,
    sealCondition: string,
    frameOffsetDistance?: number,
    mHInfiltrationInflow_FrameSeal: string,
    frameDepth?: number,

}
export interface Chimney {
    mHChimneyPresent: string,
    mHMaterial_Chimney1: string,
    mHMaterial_Chimney2: string,
    mHInfiltrationInflow_Chimney: string,
    chimneyClearOpening?: number,
    chimneyDepth?: number,
    mHLiningMaterial_Chimney_Interior: string,
    mHLiningMaterial_Chimney_Exterior: string,
    mHCondition_Chimney: string,

}
export interface Cone {
    mHConeType: string,
    mHMaterial_Cone: string,
    coneDepth?: number,
    mHLiningMaterial_Cone_Interior: string,
    mHLiningMaterial_Cone_Exterior: string,
    mHCondition_Cone: string,

}
export interface Wall {
    wallDiameter_Length?: number,
    wallDiameter_Width?: number,
    mHMaterial_Wall: string,
    wallDepth?: number,
    mHLiningMaterial_Wall_Interior: string,
    mHLiningMaterial_Wall_Exterior: string,
    mHCondition_Wall: string,

}

export interface PhotoInfo {
    uri: string,
    description: string,
}
export interface PipeConnection {
    subInspectionGuid: string,
    pipeNumber: number,
    clockPositions: string,
    rimToInvert?: number,
    mHPipeDirection: string,
    pipeMaterial_MHInsp: string,
    pipeShape_MHInsp: string,
    pipeSize_MHInsp1: string,
    pipeSize_MHInsp2: string,
    mHInspectionSealCond_Pipe: string,
    mHInspectionSealCond_Seal: string,
    mHPipeSpecialCondition: string,
    structure: string,
    additional_Component_Information: string,

}
export interface CoverAdjustmentRing {
    mHAdjustmentRingType: string,
    mHCoverMaterial_AdjRing: string,
    ringCondition: string,
    adjustmentRingHeight?: number, // MHRingHeight
}
export interface Bench {
    mHBenchPresent: string,
    mHMaterial_Bench: string,
    mHLiningMaterial_Bench: string,
    mHCondition_Bench: string,
}
export interface Channel {
    mHChannelInstalled: string,
    mHMaterial_Channel: string,
    mHChannelType: string,
    mHChannelExposure: string,
    mHCondition_Channel: string,
}
export interface Steps {
    number?: number,
    mHStepMaterial: string,
}
export interface OverallScoring {
    oMIndex?: number,
    oMQuick: string,
    oMRating?: number,
    structuralIndex?: number,
    structuralQuick: string,
    structuralRating?: number,
    overallIndex?: number,
    overallQuick: string,
    overallRating?: number,
}

export interface CategoryDetail {
    guid: string,
    categoryGuid: string,
    shortname: string,
    description: string,
    createddate: string,
    ord?: number,
    createdbyUserGuid: string,
}

export interface ComputingDetail {
    guid: string,
    createddate: string,
    createdbyUserGuid: string,
    name: string,
    description: string,
}

export interface EquipmentDetail {
    guid: string,
    equipmentitemGuid: string,
    createddate: string,
    createdbyUserGuid: string,
    name: string,
    serialnumber: string,
    equipmentItem: CategoryDetail,

}

export interface MaterialDetail {
    guid: string,
    materialitemGuid: string,
    createddate: string,
    createdbyUserGuid: string,
    name: string,
    description: string,
    materialItem: CategoryDetail,

}

export interface UserDetail {
    guid: string,
    nickname: string,
    firstname: string,
    lastname: string,
    picturelocation: string,
    fieldname: string,
    email: string,
    createdbyUserGuid?: string,
    createddate: string,
    nasscoid: string,

}
export interface RouteInspectionData {
    sharedData: Record<string, unknown>,
    inspectionData: Record<string, unknown>[],
}

export interface ResourceDetail {
    guid: string,
    createddate: string,
    createdbyUserGuid: string,
    parentResourceGroupGuid?: string,
    startTimestamp?: string,
    endTimestamp?: string,
    resourceGroupGuid?: string,
    equipmentGuid?: string,
    equipment: EquipmentDetail,
    userGuid?: string,
    user: UserDetail,
    dataManagerNodeGuid?: string,
    computingGuid?: string,
    computing: ComputingDetail,
    materialGuid?: string,
    material: MaterialDetail,

}
export interface Report {
    GeneralInformation: GeneralInformation,
    Location: Location,
    Manhole: Manhole,
    Measurements: Measurement,
    Cover: Cover,
    CoverInsert: CoverInsert,
    Frame: Frame,
    Chimney: Chimney,
    Cone: Cone,
    Wall: Wall,
    CoverAdjustmentRing: CoverAdjustmentRing,
    Bench: Bench,
    Channel: Channel,
    Steps: Steps,
    OverallScoring: OverallScoring,
    pipeConnection: PipeConnection,
    photos: PhotoInfo,
}

export interface InspectionDetail {
    name: string,
    inspectionGuid: string,
    latitude: string,
    longitude: string,
    maxPayout: string,
    flatImage: string,
    video: string,
    _3DFile: string,
    model: string,
    soloFiles: string,
    conditionReport: string,
    measurementUnits: string,
    report: Report,
    jsonData: string,
    dataStandard_Json?: string | unknown,
    dataStandard_format: string,
    dataStandard_Schema: string,
    subDataStandard_format: string,
    subDataStandard_Schema: string,
    subInspectionJsonData: SubInspectionData[] | string[],
}

export interface InspectionResponse {
    name: string,
    nodeGuid: string,
    assetTypeCodeSet: string,
    inspections: InspectionDetail[],
    resources: ResourceDetail[],
}

// eslint-disable-next-line no-shadow
export const ConditionalType: string[] = [
  'if',
  'anyOf',
  'allOf',
];

export interface ConditionalRequirement {
    conditionalField: string,
    comparerValue: string[] | string | boolean | number,
    requiredOnElse: string[],
    optionalOnElse: string[],
    conditionalsOnElse: ConditionalRequirement[],
    requiredOnThen: string[],
    optionalOnThen: string[],
    conditionalsOnThen: ConditionalRequirement[],
}
