

















































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import SLRatReportHeader from './SLRatReportHeader.vue';
import ReportFooter from '../ReportFooter.vue';
import ReportInterface, {
  WorkOrderFieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';
import ReportTextInput, {
  InputType,
} from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportInputCheckboxTable from '../ReportHelpers/ReportInputHelpers/ReportInputCheckboxTable.vue';
import { SLRatFields } from './types';
import ReportBodyMixin from '../ReportHelpers/ReportBodyMixin.vue';

@Component({
  components: {
    ReportFooter,
    ReportTextInput,
    ReportDropDownInput,
    ReportCalendarInput,
    ReportRadioRowInput,
    SLRatReportHeader,
    ReportInputCheckboxTable,
  },
})
export default class SLRatReport extends ReportBodyMixin {
  workOrderData: SLRatFields | any = {};

  workOrderFields = [
    { name: 'address', value: 'Street' },
    { name: 'city', value: 'City' },
    {
      name: 'inspectionDate',
      value: 'InspDate',
      type: WorkOrderFieldType.DATE,
    },
    {
      name: 'inspectionTime',
      value: 'InspTime',
      type: WorkOrderFieldType.TIME,
    },
    { name: 'overallQuick', value: 'OverallQuick' },
    { name: 'comments', value: 'Comments' },
    {
      name: 'followUpActions',
      value: 'FollowUpActions',
      type: WorkOrderFieldType.BOOLEAN,
    },
    { name: 'followUpComments', value: 'FollowUp' },
  ];

  numberType = InputType.NUMBER;

  canSetToInFollowUpRequired(): boolean {
    return this.workOrderData.followUpActions;
  }
}
